import React from 'react';
import Footer from '../components/footer';
import Contactheader from '../components/contactheader';
import Contactboxes from '../components/contactboxes';
import {Navbar} from '../components/navbar';
import Contactform from '../components/contactform';
const Contactpage = () => {
  return (
    <div>
       <Navbar variant='absolute' sectionId="section1" label="Go to Section 1" /> 
       <Contactheader  heading="Contact Us" location="contact"/>
       <Contactboxes />
       <Contactform/>
       <Footer sectionId="section1" label="Go to Section 1"  /> 
    </div>
  )
}

export default Contactpage
