import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {ThemeProvider} from '@mui/material'
import theme from './theme'; // Import the theme from the other file
import Singleservicespage from './pages/singleservicespage';
import {createBrowserRouter,RouterProvider} from "react-router-dom";
import Contactpage from './pages/contactpage';
import Aboutpage from './pages/aboutpage';
import Servicespage from './pages/servicespage';
import Yeastarpage from './pages/yeastarpage';
import Yealinkpage from './pages/yealinkpage';
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/Contactpage",
    element: <Contactpage/>,
  },
  {
    path: "/Aboutpage",
    element: <Aboutpage/>,
  },
  {
    path: "/Servicespage",
    element: <Servicespage/>,
  },
  {
    path: "/Singleservicespage",
    element: <Singleservicespage/>,
  },
  {
    path: "/Yeastarpage",
    element: <Yeastarpage/>,
  },
  {
    path: "/Yealinkpage",
    element: <Yealinkpage/>,
  },
  
  
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <RouterProvider router={router} />
  </ThemeProvider>,
);

