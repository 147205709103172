import p1 from '../images/Projects/airblue.png';
import p2 from  '../images/Projects/Bankislami.png';
import p3 from  '../images/Projects/CancerCare.png';
import p4 from  '../images/Projects/DHA.png';
import p5 from  '../images/Projects/Mari.png';
import p6 from  '../images/Projects/PMC.png';
import p7 from  '../images/Projects/PakistanRailway.png';
import p8 from  '../images/Projects/Sabroso.png';
import p9 from  '../images/Projects/Telenor.png';
import p10 from '../images/Projects/IDTM.png';
import p11 from '../images/Projects/RoyalFans.png'
const datateam = [
  {
    name: `Airblue`,
    img: p1,
   type: `Lorem ipsum dolor `,
    plink:`iiii`
  },
  {
    name: `Bankislami`,
    img: p2,
     type: `Lorem ipsum dolor `,
    plink:`iiii`
    
  },
  {
    name: `CancerCare`,
    img: p3,
     type: `Lorem ipsum dolor `,
    plink:`iiii`
  },
  {
    name: `DHA`,
    img: p4,
   type: `Lorem ipsum dolor `,
    plink:`iiii`
  },
  {
    name: `Mari`,
    img: p5,
    type: `Lorem ipsum dolor `,
    plink:`iiii`
  },
  {
    name: `PMC`,
    img: p6,
    type: `Lorem ipsum dolor `,
    plink:`iiii`
  },
  {
    name: `PakistanRailways`,
    img: p7,
    type: `Lorem ipsum dolor `,
    plink:`iiii`
  },
  {
    name: `Sabroso`,
    img: p8,
    type: `Lorem ipsum dolor `,
    plink:`iiii`
  },
  {
    name: `Telenor`,
    img: p9,
    type: `Lorem ipsum dolor `,
    plink:`iiii`
  },
  {
    name: `IDTM`,
    img: p10,
    type: `Lorem ipsum dolor `,
    plink:`iiii`
  },
  {
    name: `Royal Fans`,
    img: p11,
    type: `Lorem ipsum dolor `,
    plink:`iiii`
  },
  
];

export default datateam;