import React from 'react';
import Footer from '../components/footer';
import Contactheader from '../components/contactheader';
import Aboutboxes from '../components/aboutboxes';
import {Navbar} from '../components/navbar';
import Abouttext from '../components/abouttext';
import Aboutpics from '../components/aboutpics';
import datateam from '../components/teamcard';
import SliderComponent from '../components/teamcarousel';
import Aboutvalues from '../components/aboutvalues';
const Aboutpage = () => {
  return (
    <div>
       <Navbar sectionId="/#section1" label="Go to Slider" />
       
       <Contactheader  heading="About Us" location="about"/>
      <Aboutpics />
      <Abouttext/>
      <Aboutboxes />
     
      <Aboutvalues/>
      <Footer sectionId="/#section1" label="Go to Slider" /> 
    </div>
  )
}

export default Aboutpage