import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid,Box } from '@mui/material';
import { useEffect, useState } from "react";
import '../css/servicesprocess.css';
const Servicesprocess = () => {
 
 
    const [expanded, setExpanded] = useState('panel1');
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  
    // To handle oval selection
    const handleOvalClick = (panel) => {
      setExpanded(panel);
    };

  return (
    <div>
      <Box sx={{paddingBottom:'30px', paddingTop:'30px',  backgroundColor:'primary.light'}}>
        <Typography variant='h2' sx={{paddingLeft:'20px',color:'secondary.dark' , fontSize:'30px'}}> Services Process</Typography>
      <Grid container p={2} spacing={2} justifyContent="center" alignItems="center"  >
        <Grid item xs={12} md={6}  display="flex" justifyContent="center"  flexDirection="column">
      
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{backgroundColor:'primary.light' , boxShadow:'none' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <Typography sx={{ width: '50%', flexShrink: 0,fontSize:'20px',color:'#020842' }}>01. Listening to Your Needs</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Data-driven diagnostic and predictive app for improving outcomes.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{backgroundColor:'primary.light' , boxShadow:'none' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
            <Typography sx={{ width: '50%', flexShrink: 0 ,fontSize:'20px',color:'#020842'}}>02. Tailored Solutions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Tailored solutions for business challenges.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{backgroundColor:'primary.light' , boxShadow:'none' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
            <Typography sx={{ width: '50%', flexShrink: 0,fontSize:'20px',color:'#020842' }}>03. Tactical Alignment</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Aligning strategy with operational execution.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={{backgroundColor:'primary.light' , boxShadow:'none' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
            <Typography sx={{ width: '50%', flexShrink: 0,fontSize:'20px',color:'#020842' }}>04. Measurable Results</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Achieving measurable business outcomes.
            </Typography>
          </AccordionDetails>
        </Accordion>
         
        </Grid>
     
        <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <div className={`oval ${expanded === 'panel1' ? 'highlight' : ''}`} onClick={() => handleOvalClick('panel1')}>
          Listening to Your Needs
        </div>
        <div className={`oval ${expanded === 'panel2' ? 'highlight' : ''}`} onClick={() => handleOvalClick('panel2')}>
          Tailored Solutions
        </div>
        <div className={`oval ${expanded === 'panel3' ? 'highlight' : ''}`} onClick={() => handleOvalClick('panel3')}>
          Tactical Alignment
        </div>
        <div className={`oval ${expanded === 'panel4' ? 'highlight' : ''}`} onClick={() => handleOvalClick('panel4')}>
          Measurable Results
        </div>
        </Grid>
      </Grid>
      </Box>
    </div>
  )
}
export default Servicesprocess
