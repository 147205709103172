import React from 'react'
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import {Box,Typography } from '@mui/material';
const headingbg ={
    color: 'secondary.main',
    backgroundColor: 'primary.main',
    textAlign:'center',
    marginLeft:1,
    marginBottom:1,
    width:180,
    marginTop:1,
    borderRadius:1,
  }
  const headingsx ={
    color: 'secondary.contrastText',
    marginTop:1,
  }
const Abouttext = () => {
  return (
    <div>
        <Box sx={{ width: '100%', backgroundColor:'primary.light' , padding:'50px 15px 30px 15px'}}>
        <div className='app-container1'>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
               <Grid item xs={12} md={6}>
               <Stack  direction="row" sx={{textAlign:'left', justifyContent:'left' }}>
               <Typography variant='subtitle1'sx={headingsx}>About</Typography>
               <Typography variant='subtitle1'sx={headingbg}>Advance Resources</Typography>
                </Stack> 
                <Typography variant='h3'sx={{fontSize:{xs:'30px',sm:'40px'},color:'secondary.dark',fontWeight:'bold'}}>We Provide You Flexible Services</Typography>
               </Grid>
               <Grid item xs={12} md={6}>
               <Typography variant='body1'sx={{fontSize:'18px',color:'secondary.contrastText',fontWeight:500}}>As a leading provider of IT services, <Typography component="span" sx={{fontWeight:'bold' , fontSize:'20px'}}> Advance Resources</Typography> offers innovative IT solutions to businesses. Our company has specialized in providing reliable network design, seamless communication systems, and robust data security services since 2005. A customer-centric approach helps us provide clients with IT infrastructure that drives growth and efficiency while meeting their unique needs.</Typography>
               </Grid>
            </Grid>
            </div>
        </Box>
    </div>
  )
}

export default Abouttext
