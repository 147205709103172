import React from 'react'
import Footer from '../components/footer';
import Contactheader from '../components/contactheader';
import data from '../components/servicesdata';
import Allservices from '../components/allservices';
import {Navbar} from '../components/navbar';
import Servicesintro from '../components/servicesintro';
const Servicespage = () => {
  return (
    <div>
       <Navbar variant='absolute' sectionId="section1" label="Go to Section 1"/> 
       <Contactheader  heading="Our Services" location="contact"/> {/* because contact css */}
       <Servicesintro/>
       <Allservices data={data} />
       <Footer sectionId="section1" label="Go to Section 1"/> 
    </div>
  )
}

export default Servicespage
