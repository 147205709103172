import React from 'react'

import about1 from '../images/about/about1.webp';
import { Grid,Box,Typography } from '@mui/material';

const Singleserviceintro = () => {
  return (
    <div>

        


       <Box sx={{ width: '100%', backgroundColor:'primary.light' , padding:'50px 15px 30px 15px'}}>
       <Grid container  justifyContent="center" alignItems="center">
       <Grid item xs={11}>
        <Box
                sx={{
                  overflow: 'hidden',
                  position: 'relative',
                  height: '500px',
                  
                  borderRadius:'15px',
                }}
              >
                <img src={about1} style={{ width: '100%', height: '100%', objectFit: 'cover', display: 'block' }} />
              </Box>
        </Grid>
       <Grid item xs={11}  justifyContent="center" alignItems="center" mt={10}>
              
            
              <Typography variant='h3'sx={{fontSize:{xs:'30px',sm:'40px'},color:'secondary.dark',fontWeight:'bold'}}>Network infrastructure</Typography>
              <Typography variant='body1' sx={{fontSize:'16px' ,color:'secondary.contrastText',marginTop:2}}>we understand that every business is unique, with its own set of challenges, goals, and aspirations. That's why we offer tailored IT solutions designed.we understand that every business is unique, with its own set of challenges, goals, and aspirations. That's why we offer tailored IT solutions designed.we understand that every business is unique, with its own set of challenges, goals, and aspirations. That's why we offer tailored IT solutions designed.we understand that every business is unique, with its own set of challenges, goals, and aspirations. That's why we offer tailored IT solutions designed.</Typography>
        </Grid>
        
       
       </Grid>
       </Box>
  



    
    </div>
  )
}

export default Singleserviceintro
