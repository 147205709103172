import React from 'react'
import about1 from'../images/about/aboutmain.jpg';
import about2 from '../images/about/about2.mp4';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const Aboutpics = () => {
  return (
    <div>
         <Box sx={{ width: '100%', backgroundColor:'primary.light' , padding:'30px 15px 15px 15px'}}>
         <div className='app-container1'>
       <Grid container  mt={'-400px'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{justifyContent:'center'}}>
        <Grid item xs={12} sm={10} md={8} lg={7}  justifyContent={'center'}>
        <Box
                sx={{
                  overflow: 'hidden',
                  position: 'relative',
                  height: 400,
                  width:'100%',
                  borderRadius:'15px',
                  display:'flex',
                  justifyContent:'center',
                  alignItems:'center',
                  img:{
                    width:'100%',
                    minWidth:'100%',
                    maxWidth:'100%'
                  }
                }}
              >
                <img src={about1}   style={{ width: '100%',minWidth:'100%',maxWidth:'100%', height:'100%', objectFit: {xs:'contain' , md:'contain'  }}} />
              </Box>
        </Grid>
       
       </Grid>
       </div>
       </Box>
    </div>
  )
}

export default Aboutpics
