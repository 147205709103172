// section for all services intro
import React from 'react'
import Stack from '@mui/material/Stack';
import s1 from '../images/services/s1.webp';
import { ArrowOutward } from '@mui/icons-material';
import { Grid,Box,Button,Typography } from '@mui/material';
import { Link } from 'react-router-dom';
const headingbg ={
    color: 'secondary.main',
    backgroundColor: 'primary.main',
    textAlign:'center',
    marginLeft:1,
    marginBottom:1,
    width:100,
    fontSize:'14px',
    borderRadius:1,
    paddingTop:'3px'
  }
  const headingsx ={
    color: 'secondary.contrastText',
    fontSize:'16px',
  }
  const exploresx = {
    color: 'secondary.main',
    borderRadius: 10,
    width:{ xs:'200px',sm:'200px',md:'200px',lg:'250px'},
    borderColor: 'secondary.light',
    backgroundColor: 'secondary.light',
    padding: '20px 10px',
  };
const Servicesintro = () => {
  return (
    <div>
       <Box sx={{ width: '100%', backgroundColor:'primary.light' , padding:'100px 15px 30px 15px'}}>
       <Grid container  rowSpacing={5} columnSpacing={{ xs: 5, sm: 3, md: 3 }}>
       <Grid item xs={12} sm={6} sx={{paddingRight:{xs:'0px',md:'20px',lg:'100px'}}} >
              
              <Stack  direction="row" sx={{textAlign:'left', justifyContent:'left' }}>
              <Typography variant='subtitle1'sx={headingsx}>We are</Typography>
              <Typography variant='subtitle1'sx={headingbg}>IT Guidance</Typography>
             
              </Stack> 
              <Typography variant='h3'sx={{fontSize:{xs:'30px',sm:'40px'},color:'secondary.dark',fontWeight:'bold'}}>Tailored IT Solutions for Your Success</Typography>
             
              <Typography variant='body1' sx={{height:'110px',fontSize:'18px' ,color:'secondary.contrastText',marginTop:2}}>we understand that every business is unique, with its own set of challenges, goals, and aspirations. That's why we offer tailored IT solutions designed.</Typography>
              <Stack   sx={{marginTop:'15px',alignItems:'left', justifyContent:'left', }}  >
                <Button variant="contained" endIcon={<ArrowOutward/>} component={Link} to='/Contactpage' sx={exploresx}><Typography variant='body2' sx={{fontSize:'14px', paddingTop:'3px',fontWeight:400, color:'secondary.main'}}>Talk to an expert</Typography></Button>
              </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
        <Box
                sx={{
                  overflow: 'hidden',
                  position: 'relative',
                  height:' 450px',
                  
                  paddingLeft:'10px',
                  paddingBottom:'30px',
                 
                 
                }}
              >
                <img src={s1} style={{ width: '100%', height: '100%', objectFit: 'contain', display: 'block' }} />
              </Box>
        </Grid>
       
       </Grid>
       </Box>
    </div>
  )
}

export default Servicesintro
