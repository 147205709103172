import React from 'react';
import s1 from '../images/services/services1.png';
import s2 from '../images/services/services2.png';
import s3 from '../images/services/services3.jpeg';
import s4 from '../images/services/services4.webp';
import s5 from '../images/services/services5.jpeg';
import s6 from '../images/services/services6.webp';
import s7 from '../images/services/structurecabling.jpg';

const data = [
  {
    name: 'Network Solutions',
    picture: s1,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    types:[ `Routing/Switching `,`LAN/WAN`],
    category1: 'Routing',
    category2: 'LAN/WAN',
    category3: 'Firewalls',
   
  },
  {
    name: 'Structured Cabling',
    picture: s7,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    category1: 'Cat6/Fiber',
    category2:'Complete Data center',
    category3:'Endpoint Turnkey',
    types:[ `Cat6/Fiber `,`Complete Data center`],
  },
  {
    name: 'Surveillance Solutions',
    picture: s3,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    category1: 'Security Cameras',
    category2:'Access Control',
    category3:'Flap Barriers',
    types:[ `Security Cameras `,`Access Control`],
  },
 
  {
    name: 'Web Development',
    picture: s6,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    category1: 'Custom',
    category2:'Responsive',
    types:[ `Custom`,`Responsive `],
  },
  {
    name: 'UI/UX Design',
    picture: s4,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    category1: 'Custom',
    category2:'Responsive',
    types:[ `Custom `,`Responsive `],
  },
  {
    name: 'VOIP Services',
    picture:s5,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
];

export default data;