import React from 'react'
import { Grid,Box,Typography } from '@mui/material';
import thick from '../images/services/thickicon.svg';
const  Servicesoutcome = () => {
  return (
    <div>
         <Box sx={{ width: '100%', backgroundColor:'primary.light' , padding:'50px 15px 30px 15px'}}>
       <Grid container  rowSpacing={5} columnSpacing={{ xs: 5, sm: 3, md: 3 }}>
      
       <Grid item xs={12} >
              
            
              <Typography variant='h3'sx={{fontSize:{xs:'30px',sm:'40px'},color:'secondary.dark',fontWeight:'bold'}}>Network infrastructure</Typography>
              <Typography variant='body1' sx={{fontSize:'16px' ,color:'secondary.contrastText',marginTop:2}}>we understand that every business is unique, with its own set of challenges, goals, and aspirations. That's why we offer tailored IT solutions designed.</Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid  container  columnSpacing={2} rowSpacing={0}>
          <Grid item xs={12} sm={6}>
            <Box>
            <Box  width={'100%'} textAlign={'left'} sx={{display:'flex',direction:'row',alignItems:'center'}}>
                <img src={`${thick}`} width={'25px'} height={'25px'}alt="My Image" />
                    <Typography variant='body1' sx={{fontSize:{xs:'16px', md:'12px',lg:'18px'},color:'secondary.dark'  ,marginLeft:1}}>Quality Comes First</Typography>
                </Box>
                <Box  width={'100%'} textAlign={'left'} sx={{display:'flex',direction:'row',alignItems:'center'}}>
                <img src={`${thick}`} width={'25px'} height={'25px'}  alt="My Image" />
                    <Typography variant='body1' sx={{fontSize:{xs:'16px', md:'12px',lg:'18px'},color:'secondary.dark'  ,marginLeft:1}}>Quality Comes First</Typography>
                </Box>
                <Box  width={'100%'} textAlign={'left'} sx={{display:'flex',direction:'row',alignItems:'center'}}>
                <img src={`${thick}`} width={'25px'} height={'25px'} alt="My Image" />
                    <Typography variant='body1' sx={{fontSize:{xs:'16px', md:'12px',lg:'18px'},color:'secondary.dark'  ,marginLeft:1}}>Quality Comes First</Typography>
                </Box>
          </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Box>
          <Box  width={'100%'} textAlign={'left'} sx={{display:'flex',direction:'row',alignItems:'center'}}>
                <img src={`${thick}`} width={'25px'} height={'25px'} alt="My Image" />
                    <Typography variant='body1' sx={{fontSize:{xs:'16px', md:'12px',lg:'18px'},color:'secondary.dark'  ,marginLeft:1}}>Quality Comes First</Typography>
                </Box>
                <Box  width={'100%'} textAlign={'left'} sx={{display:'flex',direction:'row',alignItems:'center'}}>
                <img src={`${thick}`} width={'25px'} height={'25px'} alt="My Image" />
                    <Typography variant='body1' sx={{fontSize:{xs:'16px', md:'12px',lg:'18px'},color:'secondary.dark'  ,marginLeft:1,}}>Quality Comes First</Typography>
                </Box>
                <Box  width={'100%'} textAlign={'left'} sx={{display:'flex',direction:'row',alignItems:'center'}}>
                <img src={`${thick}`} width={'25px'} height={'25px'} alt="My Image" />
                    <Typography variant='body1' sx={{fontSize:{xs:'16px', md:'12px',lg:'18px'},color:'secondary.dark'  ,marginLeft:1}}>Quality Comes First</Typography>
                </Box>
          </Box>
          </Grid>
          </Grid>
        </Grid>
        
       
       </Grid>
       </Box>
  
    </div>
  )
}
export default Servicesoutcome
