import React from 'react';
import Footer from '../components/footer';
import {Navbar} from '../components/navbar';
import Contactheader from '../components/contactheader';
import Servicesprocess from '../components/servicesprocess';
import Singleserviceintro from '../components/singleserviceintro';
import Servicesoutcome from '../components/servicesoutcome';
const Singleservicespage = () => {
  return (
    <div>
       <Navbar variant='absolute'/> 
       <Contactheader  heading="Network infrastructure" location="contact"/> {/* because contact css */}
       <Singleserviceintro />
       <Servicesprocess />
       <Servicesoutcome />
     
       <Footer /> 
    </div>
  )
}

export default Singleservicespage