import React, { useEffect, useState } from 'react';
import '../css/navbar.css';

import { Link } from 'react-router-dom';
import logo from '../images/footer/logo.png'
 import {Menu,MenuItem, Button,Typography, Popover} from '@mui/material';
 import {ArrowOutward, NoEncryption} from '@mui/icons-material';
 import logo1 from '../images/footer/logo1.jpg';

export const Navbar = ({ sectionId, label }) => {
  const scrollToSection = () => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const [menuopen, setMenuopen] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [productsOpen, setProductOpen] = useState(false);
  const [anchorEl ,setAnchorEl]=useState(null);
  function handleMenuToggle() {
    if (menuopen) {
      setAnimateOut(true);
      setTimeout(() => setAnimateOut(false), 1500);
    }
    setMenuopen(!menuopen); 
  }
  
  const handleClick=(Event)=>{
    setAnchorEl(Event.currentTarget);
  };
  const handleClose=()=>
  {
    setAnchorEl(null);
  };
  const toggleProductsDropdown = () =>{
    setProductOpen(!productsOpen);
  }
 

  return (
    <div className='nav-container'>
      <div className='nav-logolg' >
        <Link to='/'>
        <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className='nav-logosm' >
        <Link to='/'>
        <img src={logo1} alt="logo" />
        </Link>
        
      </div>
      <input
        className='menu-btn'
        type='checkbox'
        id='menu-btn'
        checked={menuopen}
        onChange={handleMenuToggle}
      />
      <label className='menu-icon' htmlFor='menu-btn'>
        <span className='nav-icon'></span>
      </label>
      <div className={`nav-items ${animateOut ? 'slide-out' : menuopen ? 'slide-in' : ''}`}>
       
      <Link to="/"> <Typography variant='h1' component="span" sx={{ fontSize:'14px',fontWeight:500,color:'secondary.dark','&:hover': {color:'secondary.light',}}} onClick={() => window.scrollTo(0, 0)} >Home</Typography></Link>
       
        
      <Link to="/Aboutpage"> <Typography variant='h1' component="span" sx={{fontSize:'14px', fontWeight:500,color:'secondary.dark','&:hover': {color:'secondary.light'}}}  onClick={() => window.scrollTo(0, 0)}>About</Typography></Link>
        
       
     
        {/* large screen */}
    <Button
          aria-controls="products-menu"
          aria-haspopup="true"
          onClick={handleClick}

          sx={{display:{xs:'none',lg:'block'},border:'none',backgroundColor:'transparent', textTransform:'none',fontSize: '14px', fontWeight: 500, color: 'secondary.dark', '&:hover': { backgroundColor:'transparent',color: 'secondary.light' },'&:onClick':{backgroundColor:'transparent'} }}
        >
          Products
        </Button>
        <Popover
          id="products-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          container={document.body}
          disableScrollLock={true}
          sx={{marginTop:'30px',
           
          }}
        >
          <div style={{maxWidth:'150px'}}>
          <Typography sx={{width:'150px',padding:'10px' }} onClick={handleClose}><Link  to="/Yeastarpage" style={{textDecoration:'none'}}><Typography variant='h1' component="span"  sx={{   fontSize:'16px',fontWeight:500,color:'secondary.dark','&:hover': {color:'secondary.light'}}} >Yeastar</Typography></Link></Typography>
          <Typography sx={{width:'150px',padding:'10px' }} onClick={handleClose}><Link  to="/Yealinkpage" style={{textDecoration:'none'}}><Typography variant='h1' component="span"   sx={{   fontSize:'16px',fontWeight:500,color:'secondary.dark','&:hover': {color:'secondary.light'}}} >Yealink</Typography></Link></Typography>
          </div>
        </Popover>
     
     <Button
         
          // changing dropdown in display
          sx={{ display:{lg:'none !important'},border:'none',backgroundColor:'transparent', textTransform:'none',fontSize: '14px', fontWeight: 500, color: 'secondary.dark', '&:hover': { backgroundColor:'transparent',color: 'secondary.light' },'&:onClick':{backgroundColor:'transparent'} }}
          onClick={toggleProductsDropdown}
       >
          Products
        </Button>
          {productsOpen && (
            // changing dropdown css in mobile view and desktop view
          <div style={{display:{xs:'block',lg:'none !important'},justifyContent:'center', textAlign:'center',width:'100%'}}>
      <Link  to="/Yeastarpage"  onClick={() => setProductOpen(false)}>
              <Typography  variant='h1' sx={{ fontSize: '16px', fontWeight: 500, color: 'secondary.dark', '&:hover': { color: 'secondary.light' } }}>Yeastar</Typography>
            </Link>
            <Link to="/Yealinkpage" onClick={() => setProductOpen(false)}>
              <Typography  variant='h1' sx={{ fontSize: '16px', fontWeight: 500, color: 'secondary.dark', '&:hover': { color: 'secondary.light' } }}>Yealink</Typography>
            </Link>
          </div>
)}
      
      <Link to="/Servicespage"> <Typography variant='h1'component="span" onClick={() => window.scrollTo(0, 0)} sx={{ fontSize:'14px',fontWeight:500,color:'secondary.dark','&:hover': {color:'secondary.light'}}}>Services</Typography></Link>
      <Link to="/Contactpage"> <Typography variant='h1' component="span" onClick={() => window.scrollTo(0, 0)} sx={{  display: { md : 'none !important' }, fontSize:'14px',fontWeight:500,color:'secondary.dark','&:hover': {color:'secondary.light'}}} >Contact</Typography></Link>
       
      
       
      </div>
      
      <div className='nav-button'>
     
                <Button variant="contained" component={Link} to='/Contactpage' onClick={() => window.scrollTo(0, 0)}  endIcon={<ArrowOutward sx={{paddingBottom:0.2}}/>} sx={{border: '1px solid #E3F0FF', color:'secondary.dark',
  borderRadius: 10,
  width:200,
  backgroundColor: 'secondary.main',
 
  padding:'10px 10px',
  fontSize:'14px',
  textAlign:'center',
  justifyContent:'center',
 
 
  boxShadow:'none',
  '&:hover': {
    backgroundColor: 'secondary.light',
    borderColor:'secondary.light',
    color:'secondary.main',
    
   padding:'10px',
   fontSize:'14px',}}}>Contact Us</Button>
     
      </div>
      
       
     
    </div>
  );
};






