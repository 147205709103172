import React from 'react'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography} from '@mui/material';
import a1 from '../images/about/a1.png';
import a2 from '../images/about/a2.png';
import a3 from '../images/about/a3.png';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'secondary.main',
    height:'400px',
   
    textAlign: 'justify',
    color: 'secondary.light',
    padding:'50px 25px 25px 25px',
    borderRadius:'20px',

  }));
  const Imagebox = styled(Paper)(({ theme }) => ({
    backgroundColor: '',
   backgroundColor: '#0044EB1A'
   ,padding:'10px',
   borderRadius:'50%',
  width:'80px',
  height:'80px',
textAlign:'center',
    boxShadow:'none',
    marginLeft:0,
   
    
  
   

  }));
 
const Aboutboxes = () => {
  return (
    <div>
        <Box sx={{ width: '100%', backgroundColor:'primary.light' , padding:'30px 15px 15px 15px'}}>
        <div className='app-container1'>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} textAlign={'left'}>
        <Grid item xs={12}  md={4} textAlign={'justify'}>
          <Item>
          <Imagebox><img src={`${a2}`} width={'60px'} height={'60px'} alt="My Image" /></Imagebox>
                        <Typography variant='h6' sx={{fontSize:'30px',fontWeight:'bold',color:'secondary.dark',mt:'32px'}} >Our Mission</Typography>
                        <Typography variant='body1'sx={{fontSize:{xs:'16px',sm:'20px',md:'12px',lg:'16px'},color:'secondary.contrastText',mt:'20px'}}>Our team at <Typography component="span" sx={{fontWeight:'bold' , fontSize:'18px'}}>Advance Resources</Typography> specializes in designing and delivering Tailored IT solutions that drive efficiency and innovation within organizations.</Typography>
          </Item>
        </Grid>
        <Grid item xs={12}  md={4} textAlign={'justify'}>
          <Item>
          <Imagebox><img src={`${a3}`} width={'60px'} height={'60px'} alt="My Image" /></Imagebox>
                <Typography variant='h6' sx={{fontSize:'30px',fontWeight:'bold',color:'secondary.dark',mt:'32px'}} >Our Vision</Typography>
                <Typography variant='body1'sx={{fontSize:{xs:'16px',sm:'20px',md:'12px',lg:'16px'},color:'secondary.contrastText',mt:'20px'}}>To revolutionize IT services in Pakistan by becoming the most trusted and innovative technology solutions provider, empowering businesses to thrive in the digital age</Typography>
          </Item>
        </Grid>
        <Grid item xs={12}  md={4} textAlign={'justify'}>
          <Item>
          <Imagebox><img src={`${a1}`} width={'60px'} height={'60px'} alt="My Image" /></Imagebox>
                    <Typography variant='h6' sx={{fontSize:'30px',fontWeight:'bold',color:'secondary.dark' ,mt:'32px'}} >Our History</Typography>
                    <Typography variant='body1'sx={{fontSize:{xs:'16px',sm:'20px',md:'12px',lg:'16px'},color:'secondary.contrastText',mt:'20px'}}>Founded in 2005, <Typography component="span" sx={{fontWeight:'bold', fontSize:'18px'}}>Advance Resources</Typography> has proudly served over 500 companies, delivered cutting-edge IT solutions and services. With a steadfast commitment to excellence, we continue our mission to become the most trusted IT partner for businesses.</Typography>
                   
          </Item>
        </Grid>
        
      </Grid>
      </div>
    </Box>
    </div>
  )
}

export default Aboutboxes
