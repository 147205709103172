import React from 'react'
import about1 from'../images/services/s2.webp';
import v1 from '../images/about/v1.png';
import v2 from '../images/about/v2.png';
import v3 from '../images/about/v3.png';
import v4 from '../images/about/v4.png';
import v5 from '../images/about/v5.png';
import v6 from '../images/about/v6.png';
import { Typography,Grid,Box ,Stack} from '@mui/material';
const headingbg ={
  color: 'secondary.main',
  backgroundColor: 'primary.main',
  textAlign:'center',
  marginRight:1,
  marginBottom:1,
  width:100,
 
  borderRadius:1,
}
const headingsx ={
  color: 'secondary.contrastText',
  
}
const Aboutvalues = () => {
  return (
    <div>
       <Box sx={{ width: '100%', backgroundColor:'primary.light' , padding:'30px 15px 30px 15px'}}>
       <div className='app-container1'>
       <Grid container  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
        <Box
                sx={{
                  overflow: 'hidden',
                 
                  height: 500,
                 
                  maxHeight:500, minHeight:500,
                  display:'flex',
                  justifyContent:'start',
                  borderRadius:'15px',
                  img:{
                    height: 500,maxHeight:500, minHeight:500,borderRadius:'15px'
                  }
                }}
              >
                <img src={about1} style={{ width: '100%', height: 500,maxHeight:500, minHeight:500, objectFit: 'cover',borderRadius:'15px',}} sx={{borderRadius:'15px'}} />
              </Box>
        </Grid>
        <Grid item xs={12} md={6} >
              <Stack  direction="row" sx={{textAlign:'left', justifyContent:'left'  ,marginTop:3}}>
              <Typography variant='subtitle1'sx={headingbg}>Why Us</Typography>
              <Typography variant='subtitle1'sx={headingsx}>Better</Typography>
              </Stack> 
              <Typography variant='h3'sx={{fontSize:{xs:'30px',sm:'40px'},color:'secondary.dark',fontWeight:'bold'}}>Why our Services are Better Than Others?</Typography>
              <Grid container  rowSpacing={3} columnSpacing={1} mt={2}>
               <Grid item xs={12} sm={6}>
                <Box  width={'100%'} textAlign={'left'} sx={{backgroundColor:'secondary.main',padding:'25px',borderRadius:'20px',display:'flex',direction:'row',alignItems:'center','&:hover': {color:'secondary.light',boxShadow:'0 1px 2px 0 rgba(174,191,210,0.3)',transform:'scale(1.02)'}}}>
                <img src={`${v1}`} width={'25px'} height={'25px'} style={{padding:5, backgroundColor:'#0044EB1A', borderRadius:'20%'}} alt="My Image" />
                    <Typography variant='body1' sx={{fontSize:{xs:'16px', md:'12px',lg:'16px'},color:'secondary.dark'  ,marginLeft:1,'&:hover': {color:'secondary.light',}}}>Quality Comes First</Typography>
                </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                <Box  width={'100%'} textAlign={'left'} sx={{backgroundColor:'secondary.main',padding:'25px',borderRadius:'20px',display:'flex',direction:'row',alignItems:'center','&:hover': {color:'secondary.light',boxShadow:'0 1px 2px 0 rgba(174,191,210,0.3)',transform:'scale(1.02)'}}}>
                <img src={`${v2}`} width={'25px'} height={'25px'} style={{padding:5, backgroundColor:'#0044EB1A', borderRadius:'20%'}} alt="My Image" />
                    <Typography variant='body1' sx={{fontSize:{xs:'16px', md:'12px',lg:'16px'},color:'secondary.dark'  ,marginLeft:1,'&:hover': {color:'secondary.light',}}}>Flexible Cooperation</Typography>
                </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                <Box  width={'100%'} textAlign={'left'} sx={{backgroundColor:'secondary.main',padding:'25px',borderRadius:'20px',display:'flex',direction:'row',alignItems:'center','&:hover': {color:'secondary.light',boxShadow:'0 1px 2px 0 rgba(174,191,210,0.3)',transform:'scale(1.02)'}}}>
                <img src={`${v3}`} width={'25px'} height={'25px'} style={{padding:5, backgroundColor:'#0044EB1A', borderRadius:'20%'}}alt="My Image" />
                    <Typography variant='body1' sx={{fontSize:{xs:'16px', md:'12px',lg:'16px'},color:'secondary.dark',marginLeft:1,'&:hover': {color:'secondary.light',}}}>On-time Delivery</Typography>
                </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                <Box  width={'100%'} textAlign={'left'} sx={{backgroundColor:'secondary.main',padding:'25px',borderRadius:'20px',display:'flex',direction:'row',alignItems:'center','&:hover': {color:'secondary.light',boxShadow:'0 1px 2px 0 rgba(174,191,210,0.3)',transform:'scale(1.02)'}}}>
                <img src={`${v4}`} width={'25px'} height={'25px'}style={{padding:5, backgroundColor:'#0044EB1A', borderRadius:'20%'}} alt="My Image" />
                    <Typography variant='body1' sx={{fontSize:{xs:'16px', md:'12px',lg:'16px'},color:'secondary.dark'  ,marginLeft:1,'&:hover': {color:'secondary.light',}}}>Transparent Costs</Typography>
                </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                <Box  width={'100%'} textAlign={'left'} sx={{backgroundColor:'secondary.main',padding:'25px',borderRadius:'20px',display:'flex',direction:'row',alignItems:'center','&:hover': {color:'secondary.light',boxShadow:'0 1px 2px 0 rgba(174,191,210,0.3)',transform:'scale(1.02)'}}}>
                <img src={`${v5}`} width={'25px'} height={'25px'}style={{padding:5, backgroundColor:'#0044EB1A', borderRadius:'20%'}} alt="My Image" />
                    <Typography variant='body1' sx={{fontSize:{xs:'16px', md:'12px',lg:'16px'},color:'secondary.dark'  ,marginLeft:1,'&:hover': {color:'secondary.light',}}}>Qualified Team</Typography>
                </Box>
                </Grid>
                <Grid item xs={12} sm={6}  sx={{'&:hover': {color:'secondary.light',}}} >
                <Box  width={'100%'} textAlign={'left'} sx={{backgroundColor:'secondary.main',padding:'25px',borderRadius:'20px',display:'flex',direction:'row',alignItems:'center','&:hover': { color:'secondary.light',boxShadow:'0 1px 2px 0 rgba(174,191,210,0.3)',transform:'scale(1.02)'}}}>
                <img src={`${v6}`} width={'25px'} height={'25px'} style={{padding:5, backgroundColor:'#0044EB1A', borderRadius:'20%'}}alt="My Image" />
                    <Typography variant='body1' sx={{fontSize:{xs:'16px', md:'12px',lg:'16px'},color:'secondary.dark'  ,marginLeft:1,'&:hover': {color:'secondary.light',}}}>Quick Scale-up</Typography>
                </Box>
               </Grid>
              </Grid>
        </Grid>
       </Grid>
       </div>
       </Box>
   

    </div>
  )
}

export default Aboutvalues
