import { Stack , Grid ,Typography,Box ,TextField,Button } from '@mui/material'
import React,{useState, useRef} from 'react'
import '../css/map.css';
import emailjs from 'emailjs-com';
import {ArrowOutward} from '@mui/icons-material';
const worksx={
    color:'secondary.main',
    borderRadius: 10,
    width:220,
    borderColor:'secondary.light',
    backgroundColor:'secondary.light',
    marginTop:3,
    marginRight:7,
    padding:'20px 15px',
    '&:hover': {
        backgroundColor: 'secondary.light',
        borderColor:'secondary.light',
        color:'secondary.main',
        fontWeight:'bold',
        }
      
  }  
const Contactform = () => {
  const [formData, setFormData] = useState({
    name:'',
    email: '',
    phonenumber:'',
    message: '',
  });
 

  const onFormChange = (e) => {
    const { name, value } = e.target;
    setFormData(  { ...formData, [name]: value });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
   

    emailjs
      .send(
        "service_jb907fi", //service ID
        "template_ynk4ru7", //template ID
        
          formData
       ,
        "WDHfK7ZJe1Kj7Y2Ex" //public key
      )
      .then(
        (result) => {
          console.log("SUCCESS!", result.status, result.text);
          alert("Feedback sent successfully!");
          setFormData({ name:'',email: '',phonenumber:'', message: '' });
        },
        (error) => {
          console.error("FAILED...", error);
          alert("Failed to send feedback. Please try again.");
        }
      )
     
  };
  return (
    <div>
       
          <Stack sx={{textAlign:'center' , backgroundColor:'primary.light' , padding:'30px 15px 70px 15px' }}>
         <Box sx={{ width: { xs: '100%', md: '80vw' }, margin: '0 auto' }} >
          <Grid container xs={12}  rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
            <Grid item xs={12} md={6} textAlign='left'>
            <Typography variant='h4' sx={{ fontSize:{xs:'25px',md:'32px'},color:'secondary.dark',fontWeight:'bold'}}>Send Us A Message</Typography>
            <Typography variant='body1' sx={{ fontSize:'16px',color:'secondary.contrastText'}}>Give us chance to serve</Typography>
            <form   onSubmit={onFormSubmit} sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Name"
                name="name"
                value={formData.name}
                onChange={onFormChange}
               type='text'
             
                fullWidth
                sx={{
                   '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'initial', // initial color
      },
      '&:hover fieldset': {
        borderColor: 'secondary.dark', // hover color
      },
      '&.Mui-focused fieldset': {
        borderColor: 'secondary.dark', // focused color
      },
    },
                    '& label': {
                        color: 'secondary.dark', // initial color
                        '&:hover': {
                          color: 'secondary.dark', // hover color
                        },
                        '&.Mui-focused': {
                            color: 'secondary.dark', // focused color
                          },
                      },
                  }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="email"
                value={formData.email}
                onChange={onFormChange}
               type="email"
                label="Email"
                fullWidth
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'initial', // initial color
                    },
                    '&:hover fieldset': {
                      borderColor: 'secondary.dark', // hover color
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'secondary.dark', // focused color
                    },
                  },
                    '& label': {
                        color: 'secondary.dark', // initial color
                        '&:hover': {
                          color: 'secondary.dark', // hover color
                        },
                        '&.Mui-focused': {
                            color: 'secondary.dark', // focused color
                          },
                      },
                  }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
               
                name="phonenumber"
                label="Phone Number"
                type='tel'
                value={formData.phonenumber}
                onChange={onFormChange}
                required
                fullWidth
                 sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'secondary.dark', // initial color
                    },
                    '&:hover fieldset': {
                      borderColor: 'secondary.dark', // hover color
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'secondary.dark', // focused color
                    },
                  },
                  '& label': {
                    color: 'secondary.dark', // initial color
                    '&:hover': {
                      color: 'secondary.dark', // hover color
                    },
                    '&.Mui-focused': {
                        color: 'secondary.dark', // focused color
                      },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                type='text'
                name="message"
                label="Message"
                value={formData.message}
                onChange={onFormChange}
                fullWidth
                multiline
                rows={4}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'secondary.dark', // initial color
                    },
                    '&:hover fieldset': {
                      borderColor: 'secondary.dark', // hover color
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'secondary.dark', // focused color
                    },
                  },
                  '& label': {
                    color: 'secondary.dark', // initial color
                    '&:hover': {
                      color: 'secondary.dark', // hover color
                    },
                    '&.Mui-focused': {
                        color: 'secondary.dark', // focused color
                      },
                  },
                }}
                 
              />
            </Grid>
          </Grid>
       
      
        <Button type="submit"   variant="outlined" endIcon={<ArrowOutward/>} sx={worksx} > SEND MESSAGE</Button>
   





        </form>

            </Grid>
          
            <Grid item xs={12} md={6}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13605.190739295545!2d74.3230169087329!3d31.515982968671224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190458d32a5d45%3A0x8cbd6ce309877ba4!2sI.T.%20Tower!5e0!3m2!1sen!2sus!4v1718442388519!5m2!1sen!2sus"   loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>  
            </Grid>
          </Grid>
          </Box>
          </Stack>
    </div>
  )
}

export default Contactform

