// products
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {ArrowOutward} from '@mui/icons-material';
import {Stack, Box,Grid, Typography, Button, Card,  CardContent,useTheme,useMediaQuery} from '@mui/material';
import pic from "../images/footer/bgimg.svg";
import bgimg from "../images/products/bg-img.png";
import '../css/products.css';

import { Link } from 'react-router-dom';
const cardsx ={
textDecoration:'none',
  marginRight:{xs:'10%',sm:'10%',md:'9%',lg:'8%'},
  marginLeft:{xs:'10%',sm:'10%',md:'9%',lg:'8%'},

 borderRadius: '25px',
 textAlign:'center',
 display:'flex',
 flexDirection:'column',
 justifyContent:'center',
 marginBottom:'40px',
 backgroundColor:'secondary.main',
 '&:hover':{
  transform:'scale(0.97)',
  transition: 'transform 0.5s ease-in-out',

 },
  
 
}




const exploresx={
color:'secondary.main',
borderRadius: 10,
width:200,
borderColor:'secondary.light',
backgroundColor:'secondary.light',

padding:'10px 15px',
  

}


 


const headingbg ={
  color: 'secondary.main',
  backgroundColor: 'primary.main',
  textAlign:'center',
  width:100,
  marginTop:1,
  borderRadius:1,
  fontSize:'16px',
}
const headingsx ={
  color: 'secondary.main',
  width:100,
  fontSize:'16px',
  marginLeft:1,
  marginTop:1,
}



const SliderprojectComponent = ({ data }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const isMediumScreen =useMediaQuery(theme.breakpoints.up("md"));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.up("xs"));

  let slidesToShow = 1;
  if (isLargeScreen) {
    slidesToShow = 3;
  } else if (isMediumScreen) {
    slidesToShow = 3;
  } else if (isSmallScreen) {
    slidesToShow = 2;
  } else if (isXSmallScreen) {
    slidesToShow = 1;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: false,
    swipe: true,
    lazyLoad: 'ondemand',
    autoplay:true,
    autoplaySpeed:2000,
    swipe: true,
    appendDots: (dots) => <ul style={{ margin: "0px" }}>{dots}</ul>,
    customPaging: (i) => (
      <button
        style={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: "#767676",
        }}
      />
    ),
  };

  return (
   
    <Stack id="section1" mt={10} padding={'50px 0px 150px 0px'} style={{ backgroundColor: '#020842' }}>
  <div className='app-container1'>
      <Stack direction="row" sx={{ textAlign: 'center', justifyContent: 'center', padding: 2 }}>
        <Typography variant='subtitle1' sx={headingbg}>Products</Typography>
        <Typography variant='subtitle1' sx={headingsx}>Categories</Typography>
      </Stack>
      
      <Grid container  width='100%' alignItems="center" justifyContent="center" mt={5}>
        <Grid item xs={12}>
          {/* Slick Slider with Cards */}
          <Slider {...settings}  width='100%'>
            {data.map((d,index) => (
               index<3?(
              <Box  key={index} >
                <Card sx={cardsx} component={Link} 
              to={d.plink} target="_blank"
              onClick={() => window.scrollTo(0, 0)}>
                  <Box
                    height={'220px'}
                    sx={{
                      width: '100%',
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: d.pbottom,
                      paddingBottom: '5px',
                      backgroundColor: '#f7f7f7',
                    }}
                  >
                    <img src={d.img} alt={d.name} style={{ width: '80%', height: '80%' }} />
                  </Box>
                  <CardContent
                    height={'250px'}
                    width={'100%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: { xs: '10px', sm: '20px', lg: '30px' },
                    }}
                  >
                    <Typography
                      height={'90px'}
                      variant="h6"
                      sx={{
                        marginTop: '13px',
                        width:'80%',
                        textAlign: 'center',
                        background: d.colors,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        fontSize: { xs: d.fxs, sm: d.fxs, lg: d.flg },
                        fontWeight: 'bold',
                      }}
                    >
                      {d.name}
                    </Typography>
                    {/* <Box sx={{ height: '35px',  marginLeft: '5px', width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'left' }}>
                      {d.types.map((type, i) => (
                        <Typography
                          key={i}
                          variant="body1"
                          sx={{
                            height: '17px',
                            fontSize: { xs: '9px', sm: '10px', lg: '10px' },
                            textDecoration: 'none',
                            borderRadius: '20px',
                            border: '1px solid rgba(237, 237, 237, 1)',
                            margin: '5px 0px 5px 5px',
                            color: 'secondary.contrastText',
                            backgroundColor: 'rgba(247, 247, 247, 1)',
                            padding: { xs: '2px 8px 1px 8px ', sm: '2px 15px 2px 15px' },
                          }}
                        >
                          {type}
                        </Typography>
                      ))}
                    </Box> */}
                    <Stack sx={{ marginTop: '40px', alignItems: 'center', justifyContent: 'center' }}>
                      <Button component={Link}
      to={d.plink} target="_blank" variant="contained" endIcon={<ArrowOutward />} sx={exploresx}>Learn More</Button>
                    </Stack>
                  </CardContent>
                </Card>
              </Box>
            ): <Box  key={index}>
            <Card sx={cardsx} component={Link} 
         to={d.plink} target="_blank"
          onClick={() => window.scrollTo(0, 0)}>
              <Box
                height={'220px'}
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: d.pbottom,
                  paddingBottom: '5px',
                  backgroundColor: '#f7f7f7',
                }}
              >
                <img src={d.img} alt={d.name} style={{ width: '80%', height: '80%' }} />
              </Box>
              <CardContent
                height={'250px'}
                width={'100%'}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: { xs: '10px', sm: '20px', lg: '30px' },
                }}
              >
                <Typography
                  height={'90px'}
                  variant="h6"
                  sx={{
                    marginTop: '13px',
                    
                    textAlign: 'center',
                    background: d.colors,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    fontSize: { xs: d.fxs, sm: d.fxs, lg: d.flg },
                    fontWeight: 'bold',
                  }}
                >
                  {d.name}
                </Typography>
               
                <Stack sx={{ marginTop: '40px', alignItems: 'center', justifyContent: 'center' }}>
                  <Button component={Link}
      to={d.plink} target="_blank" variant="contained" endIcon={<ArrowOutward />} sx={exploresx}>Learn More</Button>
                </Stack>
              </CardContent>
            </Card>
          </Box>
            ))}
          </Slider>
        </Grid>
      </Grid>
      </div>
    </Stack>
   
  );
};

export default SliderprojectComponent;