import React from 'react'
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import {  Card,Button, CardContent,Typography } from '@mui/material';

import '../css/intro.css';

const headingbg ={
  color: 'secondary.main',
  backgroundColor: 'primary.main',
  textAlign:'center',
  width:50,
  marginTop:1,
  borderRadius:1,
  fontSize:'16px',
  padding:0,
}
const headingsx ={
  color: 'secondary.contrastText',
  width:70,
  fontSize:'16px',
  marginLeft:1,
  marginTop:1,
}
  const headingwg ={
    color:'secondary.dark',
    fontWeight:'bold',
    fontSize:{xs:'30px',sm:'36px',md:'40px'},
    marginTop:'18px',
    textAlign:'center',
  }
  
  const servtxt={
    
    fontWeight:1000,
   
   marginBottom:5,
    width:300,
    marginTop:-20,
    color:'secondary.main',
    marginLeft: 5,
  
  }
  const servtext={
    fontWeight:800,
   
    height:90,
   
    marginTop:-20,
    color:'secondary.main',
    
    md:{

    fontWeight:500,
   
    }
    
  
  }
  const servicon={
    backgroundColor:'secondary.main',
    color:'secondary.dark',
    borderRadius: '50%',
   
    fontSize:50,
    padding:1,
   
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor:'secondary.main',
      border: '1px solid #ffffff1a',
      
      }

  }
  const buttonsx={
    color:'secondary.dark',
    borderRadius: 10,
    width:{xs:200,md:250},
   
    marginTop:{xs:5,md:7},
    padding:{xs:'10px 10px',md:'23px 40px'},
    fontSize:'14px',
    textAlign:'center',
    justifyContent:'center',
    borderColor:'rgba(0,0,0,.1)',
    fontWeight:'bold',
    '&:hover': {
      backgroundColor: 'secondary.light',
      borderColor:'secondary.light',
      color:'secondary.main',
      }
  }


  const GridComponent = ({ data }) =>  {
  return (
    <div className='app-container'>
       <Stack  p={2} mt={'50px'} >
    <Stack  direction="row" sx={{textAlign:'center', justifyContent:'center' }}>
      <Typography variant='subtitle1'sx={headingbg}>Our</Typography>
      <Typography variant='subtitle1'sx={headingsx}>Specialize</Typography>
    </Stack> 
    <Typography variant='h5'sx={headingwg} mb={8}><span>Featured Services</span></Typography>
    {/* <Stack direction="row"> */}
  {/* <Box width="100%"> */}
  <Grid container spacing={{xs:2,md:3}} sx={{ overflowX: 'hidden',paddingBottom:'5px'}}>
      {data.map((item, index) => (
        index<2?(
        <Grid 
          item 
          key={index} 
          xs={12}  md={6} 
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Card sx={{ 
               height: 500, 
               width: '100%', 
               maxWidth: '100%', 
               position: 'relative', 
               overflow: 'hidden', 
               borderRadius: 5 ,
               '&:hover':{
                transform:'scale(0.97)',
                transition: 'transform 0.5s ease-in-out',
              
               },
               '&:hover img ': { transform: 'scale(1.08)' }
                
             }} component={Link} 
             to="/Contactpage"
              onClick={() => window.scrollTo(0, 0)}>
               {/* Image Container */}
               <Box
                 sx={{
                   height: '100%',
                   overflow: 'hidden',
                   '& img': { 
                     filter:'brightness(70%)',
                     width: '100%', 
                     height: '100%', 
                     objectFit: 'center',
                     transition: 'transform 0.5s ease-in-out',
                   },
                 }}
               >
                 <img src={item.picture} alt={item.name} />
               </Box>
               
               {/* Text and Icon Overlay */}
               <Box 
                 sx={{
                   position: 'absolute',
                   bottom: 0,
                   left: 0,
                   width: '100%',
                   padding: '20px',
                     
                   background: 'rgba(0, 0, 0, 0)', // Optional background for better contrast
                   color: 'secondary.main', // Adjust text color for readability
                   boxSizing: 'border-box',
                 
                 }}
               >
                 <Typography 
                   variant="h5" 
                   sx={{ fontSize:{xs:'24px',sm:'28px',md:'32px'},fontWeight:'bold', mb: 1 ,height:{xs:60,md:90} }}
                 >
                   {item.name}
                 </Typography>
                 <Stack direction={{xs:'column-reverse',sm:'row'}} sx={{alignContent:'center'}}>
              <Typography 
                component={Link} 
                to="/Contactpage"
                sx={{  color: 'white' }} 
                onClick={() => window.scrollTo(0, 0)}
              >
                <ArrowOutwardIcon sx={servicon} />
              </Typography>
              <Box sx={{width:{xs:'98%',md:'90%'},display:'flex', flexDirection:'row',alignItems:'center', justifyContent:{xs:'left',sm:'right'},marginBottom:{xs:'20px',sm:'0px'}}}>
                 
              
                      {item.types.map((type, i) => (
                        <Typography
                          key={i}
                          variant="body1"
                          sx={{fontSize:'14px',  borderRadius:'5px',margin:'15px 5px 0px',
                            color: 'white',backgroundColor:'#ffffff1a',
                            padding:'4px 10px 4px', border:'1px solid #E3F0FF',
                            '&:hover':{backgroundColor:'secondary.main',
                              color:'secondary.dark',borderColor: 'rgba(255, 255, 255, 0.15)'} 
                          }}
                        >
                          {type}
                        </Typography>
                      ))}
                   
              </Box>
              </Stack>
               </Box>
             </Card>
        </Grid>):index <5?(
        
           <Grid 
             item 
             key={index} 
             xs={12}  md={4} 
             sx={{ display: 'flex', justifyContent: 'center' }}
           >
             <Card sx={{ 
               height: 500, 
               width: '100%', 
               maxWidth: '100%', 
               position: 'relative', 
               overflow: 'hidden', 
               borderRadius: 5 ,
               '&:hover':{
                transform:'scale(0.97)',
                transition: 'transform 0.5s ease-in-out',
              
               },
               '&:hover img ': { transform: 'scale(1.08)' }
             }}component={Link} 
             to="/Contactpage"
              onClick={() => window.scrollTo(0, 0)}>
               {/* Image Container */}
               <Box
                 sx={{
                   height: '100%',
                   overflow: 'hidden',
                   '& img': { 
                     width: '100%', 
                     height: '100%', 
                     objectFit: 'center',
                     transition: 'transform 0.5s ease-in-out',
                   },
                 }}
               >
                 <img src={item.picture} alt={item.name} />
               </Box>
               
               {/* Text and Icon Overlay */}
               <Box 
                 sx={{
                   position: 'absolute',
                   bottom: 0,
                   left: 0,
                   width: '100%',
                   padding: '20px',
                   background: 'rgba(0, 0, 0, 0)', // Optional background for better contrast
                   color: 'secondary.main', // Adjust text color for readability
                   boxSizing: 'border-box',
                 
                 }}
               >
                 <Typography 
                   variant="h5" 
                   sx={{ fontSize:{xs:'24px',sm:'28px',md:'32px'},fontWeight:'bold', mb: 1 ,height:{xs:60,md:90} }}
                 >
                   {item.name}
                 </Typography>
                 <Stack direction={{xs:'column-reverse',sm:'row'}} sx={{alignContent:'center'}}>
              <Typography 
                component={Link} 
                to="/Contactpage"
                sx={{  color: 'white' }} 
                onClick={() => window.scrollTo(0, 0)}
              >
                <ArrowOutwardIcon sx={servicon} />
              </Typography>
              <Box sx={{width:{xs:'98%',md:'90%'},display:'flex', flexDirection:'row',alignItems:'center', justifyContent:{xs:'left',sm:'right'},marginBottom:{xs:'20px',sm:'0px'}}}>
                 <Typography 
                 variant='body1'
                 sx={{fontSize:'14px',  borderRadius:'5px',margin:'15px 5px 0px',color: 'white',backgroundColor:'#ffffff1a',padding:'4px 10px 4px', border:'1px solid #E3F0FF','&:hover':{backgroundColor:'secondary.main',color:'secondary.dark',borderColor: 'rgba(255, 255, 255, 0.15)'} }} 
              >
                {item.category1}
              </Typography>
              <Typography
              variant='body1'
               
              sx={{fontSize:'14px',  borderRadius:'5px',margin:'15px 5px 0px',color: 'white',backgroundColor:'#ffffff1a',padding:'4px 10px 4px', border:'1px solid #E3F0FF','&:hover':{backgroundColor:'secondary.main',color:'secondary.dark',borderColor: 'rgba(255, 255, 255, 0.15)'} }} 
              >
                  {item.category2}
              </Typography>
             
              </Box>
              </Stack>
               </Box>
             </Card>
           </Grid>
        
        ):null
    
      ))}
    </Grid>
  
        <Stack  p={2} sx={{alignItems:'center', justifyContent:'center' }} >
        <Button variant="outlined" endIcon={<ArrowOutwardIcon/>} sx={buttonsx}><span>More Services</span></Button>
        </Stack>
    </Stack>
   
    </div>
  )
}

export default GridComponent;




