import React,{useState, useRef} from 'react'
import { styled } from '@mui/material/styles';
import "../css/footer.css";
import { Link } from 'react-router-dom';
import { Send } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import fimg from '../images/footer/footer.svg';
import { LinkedIn, Facebook,X,Instagram} from '@mui/icons-material';
import { MailOutline, PhoneInTalkOutlined ,LocationOnOutlined, WhatsApp,PhoneIphone} from '@mui/icons-material';
import emailjs from 'emailjs-com';
import SendIcon from "@mui/icons-material/Send";
import {FormControl, InputLabel, Input, InputAdornment, IconButton,Typography,Paper,TextField, ListItem,List,Button,Menu, MenuItem} from '@mui/material';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "transparent",
    boxShadow:'none',
    textAlign: {xs:'left',md:'center'},
    paddingTop:40,
    paddingLeft:40,
    color: theme.palette.secondary.main,
   

  }));
  const Item1 = styled(Paper)(({ theme }) => ({
    backgroundColor: "transparent",
    boxShadow:'none',
    textAlign: {xs:'left',md:'center'},
    paddingTop:40,
    paddingLeft:40,
    color: theme.palette.secondary.main,
    display:'flex',
    flexDirection:'row',
    
   

  }));
  const icon1sx={
    padding:1,
    color:'secondary.main',
    fontSize: 50,
  }
  const linksx={
    padding:0.7,
    margin:'20px 2px 2px 2px',
    
    color:'secondary.main',
    fontSize: '12px',
    borderRadius:'10px',
    backgroundColor:'transparent',
    border:'1px solid #1b2155',
    textAlign:'center',
    textDecoration:'none',
    '&:hover':{
      backgroundColor:'secondary.light',
      textDecoration:'none',
    }
  }
const iconsx ={
    padding:1,
    borderRadius:'50%',
    color:'secondary.main',
    backgroundColor:'rgba(255,255,255,.2)',
    fontSize: 50,
    textAlign:'center',
    marginRight:'15px',
    '&:hover':{
      color:'secondary.dark',
      backgroundColor:'secondary.main',
    }
}
const fdata={
    padding:0,
    fontSize:'18px',
    fontWeight:'500',
    textAlign:'left',
    color:'secondary.main',
    textDecoration:'none',
    marginLeft:0,
    '&:hover': {
        textDecoration:'underline',
        
      },
}

export default function Footer({ sectionId, label }) {
  const scrollToSection = () => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
          
  const [formData, setFormData] = useState({
    email: "",
    feedback: "",
  });
  const [isSending, setIsSending] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);

    emailjs
      .send(
        "service_jb907fi", // Replace with your service ID
        "template_dsdqz7i", // Replace with your template ID
        {
          email: formData.email,
          feedback: formData.feedback,
        },
        "WDHfK7ZJe1Kj7Y2Ex" // Replace with your public key
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Feedback sent successfully!");
          setFormData({ email: "", feedback: "" });
        },
        (error) => {
          console.error("FAILED...", error);
          alert("Failed to send feedback. Please try again.");
        }
      )
      .finally(() => setIsSending(false));
  };
    
  const [anchorEl, setAnchorEl] = useState(null);

const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
};
   
  
  return (
    <div>
        <div className="container">
           <Typography variant='h2' sx={{width:{xs:'95%',sm:'90%'},padding:{xs:'2px',sm:'5px'},color: '#FFFFFF',fontSize:{ xs:'36px',sm:'50px'},
    
    fontWeight: {xs:700,sm:900},
    textAlign: 'center', fontFamily:'Axiforma, sans-serif'}}>To Work together,Let's Chat</Typography>

           <Typography variant='body1' sx={{  width:{xs:'85%', sm:'90%',md:'50%'},
    padding:'5px',
    textAlign: 'center',
    color: 'secondary.main',
    opacity:0.8,
    fontSize: '18px',
    fontWeight: {xs:300,sm:500,md:700},
  fontFamily:'Axiforma, sans-serif',
    lineHeight: '30px',}}>Our team of experts is ready to collaborate with you every step of the way , from initial consultation to implementation.</Typography >
           <Button variant="contained" component={Link} to='/Contactpage'  sx={{marginTop: '40px',
    padding: '23px 40px',
    backgroundColor: 'secondary.light',
    color:'secondary.main',
    borderRadius: '40px',
    textDecoration: 'none',
    fontSize: '18px',
    fontWeight: 500,'&:hover': {
    backgroundColor: 'secondary.main',
    borderColor:'secondary.main',
    color:'secondary.light',
    
   }}}>Contact Us Today!</Button>

        </div>
    <Grid container  sx={{backgroundColor:'#020842', backgroundImage: `url(${fimg})`,backgroundRepeat:'no-repeat' ,backgroundSize:'center',backgroundPosition:'center',bottom:0 }} xs={12}>
        <Grid item xs={12} textAlign={'left'} sx={{borderBottom:'1px solid ',borderColor:'#1b2155',margin:2, paddingBottom:'40px'}}>
            <Box >
                <Grid container columnSpacing={1} rowSpacing={2}>
                    <Grid item xs={12} sm={6} md={4} >
                    <Item1>
                        <Link target="_blank" to="mailto:info@ar.com.pk"><MailOutline    sx={iconsx}/></Link>
                        <Box>
                        <Typography variant='body1' sx={{fontSize:'15px',color:'primary.contrastText'}} >Write to us</Typography>
                        <Typography variant='h6'sx={{fontSize:{xs:'16px',sm:'20px'},fontWeight:'500',color:'secondary.main'}}>info@ar.com.pk</Typography>
                        </Box>
                    </Item1>
                    </Grid>
                  
                    <Grid item xs={12} sm={6} md={4}>
                    <Item1>
        
                    
                        <Box>
                        <Box component="div" display="flex" flexDirection="row">
                        <a href="http://wa.me/+923005710318" >< PhoneIphone sx={iconsx}/></a>
                        <Box component="div" display="flex" flexDirection="column">
                        <Typography variant='body1' sx={{fontSize:'15px',color:'primary.contrastText',}} >Call Us</Typography>
                        <Typography variant='h6'sx={{fontSize:{xs:'16px',sm:'20px'},fontWeight:'500',color:'secondary.main'}}>  +92 300 5710318 </Typography>
                        </Box>
                        </Box>
                        <Box  component="div" display="flex" flexDirection="row">
                        <a href="tel:+924235710318" >< PhoneInTalkOutlined sx={iconsx}/></a>
                        <Typography variant='h6'sx={{fontSize:{xs:'16px',sm:'20px'},fontWeight:'500',color:'secondary.main',marginTop:'15px'}}>  +92 42 35710318 </Typography>
                        </Box>
                        </Box>
                       
                    </Item1>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>

                    <Item1>

                    <Link to="https://maps.app.goo.gl/6NyesRFYEQtQFjJi6" target='blanket'> <LocationOnOutlined sx={iconsx}/></Link>
                        <Box>
                        <Typography variant='body1' sx={{fontSize:'15px',color:'primary.contrastText'}} >Our Office</Typography>
                        <Typography variant='h6'sx={{fontSize:{xs:'16px',sm:'20px'},fontWeight:'500',color:'secondary.main'}}>Office #53, P (Paradise) Floor I.T Tower 73-E1 Hali Rd
                        Gulberg III Lahore, Pakistan</Typography>
                        </Box>
                    </Item1>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
  
  
        <Grid item xs={12} sx={{paddingBottom:'20px',borderBottom:'1px solid ',borderColor:'#1b2155',margin:2}}  >
        <Box >
        <Grid container columnSpacing={1} rowSpacing={2} textAlign={'left'}>
            <Grid item xs={12} sm={6} md={3}>
              <Item>
              
  <Typography
    variant="h6"
    sx={{ fontSize: '16px', color: 'primary.contrastText', mb: 1 }}
  >
    Feedback
  </Typography>
  <Typography
    variant="body1"
    sx={{
      fontSize: '12px',
      color: 'primary.contrastText',
      mt: 2,
      mb: 2,
    }}
  >
    Your feedback helps us grow—share your thoughts and make a difference.
  </Typography>
<Box  component="form"
      onSubmit={handleSubmit}
      sx={{ display: "flex",
        flexDirection: "column",}}>
  
  <TextField
        label="Email"
        variant="standard"
        name="email"
        value={formData.email}
        onChange={handleChange}
        fullWidth
        required
        InputLabelProps={{
          style: { color: "#fff" }, // Label color
        }}
        InputProps={{
          style: { color: "#fff" }, // Text color
        }}
        sx={{
         "& .MuiInput-underline:before": {
              borderBottomColor: "#fff", // Default underline color
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "#fff", // Hover underline color
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#fff", // Focused underline color
            },
        }}
      />
       <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
       <TextField
          label="Feedback"
          variant="standard"
          name="feedback"
          value={formData.feedback}
          onChange={handleChange}
         
          fullWidth
          required
          InputLabelProps={{
            style: { color: "#fff" }, // Label color
          }}
          InputProps={{
            style: { color: "#fff" }, // Text color
          }}
          sx={{
            "& .MuiInput-underline:before": {
              borderBottomColor: "#fff", // Default underline color
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "#fff", // Hover underline color
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#fff", // Focused underline color
            },
          }}
        />
         <IconButton
          type="submit"
          color="#fff"
          sx={{ height: "56px" }}
          disabled={isSending}
        >
          <SendIcon sx={{ color: "#fff" }} />
        </IconButton>
        <Box/>
    

 
  </Box>
</Box>


      <Box sx={{marginTop:'5px',width:'99%',display:'flex',flexWrap:'wrap'}}>
    
                <Link to="mailto:info@ar.com.pk" target='_blank'><MailOutline sx={icon1sx}/></Link>
                <Link to="https://www.facebook.com/" target='_blank'><Facebook sx={icon1sx}/></Link>
                <Link to="https://www.linkedin.com/company/advance-resources---best-it-services-providers-in-pakistan/posts/?feedView=all" target='_blank'><LinkedIn sx={icon1sx}/></Link>
                <Link to="https://www.instagram.com/advance_resources.pk" target='_blank'><Instagram  sx={icon1sx}/></Link>
                <Link to="http://wa.me/+923005710318" target='blanket'><  WhatsApp sx={icon1sx}/></Link>
   
                
            </Box>
    </Item>
            </Grid>
            <Grid item xs={12} sm={6}  md={3}>
            <Item>
            <Typography variant='h6' sx={{fontSize:'16px',color:'primary.contrastText'}}>Links </Typography>
                    <List>
                        <ListItem sx={{paddingLeft:0,paddingY:'0.1'}}><Typography component={Link} to='/'  sx={fdata} onClick={() => window.scrollTo(0, 0)}>Home</Typography ></ListItem>
                        <ListItem sx={{paddingLeft:0,paddingY:'0.1'}}><Typography  component={Link} to='/Aboutpage' onClick={() => window.scrollTo(0, 0)}  sx={fdata}>About</Typography ></ListItem>
                        <ListItem sx={{ paddingLeft: 0, paddingY: '0.1' }}>
          <Typography
            sx={fdata}
            aria-controls="products-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            Products
          </Typography>
          <Menu
            sx={{
              '& .MuiPaper-root': {
                backgroundColor: '#020842',
              },
            }}
            id="products-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}  sx={{backgroundColor:'#020842'}}>
              <Typography component={Link} backgroundColor={'#020842'} to="/Yeastarpage" sx={fdata} onClick={() => window.scrollTo(0, 0)}>
                Yeastar
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleClose}  sx={{backgroundColor:'#020842'}}>
              <Typography component={Link} to="/Yealinkpage" sx={fdata} onClick={() => window.scrollTo(0, 0)}>
                Yealink
              </Typography>
            </MenuItem>
          </Menu>
        </ListItem>
                         <ListItem sx={{paddingLeft:0,paddingY:'0.1'}}><Typography  component={Link} to='/Servicespage' onClick={() => window.scrollTo(0, 0)} sx={fdata}>Services</Typography ></ListItem>
                         <ListItem sx={{paddingLeft:0,paddingY:'0.1'}}><Typography component={Link} to='/Contactpage' onClick={() => window.scrollTo(0, 0)} sx={fdata}>Contact</Typography ></ListItem>
                    </List>
            </Item>
            </Grid>
            <Grid item xs={12} sm={6}  md={3}>
            <Item> <Typography variant='h6' sx={{fontSize:'16px',color:'primary.contrastText'}}>Services</Typography>
                    <List>
                        <ListItem sx={{paddingLeft:0,paddingY:'0.1'}}><Typography  component={Link} to='/Servicespage' onClick={() => window.scrollTo(0, 0)} sx={fdata}>Surveillance Solutions</Typography ></ListItem>
                        <ListItem sx={{paddingLeft:0,paddingY:'0.1'}}><Typography  component={Link} to='/Servicespage' onClick={() => window.scrollTo(0, 0)}  sx={fdata}>IT infrastructure</Typography ></ListItem>
                        <ListItem sx={{paddingLeft:0,paddingY:'0.1'}}><Typography  component={Link} to='/Servicespage' onClick={() => window.scrollTo(0, 0)}  sx={fdata}>VOIP Solutions</Typography ></ListItem>
                        <ListItem sx={{paddingLeft:0,paddingY:'0.1'}}><Typography  component={Link} to='/Servicespage' onClick={() => window.scrollTo(0, 0)} sx={fdata}>AMC services</Typography ></ListItem>
                        <ListItem sx={{paddingLeft:0,paddingY:'0.1'}}><Typography  component={Link} to='/Servicespage' onClick={() => window.scrollTo(0, 0)} sx={fdata}>IT Audit</Typography ></ListItem>
                    </List></Item>
            </Grid>
            <Grid item xs={12} sm={6}  md={3}>
            <Item>
             <Typography variant='h6' sx={{fontSize:'16px',color:'primary.contrastText'}}>Services </Typography>
                    <List>
                    <ListItem sx={{paddingLeft:0,paddingY:'0.1'}}><Typography component={Link} to='/Servicespage'  sx={fdata} onClick={() => window.scrollTo(0, 0)}>Social Media Marketing</Typography ></ListItem>
                        <ListItem sx={{paddingLeft:0,paddingY:'0.1'}}><Typography  component={Link} to='/Servicespage' onClick={() => window.scrollTo(0, 0)}  sx={fdata}>Web Development</Typography ></ListItem>
                        <ListItem sx={{paddingLeft:0,paddingY:'0.1'}}><Typography  component={Link} to='/Servicespage'  onClick={() => window.scrollTo(0, 0)} sx={fdata}>App Development</Typography ></ListItem>
                        
                         <ListItem sx={{paddingLeft:0,paddingY:'0.1'}}><Typography  component={Link} to='/Servicespage' onClick={() => window.scrollTo(0, 0)} sx={fdata}>Hosting Services</Typography ></ListItem>
                         <ListItem sx={{paddingLeft:0,paddingY:'0.1'}}><Typography component={Link} to='/Servicespage' onClick={() => window.scrollTo(0, 0)} sx={fdata}>UI/UX Design</Typography ></ListItem>
                    </List>
            </Item>
            </Grid>
        </Grid>
        </Box>
        </Grid>
      
        <Typography variant="subtitle2" sx={{color:'secondary.main',width:'100%',textAlign:'center'}}>© Advance Resources 2024 All right reserved.</Typography>
    </Grid>
   
   
   
    </div>
  )
}


