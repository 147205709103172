import React from 'react'
import bgimage from '../images/contact/bg_pattern_4.svg';
import { Button,Typography,Link} from '@mui/material';

import styles1 from '../css/aboutpage.module.css';
import styles2 from '../css/contactpage.module.css';
function Contactheader(props)  {
const styles = props.location === 'about' ? styles1 : styles2;
  return(
      <div>
    
      <div className={styles.bgdetails}>
         
          <h2>{props.heading}</h2>
         
      </div>
     
      </div>
  )
}



export default Contactheader
