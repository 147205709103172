
// this file is for the section containing main slider and the four box having basic intro just below the navbar
import React, { useState } from 'react';
import { ArrowBackIos,ArrowForwardIos,ChevronLeft,ChevronRight } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import {Stack, Card,Box,Grid,Paper, Typography,Button,useTheme,useMediaQuery} from '@mui/material';
import {ArrowOutward} from '@mui/icons-material';
import intro1 from "../images/services/intro1.png";
import intro4 from "../images/services/intro4.png";
import "../css/intro.css";
import {Add} from '@mui/icons-material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { keyframes } from '@emotion/react';
import intro2 from"../images/intro/p1.png";
import { Link } from 'react-router-dom';
const Imagebox = styled(Paper)(({ theme }) => ({
  
   height:260,
   
   boxSizing:'border-box',
  marginLeft: {
    xs: '20px',
    md: '0px',
  },
   backgroundColor:'primary.main',
   boxShadow:'none',
   borderRadius: {
    xs: '25px',
    md: '0px 25px 0px 45px',
  },
   
   

  }));
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    const islarge =useMediaQuery((theme)=> theme.breakpoints.up('lg'));
    return (
      islarge && (
     <div
       
        onClick={onClick}
        style={{  display: 'flex' ,color: 'secondary.dark', zIndex: 10, left: 20, width:'30px',height:'30px',borderRadius:'50%',textAlign:'center',alignItems:'center',justifyContent:'center',boxSizing:'border-box',cursor:'pointer',border:'2px solid white',position:'absolute',top:'50%',transform:'translateY(-50%)', '&:hover': {color: 'secondary.main', backgroundColor:'secondary.dark'}}}
>
        <ChevronLeft sx={{ fontSize:'20px',color:'secondary.main'}} />
        </div>
      )
    );
  };
  const CustomNextArrow = (props) => {
    const {  onClick } = props;
    const islarge =useMediaQuery((theme)=> theme.breakpoints.up('lg'));
    return (
     islarge &&(
      <div
     
      onClick={onClick}
      style={{  display: 'flex' ,color: 'secondary.dark', zIndex: 10, right: 20, width:'30px',height:'30px',borderRadius:'50%',textAlign:'center',alignItems:'center',justifyContent:'center',boxSizing:'border-box',cursor:'pointer',border:'2px solid white',position:'absolute',top:'50%',transform:'translateY(-50%)', '&:hover': {color: 'secondary.main', backgroundColor:'secondary.dark'}}}
>
<ChevronRight  sx={{ color:'white', fontSize:'20px'}} />
      </div>
     )
    );
  };

 
  
  const Intro = ({data}) => {
    
    
   
         
   
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay:true,
      autoplaySpeed:2000,
      arrows: true,
      swipe: true,
      centerMode:true,
      centerPadding:'0%',
     lazyLoad:'ondemand',
     nextArrow:  <CustomNextArrow/> ,
     prevArrow:  <CustomPrevArrow/> ,
    
    };
  
return(

      <div className='app-container'>
       
           <Stack display='flex'direction="row"  mt={{xs:'100px',sm:'100px', md:'120px'}} mb={'50px'} >
     
     <Grid container  width={'100%'} display={'flex'} justifyContent={'center'}  >
         
      
       
          
     <Grid item xs={12} lg={7}  height={520} alignItem={'center'} backgroundColor={'none'}   >
     <Slider {...settings}   >
     
     {data.map((d,index) => (
      index < 1 ? (
      <Grid item key={index} >
     
           <Box key={index} sx={{  width:'100%' , height: 520, }}>
          
            <img  src={d.bgimg} alt={d.name}  style={{ width: '100%', height: '100%',borderRadius:'40px',padding:'5px 15px',objectFit:'cover'}}/>
          
            <Box sx={{  width: '100%', height:500,display:'flex',justifyContent:'center',flexDirection:'column',position:'absolute',zIndex:2 ,top:15,paddingLeft:{xs:5,lg:10}}}>
            <img  src={d.logo} alt={d.name}  style={{borderRadius:'40px',  width: '200px', height: {xs:'50px',md:'60px'},objectFit:'cover' }}/>
          
            <Typography variant='h3' width={{xs:'250px',sm:'400px',md:'700px',lg:'600px'}} sx={{  fontSize:{xs:'35px',sm:'35px',md:'50px',lg:'55px'}, marginTop:'10px',color:'secondary.dark',fontWeight:'bold'}}>{d.heading}</Typography>
           
            <Typography variant='h6'  sx={{ fontSize:{xs:'16px',sm:'17px',md:'18px'},marginTop:'15px',width:{  xs:'250px',sm:'400px', md:'600px'},color:'secondary.contrastText',lineHeight: 1.2}}>{d.para}</Typography>
            <Button component={Link} to='/Contactpage' onClick={() => window.scrollTo(0, 0)} variant="contained" endIcon={<ArrowOutward/>} sx={{marginTop:'15px',border: '1px solid ',borderColor:'secondary.dark', color:'secondary.dark',borderRadius: 10,width:200,
                  backgroundColor: 'transparent',padding:'10px 10px',fontSize:'14px',textAlign:'center',
                  justifyContent:'center',boxShadow:'none',
                  '&:hover': { backgroundColor: 'secondary.light', borderColor:'secondary.light',color:'secondary.main',padding:'10px',
                  fontSize:'14px'}}}>Contact Us</Button>
            </Box>
          </Box>
          </Grid>
      ):(
        <Grid item key={index}  >
     
        <Box key={index} sx={{  width:'100%' , height: 520, }}>
       
         <img  src={d.bgimg} alt={d.name}  style={{ width: '100%', height: '100%',borderRadius:'40px',filter:'brightness(70%)',padding:'5px 15px',objectFit:'cover'}}/>
       
         <Box sx={{  width: '100%', height:500,display:'flex',justifyContent:'center',flexDirection:'column',position:'absolute',zIndex:2 ,top:15,paddingLeft:{xs:5,lg:10}}}>
         <img  src={d.logo} alt={d.name}  style={{borderRadius: '40px',  width: '200px', height: {xs:'50px',md:'60px'},objectFit:'cover' }}/>
       
         <Typography variant='h3' sx={{  width: { xs: '250px', sm: '500px', md: '800px' },fontSize:{xs:'28px',sm:'34px',md:'40px'}, marginTop:'10px',color:'secondary.main',fontWeight:'bold'}}>{d.heading}</Typography>
        
         <Typography variant='h6'  sx={{ fontSize:{xs:'16px',sm:'17px',md:'18px'},marginTop:'15px',width:{  xs:'250px',sm:'400px', md:'600px'},color:'secondary.main',lineHeight: 1.2}}>{d.para}</Typography>
         <Button component={Link} to='/Contactpage'  variant="contained" endIcon={<ArrowOutward/>} sx={{marginTop:'15px',border: '1px solid #E3F0FF', color:'secondary.main',borderRadius: 10,width:200,
               backgroundColor: 'transparent',padding:'10px 10px',fontSize:'14px',textAlign:'center',
               justifyContent:'center',boxShadow:'none',
               '&:hover': { backgroundColor: 'secondary.light', borderColor:'secondary.light',color:'secondary.main',padding:'10px',
               fontSize:'14px'}}}>Contact Us</Button>
         </Box>
       </Box>
       </Grid>
      )
         
          
         
     ))}
    
      </Slider>
      </Grid>

   
    
     
         <Grid item xs={12} lg={5} display={'flex'} justifyContent={'center'} >
         <Box sx={{ width: {xs:'95%' , md:'97%'},mt:{xs:5,lg:0}}}>
         <Grid container spacing={{ xs: 1, md: 0 }}>
         <Grid item xs={6} sm={6} >
       
         
         <Imagebox sx={{
         
    img: {
      boxSizing: 'border-box',
      borderRadius: {
        xs: '25px',
        md: '25px 0px 0px 0px',
      },
      width: '100%',
     
      height: '100%',
       objectFit:'cover'
    },
  }}
>
  <img src={intro1} alt="intro" />
</Imagebox>
      
       </Grid>
     <Grid item xs={6} sm={6}>

       
        
     <Imagebox sx={{
     
    img: {
      boxSizing: 'border-box',
      borderRadius: {
        xs:'25px',
        md:'0px 25px 0px 125px'
      },
      width: '100%',
      height: '100%',
       objectFit:'fit'
    },
  }}
>
  <img src={intro2} alt="intro" />
</Imagebox>
        
      
     </Grid>
     <Grid item xs={6} sm={6}>
     
  
     <Box sx={{backgroundColor:'primary.main',height:260,display:'flex',justifyContent:'center',flexDirection:'column',borderRadius:{xs:'25px',md:'0px 125px 0px 25px'},padding: '0px 20px'}}>
     <Box sx={{display:'flex',justifyContent:'left',flexDirection:'row','&:hover': {'.hover-effect':{backgroundColor:'secondary.light'}}}} ><Typography variant='h6' sx={{fontSize:{xs:'9px',sm:'12px',lg:'10px'},color:'secondary.main',border:"1px solid #ffffff",borderRadius:'20px',padding:"12px 0px 8px 7px",width:{xs:105,lg:95},textDecoration:'none',}} component={Link} to="/Servicespage" className="hover-effect">AMC SERVICES</Typography><Add className="hover-effect" sx={{color:'secondary.main',fontSize:{xs:'20px',sm:'40px'}, marginTop:{xs:'8px',sm:'0px'},padding:{xs:0,sm:1},borderRadius:'50%',border:"1px solid #ffffff"}}/></Box>
     <Box sx={{display:'flex',justifyContent:'left',flexDirection:'row','&:hover': {'.hover-effect':{backgroundColor:'secondary.light'}}}} ><Typography variant='h6' sx={{fontSize:{xs:'9px',sm:'12px',lg:'10px'},color:'secondary.main',border:"1px solid #ffffff",borderRadius:'20px',padding:"12px 0px 8px 7px",width:{xs:150,lg:140},textDecoration:'none'}} component={Link} to="/Servicespage" className="hover-effect" >VoIP SOLUTIONS</Typography><Add className="hover-effect" sx={{color:'secondary.main',fontSize:{xs:'20px',sm:'40px'}, marginTop:{xs:'8px',sm:'0px'},padding:{xs:0,sm:1},borderRadius:'50%',border:"1px solid #ffffff"}}/></Box>
     <Box sx={{display:'flex',justifyContent:'left',flexDirection:'row','&:hover': {'.hover-effect':{backgroundColor:'secondary.light'}}}} ><Typography variant='h6' sx={{fontSize:{xs:'9px',sm:'12px',lg:'10px'},color:'secondary.main',border:"1px solid #ffffff",borderRadius:'20px',padding:"12px 0px 8px 7px",width:{xs:200,lg:180},textDecoration:'none'}} component={Link} to="/Servicespage"className="hover-effect">IT INFRASTRUCTURE</Typography><Add  className="hover-effect" sx={{color:'secondary.main',fontSize:{xs:'20px',sm:'40px'}, marginTop:{xs:'8px',sm:'0px'},padding:{xs:0,sm:1},borderRadius:'50%',border:"1px solid #ffffff"}}/></Box>
     </Box> 
     </Grid>   
     <Grid item xs={6} sm={6}>
       
     <Imagebox sx={{
    
    img: {
     
      boxSizing: 'border-box',
      borderRadius: {
        xs: '25px',
        md: '0px ',
      },
      width: '100%',
      height: '100%',
     objectFit:'fit'
    },
  }}
>
  <img src={intro4} alt="intro" />
</Imagebox>
        
      
     </Grid>
   </Grid>
 </Box>
         </Grid>


         
     </Grid>
    
    
    
      </Stack> 
      </div>        
)
};

export default Intro;



