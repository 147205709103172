import {createTheme } from '@mui/material'
import './App.css';
import './index.css';
const theme = createTheme({
 
    palette:
    {
      
      secondary:{
        main: "#FFFFFF", //white
        dark: "#020842",//dark grey
        light: "#0044EB",// blue
        contrastText: "#49515B" //black
      },
      primary:{
        main:"#f44380",//pink
        light:"#E3F0FF",//light blue
        contrastText:"#B6B8CA",
      }
  
    },
    typography:
    {
  
      fontFamily:'Axiforma, sans-serif',
      
    },
});

export default theme;