
import i1 from "../images/intro/gateway2.png";
import i2 from "../images/intro/ipphone.jpg";
import i3 from "../images/intro/meetingboard.webp";
import i4 from "../images/intro/PBX2.png";
import i5 from "../images/intro/vc.png";
import i6 from "../images/intro/3.webp";
import i0 from "../images/intro/bgimage.png";
import intro from "../images/intro/intro.png";
import yeastar from "../images/intro/Yeastar_Logo.png";
import yealink from "../images/intro/Yealink-LOGO-Green.png";
const data = [
  {
    name: 'slide0',
   bgimg: i0,
   logo: intro,
    heading: 'Grow your Business with Innovative IT Solution',
    para: "Deliver exceptional customer services with all the agent and supervisor tools that drive faster call resolution and boost agent productivity.",
    colortext:'secondary.dark',
  },
  {
    name: 'slide1',
   bgimg: i1,
   logo: yeastar,
    heading: 'Call Centre Solution',
    para: "Deliver exceptional customer services with all the agent and supervisor tools that drive faster call resolution and boost agent productivity.",
    colortext:'secondary.dark',
  },
  {
    name: 'slide2',
   bgimg: i4,
   logo: yeastar,
   heading: 'P-Series Phone System',
   para: "P Series Available in Appliance, Software & Cloud Editions.",
   colortext:'secondary.dark', 
  }, 
  {
    name: 'slide3',
   bgimg: i5,
   logo: yealink,
    heading: 'Video Conferncing System',
    para: "Yealink video conference systems enhance collaboration experiences, whether you're working from home, workspace or any remote location.",
    colortext:'secondary.dark',
  }, 
  {
    name: 'slide4',
   bgimg: i3,
   logo: yealink,
   heading: 'Meeting Board',
   para: "Yealink's interactive meeting boards enhance teamwork by enabling real-time content sharing and dynamic presentations, fostering collaborative environments.",
  }, {
    name: 'slide5',
   bgimg: i2,
   logo: yealink,
   heading: 'IP-Phone',
   para: "Yealink IP phones redefine communication with crystal-clear audio, user-friendly interfaces, and seamless connectivity—perfect for home, office, or any remote setup.",
   colortext:'secondary.dark',
  }, {
    name: 'slide6',
   bgimg: i6,
   logo: yealink,
   heading: 'Bluetooth Wireless Headset',
   para: "Applied industry-leading audio technologies, Yealink Headset Series delivers a crystal-clear, uninterrupted audio experience, even in noisy environments.",
   colortext:'secondary.dark',
  },
];

export default data;