import React from 'react';
import Footer from '../components/footer';
import Pageheader from '../components/pageheader';
import bgimg from '../images/yeastar/bgimg.jpg';
import {Navbar} from '../components/navbar';
import Yeastar from '../components/Yeastar';

import Stack from '@mui/material/Stack';
const Yeastarpage = () => {
  return (
    <div>
       <Navbar variant='absolute'/> 
       <Pageheader  heading="Yeastar" bgimg={bgimg} color="rgba(12, 95, 255, 1)"/>
       <Stack sx={{backgroundColor:'primary.light'}}>
      <Yeastar/>
      </Stack>
       <Footer /> 
    </div>
  )
}

export default Yeastarpage