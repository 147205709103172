
// import React from 'react';
// import yealinkproductdata from "../components/Yealinkproductdata";
// import { useEffect, useState } from "react";
// import logo from "../images/products/endlogo.png";
// import {ArrowOutward} from '@mui/icons-material';
// import {Stack, Box,Grid, Typography, Button, Card,  CardContent} from '@mui/material';
// import { Link } from 'react-router-dom';
// import '../css/yealinkproduct.css';
// import bgimg from "../images/products/bg-img.png";
// const exploresx={
//   color:'secondary.main',
//   borderRadius: 10,
//   width:200,
//   borderColor:'secondary.light',
//   backgroundColor:'secondary.light',
  
//   padding:'10px 15px',
    
  
//   }
 

    
   

// const Yealink = () => {

  


//   const [data,setData] = useState(yealinkproductdata);
//   const [filteredData , setFilteredData] = useState(yealinkproductdata);
//   const [collection,setCollection] = useState([]);
//   const [selectedFilter , setSelectedFilter] = useState('All');
//   const [subFilters ,setSubFilters]=useState([]); // to store sub filters
//   const [selectdSubFilter, setSelectedSubFilter] =useState('All');
//   const [flipped,setFlipped]=useState({}); //track flip stae of each card
//   const [checkedFilters , setCheckedFilters]=useState([]);
//   useEffect(()=>{
//     setData( yealinkproductdata);
//     setCollection([... new Set( yealinkproductdata.map((item)=> item.title))])
//   },[]) 

//   const gallery_filter = (itemData) =>{
//     const filterData =  yealinkproductdata.filter((item)=> item.title == itemData);
//     setData(filterData);
//     setSelectedFilter(itemData);
//     setSelectedSubFilter('All');
//     const allTypes = [...new Set(filterData.map(item => item.subtitle))];
//     setSubFilters(allTypes);
//   };
 
  

//   // Sub-filter logic (by type)
//   const handleSubFilter = (subtilte) => {
//     if (subtilte === 'All') {
//       setSelectedSubFilter('All');
//       setData(yealinkproductdata.filter(item => item.title === selectedFilter));
//     } else {
//       const filteredByType = yealinkproductdata.filter(item =>
//         item.title === selectedFilter && item.subtitle === subtilte
//       );
//       setData(filteredByType);
//       setSelectedSubFilter(subtilte);
//     }
//   };
// const handleMouseEnter =(id)=>{
//   setFlipped((prev)=> ({...prev,[id]:true}));
// };
// const handleMouseLeave =() =>{
//   setFlipped(false);
// };
// const handleClick = (id) => {
//   setFlipped((prev)=> ({...prev,[id]:false}));
// };
//   return (
//     <div >
     
//         <div className="filterItem-yealink">
//           <ul>
//             <li><button className={selectedFilter === 'All' ? 'active' :''} onClick={()=> {setData( yealinkproductdata); setSelectedFilter('All'); setSubFilters([]);}}>All</button></li>
//             {
//               collection.map((item)=> <li><button className={selectedFilter === item ? 'active' : ''} onClick={()=>{gallery_filter(item)}}>{item}</button></li>)
//             }
//           </ul>
//         </div>
//        <Grid container spacing={1} paddingBottom={10}>
//         <Grid item xs={12} md={2} >
//         {subFilters.length>0 &&(
//        <div className='subFilterItem' style={{textAlign:'left',paddingLeft:'20px',borderRight:'1px solid #000000'}}>
//         <ul style={{listStyleType:'none' , padding:0}}>
//           <li>
//             <button className={selectdSubFilter === 'All' ? 'active':''} 
//             onClick={() => handleSubFilter('All')} 
//             style={{ display:'block',color:'secondary.dark',paddingTop:'15px',paddingBottom:'20px',border:'none', backgroundColor:'transparent',width:'100%'

//             }}>
//               All TYPES
//             </button>
//           </li>
//           {subFilters.map((subtilte)=>(
//             <li key={subtilte}>
//               <button className={selectdSubFilter === subtilte ? 'active' :''}
//               onClick={()=> handleSubFilter(subtilte)} style={{ display:'block', paddingTop:'15px',color:'secondary.dark',paddingBottom:'15px',border:'none', backgroundColor:'transparent',width:'100%'}}>
//                 {subtilte}
//               </button>
//             </li>
//           ))}
//         </ul>
//         </div> 
//         )}
//         </Grid>
//         {/* product display */}
//       <Grid item   xs={12} md={10} >
//         <Grid container columnSpacing={{xs:2,md:2}} rowSpacing={{xs:2,md:3}}sx={{ overflowX: 'hidden',paddingBottom:'5px',paddingRight:'20px',paddingLeft:'20px'}}>
//           {
//             data.map((item)=>      <Grid 
//             item 
//             key={item.id} 
//             xs={12}  md={6} lg={4}
//             sx={{ display: 'flex', justifyContent: 'center' }}
//           > <Card  sx={{borderRadius: '25px',
//             textAlign:'center',
//             display:'flex',
//             flexDirection:'column',
//             width: '100%', 
//              maxWidth: '100%', 
//              perspective:'1000px',
//              height:'500px',
//             backgroundColor:'transparent',
//             cursor:'pointer',
//             border:'none',
//             boxShadow:'none'
//            }}
//            onMouseEnter={()=>handleMouseEnter(item.id)} onClick={()=>handleClick(item.id)}>
//         <Box sx={{position:'relative',width:'100%', height:'100%', transformStyle:'preserve-3d',transition:'transform 0.8s',transform:flipped[item.id] ? 'rotateY(180deg)': 'rotateY(0deg)'}}>
//         <Box sx={{ position:'absolute', width:'100%',display:'flex',
//             flexDirection:'column', backgroundColor:'secondary.main', textAlign:'center',backfaceVisibility:'hidden',height:'100%' }}>
//             <Box
//            height={'200px'}
//            sx={{  width:'100%',textAlign:'center',display:'flex', justifyContent: "center",
//             alignItems: "end",paddingBottom:'5px',backgroundColor:'#f7f7f7'}}
           

//          >
//              <img src={item.image} alt={item.title} style={{ width: '80%', height: '80%' }} />
             
//        </Box>
//               <CardContent sx={{height:{xs:'300px',md:'300px'},padding:'0px',}}>
//               <Typography variant="h6" sx={{textAlign:'left',marginLeft:'22px', background: item.colors,
//     WebkitBackgroundClip: 'text',
//     WebkitTextFillColor: 'transparent'
// ,fontSize:'40px',fontWeight:'bold'}}>
//                   {item.name}
//                 </Typography>
//                 <Box sx={{marginLeft:'20px',height:'50px', width:'85%',display:'flex', flexDirection:'row', flexWrap:'wrap',justifyContent:'left',}}>
//                  {item.types.map((type,i) => (
//                  <Typography 
//                  key={i}
//                  variant='body1'
               
//                 sx={{ fontSize:'14px', textDecoration:'none', borderRadius:'20px',border:'1px solid rgba(237, 237, 237, 1)',margin:'5px 5px',color: 'secondary.dark',backgroundColor:'rgba(247, 247, 247, 1)',padding:'10px 15px 8px 15px',}} >
//                 {type}
//               </Typography>
             
//               ))}
             
//               </Box>
//               <Typography variant='body1' sx={{padding:'0px 20px',marginTop:'20px',fontSize:'16px',color:'secondary.dark',textAlign:'center'}}>{item.details}</Typography>
//                  <Box
//                  height={'40px'} 
//                  sx={{textAlign:'center',display:'flex', justifyContent: "center",marginTop:'20px',
//                   alignItems: "end",paddingBottom:'2px',backgroundColor:'secondary.main'}}
                 
      
//                >
//                    <img src={logo} alt={item.title} style={{ width: '40px', height: '100%' }} />
                   
//              </Box>
             
//               </CardContent>
//               </Box>
//               <Box className="backflip" sx={{ transform:flipped[item.id] ? 'rotateY(0deg)': 'rotateY(-180deg)',position:'absolute', width:'100%',height:'100%', backfaceVisibility:'hidden',transform:'rotateY(180deg)',backgroundColor:'secondary.main',
//                 display:'flex',flexDirection:'column',  alignItems:'center' 
//               }}>
                
//               <Typography variant='h6' sx={{marginTop:'25px', marginBottom:'25px',fontSize:'25px', backgroundColor:'primary.light',color:'secondary.dark',fontWeight:'600',padding:'2px 10px',borderRadius:'10px'}}> {item.title} </Typography>
//               <Box component="ul" sx={{ textAlign:'left',listStyleType:'disc',marginLeft:'20px',height:'300px', width:'90%',display:'flex', flexDirection:'column', justifyContent:'left',}}>
//                  {item.backDetails.map((backdetail,i) => (
//                  <Typography component="li"
//                  key={i}
//                  variant='body1'
               
//                 sx={{ fontSize:'14px', textDecoration:'none',margin:'5px 5px',color: 'secondary.dark',padding:'10px 5px 8px 15px',}} >
//                 {backdetail}
//               </Typography>
             
//               ))}
             
//               </Box>
//                 <Stack   sx={{marginTop:'20px',alignItems:'center', justifyContent:'center', }}  >
//                 <Button variant="contained" endIcon={<ArrowOutward/>} component={Link} to='/servicespage' sx={exploresx}>Learn More</Button>
//                 </Stack>
//               </Box>
//               </Box>
//             </Card>
//             </Grid>
//             )
//           }
//           </Grid>
//         </Grid>
       
      
//         </Grid>
//     </div>
//   )
// }

// export default Yealink



// import React, { useEffect, useState } from 'react';
// import { Stack, Box, Grid, Typography, Button, Card, CardContent, Checkbox, FormControlLabel } from '@mui/material';
// import { Link } from 'react-router-dom';
// import { ArrowOutward } from '@mui/icons-material';
// import yealinkproductdata from '../components/Yealinkproductdata';
// import logo from '../images/products/endlogo.png';
// import '../css/yealinkproduct.css';

// const exploresx = {
//   color: 'secondary.main',
//   borderRadius: 10,
//   width: 200,
//   borderColor: 'secondary.light',
//   backgroundColor: 'secondary.light',
//   padding: '10px 15px',
// };

// const Yealink = () => {
//   const [data, setData] = useState(yealinkproductdata);
//   const [collection, setCollection] = useState([]);
//   const [selectedFilter, setSelectedFilter] = useState('All');
//   const [subFilters, setSubFilters] = useState([]); // to store sub filters
//   const [checkedSubFilters, setCheckedSubFilters] = useState([]); // track checked state of subfilters
//   const [flipped, setFlipped] = useState({}); // track flip state of each card

//   useEffect(() => {
//     setData(yealinkproductdata);
//     setCollection([...new Set(yealinkproductdata.map((item) => item.title))]);
//   }, []);

//   const gallery_filter = (itemData) => {
//     const filterData = yealinkproductdata.filter((item) => item.title === itemData);
//     setData(filterData);
//     setSelectedFilter(itemData);
//     setCheckedSubFilters([]); // Reset subfilter checkboxes
//     const allTypes = ['All', ...new Set(filterData.map((item) => item.subtitle))]; // Add 'Alltypes'
//     setSubFilters(allTypes);
//   };

//   const handleSubFilter = (subtitle) => {
//     let updatedCheckedSubFilters = [...checkedSubFilters];

//     // Toggle 'Alltypes' logic
//     if (subtitle === 'All') {
//       if (updatedCheckedSubFilters.includes('All')) {
//         // If 'Alltypes' is already checked, uncheck all
//         updatedCheckedSubFilters = [];
//       } else {
//         // If 'Alltypes' is not checked, check all subfilters
//         updatedCheckedSubFilters = [...subFilters];
//       }
//     } else {
//       // Individual subfilter logic
//       if (updatedCheckedSubFilters.includes(subtitle)) {
//         updatedCheckedSubFilters = updatedCheckedSubFilters.filter((item) => item !== subtitle);
//       } else {
//         updatedCheckedSubFilters.push(subtitle);
//       }

//       // If all individual subfilters are selected, check 'Alltypes' as well
//       if (updatedCheckedSubFilters.length === subFilters.length - 1) {
//         updatedCheckedSubFilters.push('All');
//       } else {
//         // If not all individual subfilters are selected, uncheck 'Alltypes'
//         updatedCheckedSubFilters = updatedCheckedSubFilters.filter((item) => item !== 'All');
//       }
//     }
//     setCheckedSubFilters(updatedCheckedSubFilters);

//     // Filtering logic based on checked subfilters
//     if (updatedCheckedSubFilters.length === 0 || updatedCheckedSubFilters.includes('All')) {
//       setData(yealinkproductdata.filter((item) => item.title === selectedFilter));
//     } else {
//       const filteredBySubfilters = yealinkproductdata.filter(
//         (item) => item.title === selectedFilter && updatedCheckedSubFilters.includes(item.subtitle)
//       );
//       setData(filteredBySubfilters);
//     }
//   };

//   const handleMouseEnter = (id) => {
//     setFlipped((prev) => ({ ...prev, [id]: true }));
//   };
//   // const handleMouseLeave = () => {
//   //   setFlipped(false);
//   // };
//   // const handleClick = (id) => {
//   //   setFlipped((prev) => ({ ...prev, [id]: false }));
//   // };
//   const clickedflipped =(id) =>{
//     setFlipped((prev)=> ({...prev,[id]:!prev[id]}));
//   }
 

//   return (
//     <div>
//       <div className="filterItem-yealink">
//         <ul>
//           <li>
//             <button
//               className={selectedFilter === 'All' ? 'active' : ''}
//               onClick={() => {
//                 setData(yealinkproductdata);
//                 setSelectedFilter('All');
//                 setSubFilters([]);
//                 setCheckedSubFilters([]);
//               }}
//             >
//               All
//             </button>
//           </li>
//           {collection.map((item) => (
//             <li key={item}>
//               <button
//                 className={selectedFilter === item ? 'active' : ''}
//                 onClick={() => {
//                   gallery_filter(item);
//                 }}
//               >
//                 {item}
//               </button>
//             </li>
//           ))}
//         </ul>
//       </div>
//       <Grid container spacing={1} paddingBottom={10}>
//         <Grid item xs={12} md={3}>
//           {subFilters.length > 0 && (
//             <div
//               className="subFilterItem"
//               style={{ textAlign: 'left', paddingLeft: '20px',paddingRight:'10px'  }}
//             >
//               <Box
//         sx={{
//           display: 'flex',
//           alignItems: 'center',
//           marginBottom: '10px', // Space between the logo/title and subfilters
//           paddingBottom:'10px',
//           borderBottom:'6px solid ',
//           width: '100%', // Ensure full width for better layout
//           padding: '0px 10px 5px 5px', // Adjust padding if needed
//           borderImage: 'linear-gradient(90deg, #0055D4 0%, #7FABFF 100%)',
//           borderImageSlice: 1,
//           marginLeft:'15px'
//         }}
//       >
//         <img
//           src={logo}
//           alt="Logo"
//           style={{
//             width: '40px', // Adjust size as needed
//             height: '40px', // Adjust size as needed
//             marginRight: '10px', // Space between logo and text
//           }}
//         />
//         <Typography
//           variant="h6"
//           sx={{
//             color: 'secondary.dark',
//             textAlign: 'center',
           


//           }}
//         >
//           {selectedFilter}
//         </Typography>
        
//       </Box>
//               <ul style={{ listStyleType: 'none', padding: 0 }}>
//                 {subFilters.map((subtitle) => (
//                   <li key={subtitle}>
//                     <FormControlLabel
//                     labelPlacement="start"
//                       control={
//                         <Checkbox
//                           checked={checkedSubFilters.includes(subtitle)}
//                           onChange={() => handleSubFilter(subtitle)}
//                            sx={{
//         color: 'green', // Unchecked color
//         '&.Mui-checked': {
//           color: 'green', // Checked color
//         },  }}
//                         />
//                       }
//                       label={subtitle}
//                       sx={{ display: 'flex', // Ensures the checkbox and label are aligned in a row
//     flexDirection: 'row-reverse', // Checkbox on the right, label on the left
//     justifyContent: 'space-between', // Spaces the label and checkbox evenly
//     alignItems: 'center', // Vertically center align
//     width: '100%', // Ensure full width for better layout
//     padding: '5px 10px 5px 2px', // Adjust padding if needed
//     color: 'secondary.dark',
//   borderBottom: '1px solid #000000' }}
//                     />
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           )}
//         </Grid>
        
//         {/* product display */}
//          {/* product display */}
//       <Grid item   xs={12} md={9} >
     
//        <Grid container columnSpacing={{xs:6,md:2}} rowSpacing={{xs:2,md:3}}sx={{ overflowX: 'hidden',paddingBottom:'5px',paddingRight:'20px',paddingLeft:'20px'}}>
//          {
//             data.map((item)=>      <Grid 
//             item 
//             key={item.id} 
//             xs={12}  sm={6} lg={4}
//             sx={{ display: 'flex', justifyContent: 'center' }}
//           > <Card  sx={{borderRadius: '25px',
//             textAlign:'center',
//             display:'flex',
//             flexDirection:'column',
//             width: '100%', 
//              maxWidth: '100%', 
//              perspective:'1000px',
//              height:'420px',
//             backgroundColor:'transparent',
//             cursor:'pointer',
//             border:'none',
//             boxShadow:'none'
//            }}
//            onMouseEnter={()=>handleMouseEnter(item.id)} onClick={()=>clickedflipped(item.id)}>
//         <Box sx={{position:'relative',width:'100%', height:'100%', transformStyle:'preserve-3d',transition:'transform 0.8s',transform:flipped[item.id] ? 'rotateY(180deg)': 'rotateY(0deg)'}}>
//         <Box sx={{ position:'absolute', width:'100%',display:'flex',
//             flexDirection:'column', backgroundColor:'secondary.main', textAlign:'center',backfaceVisibility:'hidden',height:'100%' }}>
//             <Box
//            height={'180px'}
//            sx={{  width:'100%',textAlign:'center',display:'flex', justifyContent: "center",
//             alignItems: "end",paddingBottom:'5px',backgroundColor:'#f7f7f7'}}
           

//          >
//              <img src={item.image} alt={item.title} style={{ width: '80%', height: '80%' }} />
             
//        </Box>
//               <CardContent sx={{height:{xs:'240px',md:'240px'},padding:'0px',}}>
//               <Typography variant="h6" sx={{textAlign:'left',marginLeft:'22px', background: item.colors,
//     WebkitBackgroundClip: 'text',
//     WebkitTextFillColor: 'transparent'
// ,fontSize:'35px',fontWeight:'bold'}}>
//                   {item.name}
//                 </Typography>
               
//               <Box sx={{height:'30px',marginLeft:'15px', width:'100%',display:'flex', flexDirection:'row', flexWrap:'wrap',justifyContent:'left',}}>
//                  {item.types.map((type,i) => (
//                  <Typography 
//                  key={i}
//                  variant='body1'
               
//                 sx={{ height:'20px',fontSize:{xs:'11px',sm:'10px',lg:'10px'}, textDecoration:'none', borderRadius:'20px',border:'1px solid rgba(237, 237, 237, 1)',margin:'5px 0px 5px 5px',color: 'secondary.contrastText',backgroundColor:'rgba(247, 247, 247, 1)',padding:'3px 15px',}} >
//                 {type}
//               </Typography>
             
//               ))}
//               </Box>
//               <Typography variant='body1' sx={{fontSize:'14px',padding:'0px 20px',marginTop:'20px',fontSize:'16px',color:'secondary.dark',textAlign:'center'}}>{item.details}</Typography>
//                  <Box
//                  height={'40px'} 
//                  sx={{textAlign:'center',display:'flex', justifyContent: "center",marginTop:'20px',
//                   alignItems: "end",paddingBottom:'2px',backgroundColor:'secondary.main'}}
                 
      
//                >
//                    <img src={logo} alt={item.title} style={{ width: '40px', height: '100%' }} />
                   
//              </Box>
             
//               </CardContent>
//               </Box>
//               {/* back side */}
//               <Box className="backflip" sx={{ transform:flipped[item.id] ? 'rotateY(0deg)': 'rotateY(-180deg)',position:'absolute', width:'100%',height:'100%', backfaceVisibility:'hidden',transform:'rotateY(180deg)',backgroundColor:'secondary.main',
//                 display:'flex',flexDirection:'column',  alignItems:'center' 
//               }}>
                
//               <Typography variant='h6' sx={{marginTop:'25px', marginBottom:'25px',fontSize:'25px', backgroundColor:'primary.light',color:'secondary.dark',fontWeight:'600',padding:'2px 10px',borderRadius:'10px'}}> {item.title} </Typography>
//               <Box component="ul" sx={{ textAlign:'left',listStyleType:'disc',marginLeft:'20px',height:'250px', width:'90%',display:'flex', flexDirection:'column', justifyContent:'left',}}>
//                  {item.backDetails.map((backdetail,i) => (
//                  <Typography component="li"
//                  key={i}
//                  variant='body1'
               
//                 sx={{ fontSize:'14px', textDecoration:'none',margin:'3px 5px',color: 'secondary.dark',padding:'10px 5px 8px 15px',}} >
//                 {backdetail}
//               </Typography>
             
//               ))}
             
//               </Box>
//                 <Stack   sx={{marginTop:'20px',alignItems:'center', justifyContent:'center', }}  >
//                 <Button variant="contained" endIcon={<ArrowOutward/>} component={Link} to='/servicespage' sx={exploresx}>Learn More</Button>
//                 </Stack>
//               </Box>
//               </Box>
//             </Card>
//             </Grid>
//             )
//           }
//           </Grid>
//         </Grid>
       
      
//         </Grid>
//     </div>
//   )
// }

// export default Yealink


// import React, { useEffect, useState } from 'react';
// import { Stack, Box, Grid, Typography, Button, Card, CardContent, Checkbox, FormControlLabel } from '@mui/material';
// import { Link } from 'react-router-dom';
// import { ArrowOutward } from '@mui/icons-material';
// import yealinkproductdata from '../components/Yealinkproductdata';
// import logo from '../images/products/endlogo.png';
// import '../css/yealinkproduct.css';

// const exploresx = {
//   color: 'secondary.main',
//   borderRadius: 10,
//   width:{ xs:'150px',sm:'200px',md:'150px',lg:'200px'},
//   borderColor: 'secondary.light',
//   backgroundColor: 'secondary.light',
//   padding: '10px 15px',
// };

// const Yealink = () => {
//   const [data, setData] = useState(yealinkproductdata);
//   const [collection, setCollection] = useState([]);
//   const [selectedFilter, setSelectedFilter] = useState('All');
//   const [subFilters, setSubFilters] = useState([]); // to store sub filters
//   const [checkedSubFilters, setCheckedSubFilters] = useState([]); // track checked state of subfilters
//   const [flipped, setFlipped] = useState({}); // track flip state of each card

//   useEffect(() => {
//     setData(yealinkproductdata);
//     setCollection([...new Set(yealinkproductdata.map((item) => item.title))]);
//   }, []);

//   const gallery_filter = (itemData) => {
//     const filterData = yealinkproductdata.filter((item) => item.title === itemData);
//     setData(filterData);
//     setSelectedFilter(itemData);
//     setCheckedSubFilters([]); // Reset subfilter checkboxes
//     const allTypes = ['All', ...new Set(filterData.map((item) => item.subtitle))]; // Add 'Alltypes'
//     setSubFilters(allTypes);
//   };

//   
//   //   let updatedCheckedSubFilters = [...checkedSubFilters];

//   //   // Toggle 'Alltypes' logic
//   //   if (subtitle === 'All') {
//   //     if (updatedCheckedSubFilters.includes('All')) {
//   //       // If 'Alltypes' is already checked, uncheck all
//   //       updatedCheckedSubFilters = [];
//   //     } else {
//   //       // If 'Alltypes' is not checked, check all subfilters
//   //       updatedCheckedSubFilters = [...subFilters];
//   //     }
//   //   } else {
//   //     // Individual subfilter logic
//   //     if (updatedCheckedSubFilters.includes(subtitle)) {
//   //       updatedCheckedSubFilters = updatedCheckedSubFilters.filter((item) => item !== subtitle);
//   //     } else {
//   //       updatedCheckedSubFilters.push(subtitle);
//   //     }

//   //     // If all individual subfilters are selected, check 'Alltypes' as well
//   //     if (updatedCheckedSubFilters.length === subFilters.length - 1) {
//   //       updatedCheckedSubFilters.push('All');
//   //     } else {
//   //       // If not all individual subfilters are selected, uncheck 'Alltypes'
//   //       updatedCheckedSubFilters = updatedCheckedSubFilters.filter((item) => item !== 'All');
//   //     }
//   //   }
//   //   setCheckedSubFilters(updatedCheckedSubFilters);

//   //   // Filtering logic based on checked subfilters
//   //   if (updatedCheckedSubFilters.length === 0 || updatedCheckedSubFilters.includes('All')) {
//   //     setData(yealinkproductdata.filter((item) => item.title === selectedFilter));
//   //   } else {
//   //     const filteredBySubfilters = yealinkproductdata.filter(
//   //       (item) => item.title === selectedFilter && updatedCheckedSubFilters.includes(item.subtitle)
//   //     );
//   //     setData(filteredBySubfilters);
//   //   }
//   // };

//   const handleSubFilter = (subtitle) => {
//     let updatedCheckedSubFilters = [...checkedSubFilters];
  
//     // When 'All' is clicked
//     if (subtitle === 'All') {
//       if (updatedCheckedSubFilters.includes('All')) {
//         // Uncheck all filters if 'All' is already checked
//         updatedCheckedSubFilters = [];
//       } else {
//         // Check all subfilters when 'All' is clicked
//         updatedCheckedSubFilters = [...subFilters];
//       }
//     } else {
//       // Individual subfilter logic
//       if (updatedCheckedSubFilters.includes(subtitle)) {
//         // Uncheck the clicked subtitle
//         updatedCheckedSubFilters = updatedCheckedSubFilters.filter((item) => item !== subtitle);
//       } else {
//         // Check the clicked subtitle
//         updatedCheckedSubFilters.push(subtitle);
//       }
  
//       // Check if all subfilters except 'All' are now selected
//       if (updatedCheckedSubFilters.length === subFilters.length - 1 && !updatedCheckedSubFilters.includes('All')) {
//         updatedCheckedSubFilters.push('All');
//       } else {
//         // Uncheck 'All' if not all individual subfilters are selected
//         updatedCheckedSubFilters = updatedCheckedSubFilters.filter((item) => item !== 'All');
//       }
//     }
  
//     setCheckedSubFilters(updatedCheckedSubFilters);
  
//     // Filtering logic based on checked subfilters
//     if (updatedCheckedSubFilters.length === 0 || updatedCheckedSubFilters.includes('All')) {
//       setData(yealinkproductdata.filter((item) => item.title === selectedFilter));
//     } else {
//       const filteredBySubfilters = yealinkproductdata.filter(
//         (item) => item.title === selectedFilter && updatedCheckedSubFilters.includes(item.subtitle)
//       );
//       setData(filteredBySubfilters);
//     }
//   };
  

//   const handleMouseEnter = (id) => {
//     setFlipped((prev) => ({ ...prev, [id]: true }));
//   };

//   const clickedflipped = (id) => {
//     setFlipped((prev) => ({ ...prev, [id]: !prev[id] }));
//   };

//   // Function to group data by subtitles
//   const groupBySubtitle = () => {
//     return data.reduce((acc, item) => {
//       if (!acc[item.subtitle]) {
//         acc[item.subtitle] = [];
//       }
//       acc[item.subtitle].push(item);
//       return acc;
//     }, {});
//   };

//   const groupedData = groupBySubtitle(); // Group data by subtitle

//   return (
//     <div>
//       <div className="filterItem-yealink">
//         <ul>
//           <li>
//             <button
//               className={selectedFilter === 'All' ? 'active' : ''}
//               onClick={() => {
//                 setData(yealinkproductdata);
//                 setSelectedFilter('All');
//                 setSubFilters([]);
//                 setCheckedSubFilters([]);
//               }}
//             >
//               All
//             </button>
//           </li>
//           {collection.map((item) => (
//             <li key={item}>
//               <button
//                 className={selectedFilter === item ? 'active' : ''}
//                 onClick={() => {
//                   gallery_filter(item);
//                 }}
//               >
//                 {item}
//               </button>
//             </li>
//           ))}
//         </ul>
//       </div>
//       <Grid container spacing={1} paddingBottom={10}>
//         <Grid item xs={12} md={3}>
//           {subFilters.length > 0 && (
//             <div
//               className="subFilterItem"
//               style={{ textAlign: 'left', paddingLeft: '20px', paddingRight: '10px' }}
//             >
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   marginBottom: '10px', // Space between the logo/title and subfilters
//                   paddingBottom: '10px',
//                   borderBottom: '6px solid ',
//                   width: '100%', // Ensure full width for better layout
//                   padding: '0px 10px 5px 5px', // Adjust padding if needed
//                   borderImage: 'linear-gradient(90deg, #0055D4 0%, #7FABFF 100%)',
//                   borderImageSlice: 1,
//                   marginLeft: '15px',
//                 }}
//               >
//                 <img
//                   src={logo}
//                   alt="Logo"
//                   style={{
//                     width: '40px', // Adjust size as needed
//                     height: '40px', // Adjust size as needed
//                     marginRight: '10px', // Space between logo and text
//                   }}
//                 />
//                 <Typography
//                   variant="h6"
//                   sx={{
//                     color: 'secondary.dark',
//                     textAlign: 'center',
//                   }}
//                 >
//                   {selectedFilter}
//                 </Typography>
//               </Box>
//               <ul style={{ listStyleType: 'none', padding: 0 }}>
//                 {subFilters.map((subtitle) => (
//                   <li key={subtitle}>
//                     <FormControlLabel
//                       labelPlacement="start"
//                       control={
//                         <Checkbox
//                           checked={checkedSubFilters.includes(subtitle)}
//                           onChange={() => handleSubFilter(subtitle)}
//                           sx={{
//                             color: 'green', // Unchecked color
//                             '&.Mui-checked': {
//                               color: 'green', // Checked color
//                             },
//                           }}
//                         />
//                       }
//                       label={subtitle}
//                       sx={{
//                         display: 'flex', // Ensures the checkbox and label are aligned in a row
//                         flexDirection: 'row-reverse', // Checkbox on the right, label on the left
//                         justifyContent: 'space-between', // Spaces the label and checkbox evenly
//                         alignItems: 'center', // Vertically center align
//                         width: '100%', // Ensure full width for better layout
//                         padding: '5px 10px 5px 2px', // Adjust padding if needed
//                         color: 'secondary.dark',
//                         borderBottom: '1px solid #000000',
//                       }}
//                     />
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           )}
//         </Grid>

import React, { useEffect, useState } from 'react';
import {useMediaQuery, Stack, Box, Grid, Typography, Button, Card, CardContent, Checkbox, FormControlLabel } from '@mui/material';
import { Link } from 'react-router-dom';
import { ArrowOutward } from '@mui/icons-material';
import yeastarproduct from '../components/yeastarproduct';
import logo from '../images/products/endlogo.png';
import shop_bag from '../images/products/shopping_bag.png';
import '../css/yeastarproduct.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

const exploresx = {
  color: 'secondary.main',
  borderRadius: 10,
  width:'170px',
  borderColor: 'secondary.light',
  backgroundColor: 'secondary.light',
  padding: '8px 10px',
};

const Yeastar = () => {
  const [data, setData] = useState(yeastarproduct);
  const [collection, setCollection] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [subFilters, setSubFilters] = useState([]); // to store sub filters
  const [checkedSubFilters, setCheckedSubFilters] = useState([]); // track checked state of subfilters
  const [flipped, setFlipped] = useState({}); // track flip state of each card

  
  useEffect(() => {
    setData(yeastarproduct);
    const initialCollection = [...new Set(yeastarproduct.map((item) => item.title))];
    setCollection(initialCollection);
    // If "All" is selected by default, check all subfilters
    if (selectedFilter === 'All') {
      const allSubFilters = ['All', ...new Set(yeastarproduct.map((item) => item.subtitle))];
      setCheckedSubFilters(allSubFilters);
      setSubFilters(allSubFilters);
    }
  }, []);

  const gallery_filter = (itemData) => {
    const filterData = yeastarproduct.filter((item) => item.title === itemData);
    setData(filterData);
    setSelectedFilter(itemData);

    // Check all subfilters for the selected main filter
    const allSubFilters = ['All', ...new Set(filterData.map((item) => item.subtitle))];
    setCheckedSubFilters(allSubFilters);
    setSubFilters(allSubFilters);
  };
  

 
 
  const handleSubFilter = (subtitle) => {
    let updatedCheckedSubFilters = [...checkedSubFilters];
  
    // When 'All' is clicked
    if (subtitle === 'All') {
      if (updatedCheckedSubFilters.includes('All')) {
        // Uncheck all filters if 'All' is already checked
        updatedCheckedSubFilters = [];
      } else {
        // Check all subfilters when 'All' is clicked
        updatedCheckedSubFilters = [...subFilters];
      }
    } else {
      // Individual subfilter logic
      if (updatedCheckedSubFilters.includes(subtitle)) {
        // Uncheck the clicked subtitle
        updatedCheckedSubFilters = updatedCheckedSubFilters.filter((item) => item !== subtitle);
      } else {
        // Check the clicked subtitle
        updatedCheckedSubFilters.push(subtitle);
      }
  
      // Check if all subfilters except 'All' are now selected
      if (updatedCheckedSubFilters.length === subFilters.length - 1 && !updatedCheckedSubFilters.includes('All')) {
        updatedCheckedSubFilters.push('All');
      } else {
        // Uncheck 'All' if not all individual subfilters are selected
        updatedCheckedSubFilters = updatedCheckedSubFilters.filter((item) => item !== 'All');
      }
    }
  
    setCheckedSubFilters(updatedCheckedSubFilters);
  
    // Filtering logic
    if (updatedCheckedSubFilters.length === 0) {
      // If no subfilters are checked, set data to empty array
      setData([]);
    } else if (selectedFilter === 'All') {
      // Filtering for the 'All' main filter
      const filteredBySubfilters = yeastarproduct.filter(
        (item) => updatedCheckedSubFilters.includes(item.subtitle)
      );
      setData(filteredBySubfilters);
    } else {
      // Filtering for other main filters
      const filteredBySubfilters = yeastarproduct.filter(
        (item) => item.title === selectedFilter && updatedCheckedSubFilters.includes(item.subtitle)
      );
      setData(filteredBySubfilters);
    }
  };
  
  
  
  
 
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  
  const handleMouseEnter = (id) => {
    setFlipped((prev) => ({ ...prev, [id]: true }));
  };

  const clickedflipped = (id) => {
    setFlipped((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const groupBySubtitle = () => {
    return data.reduce((acc, item) => {
      if (!acc[item.subtitle]) {
        acc[item.subtitle] = [];
      }
      acc[item.subtitle].push(item);
      return acc;
    }, {});
  };

  const groupedData = groupBySubtitle();

  return (
    <div>
      <div className="filterItem-yeastar">
        <ul>
          <li>
            <button
              className={selectedFilter === 'All' ? 'active' : ''}
              onClick={() => {
                setData(yeastarproduct);
                setSelectedFilter('All');
                setSubFilters([...new Set(yeastarproduct.map((item) => item.subtitle))]); // Show all subtitles
                setCheckedSubFilters([]);
              }}
            >
              All
            </button>
          </li>
          {collection.map((item) => (
            <li key={item}>
              <button
                className={selectedFilter === item ? 'active' : ''}
                onClick={() => {
                  gallery_filter(item);
                }}
              >
                {item}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <Grid container spacing={1} paddingBottom={10} >
      
 
 <Grid item xs={12} md={4} lg={3} sx={{
          position: 'relative',
        }}
    >
      {subFilters.length > 0 && (
        <div
          className="subFilterItem"
          style={{ textAlign: 'left', paddingLeft: '20px', paddingRight: '10px' ,
            position: 'sticky',
            top: '230px', //  top offset
           }}
        >
          {/* Use Accordion only for small screens */}
          {isSmallScreen ? (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    padding: '0px 10px 1px 5px',
                    borderBottom: '6px solid ',
                    borderImage: 'linear-gradient(90deg, #0055D4 0%, #7FABFF 100%)',
                    borderImageSlice: 1,
                  }}
                >
                  <img
                    src={logo}
                    alt="Logo"
                    style={{
                      width: '40px',
                      height: '40px',
                      marginRight: '10px',
                    }}
                  />
                  <Typography variant="h6" sx={{ color: 'secondary.dark', textAlign: 'left', width: '90%' }}>
                    {selectedFilter}
                  </Typography>
                  <img
                    src={shop_bag}
                    alt="shop_bag"
                    style={{
                      width: '20px',
                      height: '20px',
                      marginLeft: '10px',
                      textAlign: 'right',
                    }}
                  />
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                  {subFilters.map((subtitle) => (
                    <li key={subtitle}>
                      <FormControlLabel
                        labelPlacement="start"
                        control={
                          <Checkbox
                            checked={checkedSubFilters.includes(subtitle)}
                            onChange={() => handleSubFilter(subtitle)}
                            sx={{
                              color: 'green',
                              '&.Mui-checked': {
                                color: 'green',
                              },
                            }}
                          />
                        }
                        label={subtitle}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                          padding: '3px 10px 3px 2px',
                          color: 'secondary.dark',
                          borderBottom: '1px solid #000000',
                        }}
                      />
                    </li>
                  ))}
                </ul>
              </AccordionDetails>
            </Accordion>
          ) : (
            // Regular layout for medium and large screens
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
                paddingBottom: '10px',
                borderBottom: '6px solid ',
                width: '100%',
                padding: '0px 10px 5px 5px',
                borderImage: 'linear-gradient(90deg, #0055D4 0%, #7FABFF 100%)',
                borderImageSlice: 1,
                marginLeft: { sx: '15px', md: '5px', lg: '15px' },
              }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{
                  width: '40px',
                  height: '40px',
                  marginRight: '10px',
                }}
              />
              <Typography variant="h6" sx={{ color: 'secondary.dark', textAlign: 'left', width: '90%' }}>
                {selectedFilter}
              </Typography>
              <img
                src={shop_bag}
                alt="shop_bag"
                style={{
                  width: '20px',
                  height: '20px',
                  marginLeft: '10px',
                  textAlign: 'right',
                }}
              />
            </Box>
          )}

          {/* Shared subFilter section for both small and large screens */}
          {!isSmallScreen && (
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              {subFilters.map((subtitle) => (
                <li key={subtitle}>
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Checkbox
                        checked={checkedSubFilters.includes(subtitle)}
                        onChange={() => handleSubFilter(subtitle)}
                        sx={{
                          color: 'green',
                          '&.Mui-checked': {
                            color: 'green',
                          },
                        }}
                      />
                    }
                    label={subtitle}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      padding: '2px 10px 2px 2px',
                      color: 'secondary.dark',
                      borderBottom: '1px solid #000000',
                    }}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </Grid>

        {/* product display */}
        <Grid item xs={12} md={8} lg={9} >
          <Grid
            container
            columnSpacing={{ xs: 6, md: 2 }}
            rowSpacing={{ xs: 2, md: 3 }}
            sx={{ overflowX: 'hidden', paddingBottom: '5px', paddingRight: '20px', paddingLeft: '20px' }}
          >
            {/* Loop through groupedData to display categorized products */}
            {Object.keys(groupedData).map((subtitle) => (
              <Grid item xs={12} key={subtitle}>
                <Typography
                  variant="h6"
                  sx={{
                    padding: '0px 10px 0px 5px', 
                    textAlign: 'left',
                    marginBottom: '10px',
                   
                    background: 'linear-gradient(90deg, #0055D4 0%, #7FABFF 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                   
                    borderBottom: '2px solid rgba(129, 129, 129, 1)',
                    
fontSize: '30px',
fontWeight: 400,



                   
                  }}
                >
                  {subtitle}
                </Typography>
                <Grid container spacing={2}>
                  {groupedData[subtitle].map((item) => (
                    <Grid
                      item
                      key={item.id}
                      xs={12}
                      sm={6}
                      lg={4}
                    
                      sx={{ display: 'flex', justifyContent: 'space-around' }}
                     
                    >
                     
                
                     <Card  sx={{borderRadius: '25px',
            textAlign:'center',
            display:'flex',
            flexDirection:'column',
            // marginLeft:{xs:'10%',sm:'5%', lg:'9%'},
            // marginRight:{xs:'10%',sm:'5%',lg:'9%'},
            width: '230px', 
            minWidth:'230px',
             maxWidth: '230px', 
             perspective:'1000px',
             height:'330px',
            backgroundColor:'transparent',
            cursor:'pointer',
            border:'none',
            boxShadow:'none'
           }}
           onMouseEnter={()=>handleMouseEnter(item.id)} onClick={()=>clickedflipped(item.id)}>
        <Box sx={{position:'relative',width:'100%', height:'100%', transformStyle:'preserve-3d',transition:'transform 0.8s',transform:flipped[item.id] ? 'rotateY(180deg)': 'rotateY(0deg)'}}>
        <Box sx={{ position:'absolute', width:'100%',display:'flex',
            flexDirection:'column', backgroundColor:'secondary.main', textAlign:'center',backfaceVisibility:'hidden',height:'100%' }}>
            <Box
           height={'130px'}
           sx={{  width:'100%',textAlign:'center',display:'flex', justifyContent: "center",
            alignItems: "end",paddingBottom:item.bsize,backgroundColor:'#f7f7f7'}}
           

         >
             <img src={item.image} alt={item.title} style={{ width: item.isize, height: item.isize }} />
             
       </Box>
              <CardContent sx={{height:{xs:'190px',md:'190px'},padding:'0px',display:'flex',flexDirection:'column'}}>
              <Typography variant="h6" sx={{textAlign:'left',marginLeft:'20px', background: item.colors,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
,fontSize:{xs:'20px',sm:'30px',md:'20px',lg:'30px'},fontWeight:'bold',marginTop:'10px'}}>
                  {item.name}
                </Typography>
               
              
              <Typography variant='body1' sx={{fontSize:{xs:'12px',sm:'12px',md:'12px',lg:'12px'},padding:'0px 20px',marginTop:'5px',color:'secondary.contrastText',textAlign:'justify'}}>{item.details}</Typography>
                
             
              </CardContent>
              </Box>
              {/* back side */}
              <Box className="backflip" sx={{ transform:flipped[item.id] ? 'rotateY(0deg)': 'rotateY(-180deg)',position:'absolute', width:'100%',height:'100%', backfaceVisibility:'hidden',transform:'rotateY(180deg)',backgroundColor:'secondary.main',
                display:'flex',flexDirection:'column',  alignItems:'center' 
              }}>
                
              <Typography variant='h6' sx={{marginTop:'20px', marginBottom:'20px',fontSize:'20px', backgroundColor:'primary.light',color:'secondary.dark',fontWeight:'600',padding:'2px 10px ',borderRadius:'10px'}}> {item.name} </Typography>
              <Box component="ul" sx={{ textAlign:'left',listStyleType:'disc',marginLeft:'20px',height:'200px', width:'90%',display:'flex', flexDirection:'column', justifyContent:'left',}}>
                 {item.backDetails.map((backdetail,i) => (
                 <Typography component="li"
                 key={i}
                 variant='body1'
               
                sx={{ fontSize:item.fsize, textDecoration:'none',margin:'0px 5px 0px 5px',color: 'secondary.dark',padding:'5px 5px 5px 15px',}} >
                {backdetail}
              </Typography>
             
              ))}
             
              </Box>
                <Stack   sx={{marginTop:'5px',alignItems:'center', justifyContent:'center', }}  >
                <Button variant="contained" endIcon={<ArrowOutward/>} component={Link} to={item.weblink}  target='_blank'  sx={exploresx}>Learn More</Button>
                </Stack>
              </Box>
              </Box>
            </Card>
               
               
             
             
             
                            
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Yeastar;

