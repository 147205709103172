import c1 from '../images/customer/Customer1.png';
import c2 from '../images/customer/Customer2.jpeg';
import c3 from '../images/customer/Customer3.jpeg';
import c4 from '../images/customer/Customer4.png';
import c5 from '../images/customer/Customer5.png';
import c6 from '../images/customer/Customer6.jpg';
import c7 from '../images/customer/Customer7.jpg';



const dataclient = [
  {
    name: `Airblue`,
    img: c1,
   type: `Lorem ipsum dolor `,
    plink:`iiii`
  },
  {
    name: `Bankislami`,
    img: c2,
     type: `Lorem ipsum dolor `,
    plink:`iiii`
    
  },
  {
    name: `CancerCare`,
    img: c3,
     type: `Lorem ipsum dolor `,
    plink:`iiii`
  },
  {
    name: `DHA`,
    img: c4,
   type: `Lorem ipsum dolor `,
    plink:`iiii`
  },
  {
    name: `Mari`,
    img: c5,
    type: `Lorem ipsum dolor `,
    plink:`iiii`
  },
  {
    name: `Mari`,
    img: c6,
    type: `Lorem ipsum dolor `,
    plink:`iiii`
  },
  {
    name: `Mari`,
    img: c7,
    type: `Lorem ipsum dolor `,
    plink:`iiii`
  },
  
  
  
];

export default dataclient;