



import React from "react";
import Slider from "react-slick";
import { Box, Typography } from "@mui/material";
import data from "../components/projectdata";
import "../css/projectslideshow.css";
const AutoScrollingCarousel = () => {
  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 5000,
  //   slidesToShow: 1, // Initially show 1 slide, adjusted in CSS
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 0,
  //   cssEase: "linear",
  //   centerMode: true,
  //   arrows:false,
  //   variableWidth: true, // This enables consistent slide width
  // };

  // const slideStyle = {
  //   padding: 2,
  //   backgroundColor: "transparent",
  //   color: "black",
  //   borderRadius:5,
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   height: "150px", 
  //   textAlign: "center",
  //   width: "300px",
  //   margin: "5px 10px", 
  // };

  // return (
  //   <Box sx={{ width: "100%", overflow: "hidden", padding: "0 10px" }}>
  //     <Slider {...settings}>
  //     {data.map((item) => (
  //         <Box key={item.name} sx={slideStyle}>
  //            <Box
           
  //          sx={{ height: 140 ,width:240,backgroundColor:'secondary.main',textAlign:'center', display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",borderRadius:5}}
           

  //        >
  //            <img src={item.img} alt={item.name} style={{ width: '50%', height: '50%' }} />
             
  //      </Box>
  //         </Box>
  //       ))}
  //     </Slider>
  //   </Box>
  // );

return(
  <div>
  

  <div class="logos">
      <div class="logos-slide">
      {data.map((item) => (
          
              <img src={item.img} alt={item.name} />
          

        ))}
      </div>

      <div class="logos-slide">
      {data.map((item) => (
            
            
            <img src={item.img} alt={item.name} />
        
           

        ))}
      </div>
    </div>
  </div>
)
};

export default AutoScrollingCarousel;