import React from 'react';
import { BrowserRouter as Router,Route,Switch } from 'react-router-dom';
import {createTheme ,ThemeProvider} from '@mui/material';
import './App.css';
import Landingpage from './pages/landingpage';
import Contactpage from './pages/contactpage';
import ScrollToHash from './components/scrollhash';
function App() {

  return (
    
   <div>
     <ScrollToHash />
    <Landingpage />
    </div>
   
   

        
     
       
        

    
  );
}

export default App;
