import p1 from '../images/products/P-Series.png';
import p2 from  '../images/products/s_series.png';
import p3 from  '../images/products/gateway.png';
import p4 from  '../images/products/ipphone.png';
import p5 from  '../images/products/headset.png';
import p6 from  '../images/products/meetingboard.jpg';
import p7 from  '../images/products/videoC.png';

const dataproduct = [
  {
    name: `Yeastar P-Series VoIP PBX`,
    img: p1,
    colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
    fxs:'20px',
    flg:'25px',
   types:[ `Yeastar `,`VoIP PBX `],
   pbottom:'end',
    plink:`https://www.yeastar.com/p-series-pbx-system/`
  },
  {
    name: `Yeastar S-Series VoIP PBX`,
    subname: `VoIP PBX`,
    fxs:'20px',
    flg:'25px',
    img: p2,
    fsize:'40px',
    pbottom:'end',
    colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
    types:[ `Yeastar  `,`VoIP PBX `],
    plink:`https://www.yeastar.com/s-series-voip-pbx/`
    
  },
  {
    name: ` Yeastar VoIP Gateway`,
    subname: `VoIP PBX`,
    img: p3,
    fxs:'20px',
    flg:'25px',
    pbottom:'end',
    colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
    types:[ `Yeastar  `,`FXS-Gateways`,`FXO-Gateways`,`E1/T1/PRI-Gateways`],
    plink:`https://www.yeastar.com/voip-gateways/`
  },
  {
    name: `IP-Phones`,
    subname: `VoIP PBX`,
    img: p4,
    
    fxs:'20px',
    flg:'25px',
    pbottom:'end',
    colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
    types:[ `Yealink `,`Ip-phones `],
    plink:`https://www.yealink.com/en/product-list/ip-phone`
  },
  {
    name: `Headsets`,
    subname: `VoIP PBX`,
    img: p5,
    fxs:'20px',
    flg:'28px',
    pbottom:'start',
    colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
    types:[ `Yealink `,`Headsets`],
    plink:`https://www.yealink.com/en/product-list/headset-webcam`
  },
  {
    name: `Meeting Board`,
    img: p6,
    subname: `VoIP PBX`,
    fxs:'20px',
    flg:'28px',
    pbottom:'center',
    colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
    types:[ `Yealink `,`Video Conferencing`],
    plink:`https://www.yealink.com/en/product-detail/microsoft-teams-rooms-meetingboard65`
  },
  {
    name: `Video Conferencing`,
    img: p7,
    subname: `VoIP PBX`,
    fxs:'20px',
    flg:'25px',
    pbottom:'end',
    colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
    types:[ `Yealink `,`Video Conferencing`],
    plink:`https://www.yealink.com/en/onepage/the-best-video-conference-systems`
  },
 
  
];

export default dataproduct;