
 import p1 from "../images/yeastar/p520.png";
 import p2 from "../images/yeastar/p550.png";
 import p3 from "../images/yeastar/p560.png";
 import p4 from "../images/yeastar/p570(1).png";
 import s1 from "../images/yeastar/s20.png";
 import s2 from "../images/yeastar/s50.png";
 import s3 from "../images/yeastar/s412.png";
 import s4 from "../images/yeastar/s100.png";
 import s5 from "../images/yeastar/s300.png";
 import t1 from "../images/yeastar/t100.png";
 import t2 from "../images/yeastar/t200.png";
 import te1 from "../images/yeastar/te100.png";
 import te2 from "../images/yeastar/te200.png";
 import t3 from "../images/yeastar/t400.png";
 import t4 from "../images/yeastar/t410.png";
 import t5 from "../images/yeastar/t800.png";
 import t6 from "../images/yeastar/t810.png";
 import t7 from "../images/yeastar/t1600.png";
 import t8 from "../images/yeastar/t1610.png";
 import t9 from "../images/yeastar/t2400.png";
 import t10 from "../images/yeastar/t3200.png";
 const yeastarproduct = [
    {
        id:1,
        title:"P-Series",
        image:p1,
        name:"P520",
        subtitle:'P-Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar P Series VoIP Call Center IP PBX P520 | For 20 Users / Agents | 10 Concurrent Calls',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Users 20`,
            `Concurrent Calls 10`,
            `Max FXO / FXS Ports 4`,
            `Omni Channel Integration`,
            `Storage: Micro SD Card`   
            
            
            ],
            fsize:'12px',
            isize:'90%',
            bsize:'0px',
            weblink:`https://www.yeastar.com/ip-pbx/`,
    },
    {
        id:2,
        title:"P-Series",
        image:p2,
        name:"P550",
        subtitle:'P-Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar P Series VoIP Call Center IP PBX P550 | For 50 Users / Agents | 25 Concurrent Calls',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Users 50`,
            `Concurrent Calls 25`, 
            `Max FXO / FXS Ports 8`,
            `API / CTI / AMI / Omni Channel`,
            `Storage: USB` 
            
            ],
            fsize:'12px',
            isize:'90%',
            bsize:'0px',
            weblink:`https://www.yeastar.com/ip-pbx/`,
    },
    {
        id:3,
        title:"P-Series",
        image:p3,
        name:"P560",
        subtitle:'P-Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar P Series VoIP Call Center IP PBX P560 | For Up To 200 Users / Agents | Up To 60 Concurrent Calls',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[`Users 100 by default Max. 200`,
            `Concurrent Calls 30 by default Max. 60`,
            `Call Center Agents 100 Max.200*`,
            `Max FXO/FXS Ports 8`,
            `Max E1/T1/J1 Port 1`,
            `Expandable D30 1`,
            `API / CTI / AMI / Omni Channel`,
            `Storage: Hard Disk / USB / SD Card`
            
            ],
            fsize:'9px',
            isize:'90%',
            bsize:'0px',
            weblink:`https://www.yeastar.com/ip-pbx/`,
    },
    {
        id:4,
        title:"P-Series",
        image:p4,
        name:"P570",
        subtitle:'P-Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar P Series VoIP Call Center IP PBX P570 | For Up To 500 Users / Agents | Up To 120 Concurrent Calls',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Users 300 by default Max. 500`,
`Concurrent Calls 60 by default
Max. 120`,
`Call Center Agents 300 Max. 500*`,
            
            `Max FXO / FXS Ports 16`,
            `Max E1/T1/J1 Ports 2`,
            `Expandable D30 2`,
            `API / CTI / AMI / Omni Channel`,
            `Storage: Hard Disk / USB / SD Card`,
            
            
            ],
        fsize:'9px',
        isize:'100%',
        bsize:'0px',
        weblink:`https://www.yeastar.com/ip-pbx/`,
    },
    {
        id:5,
        title:"S-Series",
        image:s1,
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar S Series VoIP IP PBX S20 for 20 Users 10 Concurrent Calls',
        types:[`Lorem `,`Lorem `,`Lorem `],
        name:"S20",
        subtitle:'S-Series',  
        backDetails:[`Users 20 `,                                 
           `Concurrent call: 10`,                          
            `Max FXS / FXO ports 4`,
            `Storage: TF/Micro SD Card`
            
            ],
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yeastar.com/s-series-voip-pbx/`,
    },
    {
        id:6,
        title:"S-Series",
        image:s2,
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar S Series VoIP IP PBX S50 for 50 Users 25 Concurrent Call',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        name:"S50",
        subtitle:'S-Series', 
        backDetails:[ `Users 50 `,                                 
            `Concurrent call: 25`,                         
            `Max FXS/FXO ports 8`,  
            `Storage: SD Card / USB`     
            ],
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yeastar.com/s-series-voip-pbx/`,
    },
    {
        id:7,
        title:"S-Series",
        image:s4,
        name:"S100",
        subtitle:'S-Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar S Series VoIP IP PBX S100 Support up to 200 users & up to 60 Concurrent Calls',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[`Users 100 by default Max. 200`,                             
            `Concurrent call: 30 by default Max. 60`,   
            `Expand Cards: max. 2 EX08/ EX30 & 1 D30`,                  
            `Max FXS/FXO Ports 16`,
            `Storage: SD Card / USB`
            ],
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yeastar.com/s-series-voip-pbx/`,
    },
    {
        id:8,
        title:"S-Series",
        image:s5,
        name:"S300",
        subtitle:'S-Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar S Series VoIP IP PBX S300 Support up to 500 users & up to 120 Concurrent Call',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Users 300 by default Max. 500` ,                            
            `Concurrent call: 60 by default Max. 120` ,
           `Expand Cards: max. 3 EX08/ EX30 & 2 D30` ,                  
            `Max FXS/FXO Ports 24`,
            `Storage: SD Card / USB / Hard Disk` ,
            ],
            fsize:'11px',
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yeastar.com/s-series-voip-pbx/`,
    },
    {
        id:9,
        title:"S-Series",
        image:s3,
        name:"S412",
        subtitle:'S-Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar S Series VoIP IP PBX S412 | 20 Users 8 Built-in Analog Users 8 SIP Users 8 Concurrent Calls',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Up to 12 Analog Extensions`,
            `Up to 4 FXO lines`,
            `8 VoIP Users`,
            `8 Concurrent Calls`,
            `Storage: TF/Micro SD Card`
            ],
            fsize:'12px',
            isize:'90%',
            bsize:'0px',
            weblink:`https://www.yeastar.com/s-series-voip-pbx/`,
    },
    {
        id:10,
        title:"FXS Gateway",
        image:t1,
        name:"TA100",
        subtitle:'FXS Gateway', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar TA100 | Single Port FXS VoIP Gateway',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Analog ports：1 FXS port`,
`Protocol: SIP and IAX2`,
`Fax over IP: T.38`,
`Codec: G.711 G.722 G.726`,
`G.729A, GSM, ADPCM, Speex`
            ],
            fsize:'14px',  //font size details
            isize:'80%',   // image size
            bsize:'0px',   // paddingbottom of image
            weblink:`https://www.yeastar.com/fxs-voip-gateways/`,
    },
    {
        id:11,
        title:"FXS Gateway",
        image:t2,
        name:"TA200",
        subtitle:'FXS Gateway', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar TA200 | 2 Port FXS VoIP Gateway',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Analog ports：2 FXS ports`,
`Protocol: SIP and IAX2`,
`Fax over IP: T.38`,
`Codec: G.711 G.722 G.726`,
`G.729A, GSM, ADPCM, Speex`
            ],
            fsize:'14px',  //font size details
            isize:'80%',   // image size
            bsize:'0px',   // paddingbottom of image
            weblink:`https://www.yeastar.com/fxs-voip-gateways/`,
    },
    {
        id:12,
        title:"FXS Gateway",
        image:t3,
        name:"TA400",
        subtitle:'FXS Gateway', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:' Yeastar TA400 | 4 Port FXS VoIP Gateway',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Analog ports：4 FXS ports`,
`Protocol: SIP and IAX2`,
`Fax over IP: T.38`,
`Codec: G.711 G.722 G.726`,
`G.729A, GSM, ADPCM, Speex`
            ],
            fsize:'14px',  //font size details
            isize:'100%',   // image size
            bsize:'0px',   // paddingbottom of image
            weblink:`https://www.yeastar.com/fxs-voip-gateways/`,
    },
     {
        id:13,
        title:"FXO Gateway",
        image:t4,
        name:"TA410 ",
        subtitle:'FXO Gateway', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:' Yeastar TA410 | 4 Port FXO VoIP Gateway',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Analog ports：4 FXO ports`,
`Protocol: SIP and IAX2`,
`Fax over IP: T.38`,
`Codec: G.711 G.722 G.726`,
`G.729A, GSM, ADPCM, Speex`
            ],
            fsize:'14px',  //font size details
            isize:'100%',   // image size
            bsize:'0px',   // paddingbottom of image
            weblink:`https://www.yeastar.com/fxo-voip-gateways/`,
    },
    {
        id:14,
        title:"FXS Gateway",
        image:t5,
        name:"TA800 ",
        subtitle:'FXS Gateway', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar TA800 | 8 Port FXS VoIP Gateway',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Analog ports：8 FXS ports`,
`Protocol: SIP and IAX2`,
`Fax over IP: T.38`,
`Codec: G.711 G.722 G.726`,
`G.729A, GSM, ADPCM, Speex`
            ],
            fsize:'14px',  //font size details
            isize:'100%',   // image size
            bsize:'0px',   // paddingbottom of image
            weblink:`https://www.yeastar.com/fxs-voip-gateways/`,
    },
    {
        id:15,
        title:"FXO Gateway",
        image:t6,
        name:"TA810",
        subtitle:'FXO Gateway', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar TA810 | 8 Port FXO VoIP Gateway',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Analog ports：8 FXO ports`,
                    `Protocol: SIP and IAX2`,
                    `Fax over IP: T.38`,
                    `Codec: G.711 G.722 G.726`,
                    `G.729A, GSM, ADPCM, Speex`
            ],
            fsize:'14px',  //font size details
            isize:'100%',   // image size
            bsize:'0px',   // paddingbottom of image
            weblink:`https://www.yeastar.com/fxo-voip-gateways/`,
    },
    {
        id:16,
        title:"FXS Gateway",
        image:t7,
        name:"TA1600",
        subtitle:'FXS Gateway', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar TA1600 | 16 Port FXS VoIP Gateway',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ ` Analog ports：16 FXS ports`,
`Protocol: SIP and IAX2`,
`Fax over IP: T.38`,
`Codec: G.711 G.722 G.726`,
`G.729A, GSM, ADPCM, Speex`
            ],
            fsize:'14px',  //font size details
            isize:'100%',   // image size
            bsize:'0px',   // paddingbottom of image
            weblink:`https://www.yeastar.com/fxs-voip-gateways/`,
    },
    {
        id:17,
        title:"FXO Gateway",
        image:t8,
        name:"TA1610",
        subtitle:'FXO Gateway', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar TA1610 | 16 Port FXO VoIP Gateway',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Analog ports：16 FXO ports`,
                    `Protocol: SIP and IAX2`,
                    `Fax over IP: T.38`,
                    `Codec: G.711 G.722 G.726`,
                    `G.729A, GSM, ADPCM, Speex`
            ],
            fsize:'14px',  //font size details
            isize:'100%',   // image size
            bsize:'0px',   // paddingbottom of image
            weblink:`https://www.yeastar.com/fxo-voip-gateways/`,
    },
    {
        id:18,
        title:"FXS Gateway",
        image:t9,
        name:"TA2400",
        subtitle:'FXS Gateway', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar TA2400 | 24 Port FXS VoIP Gateway',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ ` Analog ports：24 FXS ports`,
`Protocol: SIP and IAX2`,
`Fax over IP: T.38`,
`Codec: G.711 G.722 G.726`,
`G.729A, GSM, ADPCM, Speex`
            ],
            fsize:'14px',  //font size details
            isize:'100%',   // image size
            bsize:'0px',   // paddingbottom of image
            weblink:`https://www.yeastar.com/fxs-voip-gateways/`,
    },
    {
        id:19,
        title:"FXS Gateway",
        image:t10,
        name:"TA3200",
        subtitle:'FXS Gateway', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar TA3200 | 32 Port FXS VoIP Gateway',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ ` Analog ports：32 FXS ports`,
`Protocol: SIP and IAX2`,
`Fax over IP: T.38`,
`Codec: G.711 G.722 G.726`,
`G.729A, GSM, ADPCM, Speex`
            ],
            fsize:'14px',  //font size details
            isize:'100%',   // image size
            bsize:'0px',   // paddingbottom of image
            weblink:`https://www.yeastar.com/fxs-voip-gateways/`,
    },
    {
        id:20,
        title:"E1/PRI Gateway",
        image:te1,
        name:"TE100",
        subtitle:'E1/PRI Gateway', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar TE100 | Single Port PRI / E1 Gateway',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ ` E1/T1/J1 port：1`,
`Protocol: SIP and IAX2`,
`Codec: G.711 G.722 G.726`,
`G.729A, GSM, ADPCM, Speex`
            ],
            fsize:'14px',  //font size details
            isize:'100%',   // image size
            bsize:'0px',   // paddingbottom of image
            weblink:`https://www.yeastar.com/pri-voip-gateway/`,
    },
    {
        id:21,
        title:"E1/PRI Gateway",
        image:te2,
        name:"TE200",
        subtitle:'E1/PRI Gateway', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Yeastar TE200 | 2 Port PRI / E1 Gateway',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ ` E1/T1/J1 ports：2`,
`Protocol: SIP and IAX2`,
`Codec: G.711 G.722 G.726`,
`G.729A, GSM, ADPCM, Speex`
            ],
            fsize:'14px',  //font size details
            isize:'90%',   // image size
            bsize:'0px',   // paddingbottom of image
            weblink:`https://www.yeastar.com/pri-voip-gateway/`,
    },

   
   
];
export default yeastarproduct;