
import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography} from '@mui/material';
import { MailOutline, PhoneInTalkOutlined ,LocationOnOutlined} from '@mui/icons-material';
const iconsx ={
    padding:1,
    borderRadius:'50%',
    color:'secondary.light',
    backgroundColor:'#0044EB1A',
    fontSize: 50,
    textAlign:'center',
};
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'secondary.main',
    height:'270px',
   
    textAlign: 'center',
    color: 'secondary.light',
    // display:'flex',
    // flexDirection:'column',
    // alignItems:'center',
    // alignContent:'center',
    // justifyContent:'center',
    // justifyItems:'center',
    padding:'50px 15px 15px 5px',
    borderRadius:'20px',

  }));
const Contactboxes = () => {
  return (
    <div>
       <Box sx={{ width: '100%', backgroundColor:'primary.light' , padding:'70px 15px 15px 15px'}}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Item>
          <LocationOnOutlined sx={iconsx}/>
                        <Typography variant='h6' sx={{fontSize:'18px',fontWeight:'bold',color:'secondary.dark'}} >Our Office</Typography>
                        <Typography variant='body1'sx={{fontSize:'16px',color:'secondary.contrastText'}}>Office #53, P (Paradise) Floor I.T Tower 73-E1 Hali Rd
                        Gulberg III Lahore, Pakistan</Typography>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Item>
          < PhoneInTalkOutlined sx={iconsx}/>
                <Typography variant='h6' sx={{fontSize:'18px',fontWeight:'bold',color:'secondary.dark'}} >Contact</Typography>
                <Typography variant='body1'sx={{fontSize:'16px',color:'secondary.contrastText'}}>+92 300 5710318 <br></br>+92 42 35710318</Typography>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Item>
          <MailOutline sx={iconsx}/>
                    <Typography variant='h6' sx={{fontSize:'18px',fontWeight:'bold',color:'secondary.dark'}} >Email</Typography>
                    <Typography variant='body1'sx={{fontSize:'16px',color:'secondary.contrastText'}}>info@ar.com.pk</Typography>
                   
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Item>
          <LocationOnOutlined sx={iconsx}/>
                        <Typography variant='h6' sx={{fontSize:'18px',fontWeight:'bold',color:'secondary.dark'}} >Visit between</Typography>
                        <Typography variant='body1'sx={{fontSize:'16px',color:'secondary.contrastText'}}>Monday-Saturday 10am-7pm<br></br>Sunday closed
                       </Typography>
          </Item>
        </Grid>
      </Grid>
    </Box>
    </div>
  )
}

export default Contactboxes
