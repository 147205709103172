import React from 'react';
import Footer from '../components/footer';
import Pageheader from '../components/pageheader';
import bgimg from '../images/products/bgimg.png';
import {Navbar} from '../components/navbar';
import Yealink from '../components/Yealink';

import Stack from '@mui/material/Stack';
const Yealinkpage = () => {
  return (
    <div>
       <Navbar variant='absolute'/> 
       <Pageheader  heading="Yealink" bgimg={bgimg} color="rgba(51, 222, 191, 1)"/>
       <Stack sx={{backgroundColor:'primary.light'}}>
      <Yealink/>
      </Stack>
       <Footer /> 
    </div>
  )
}

export default Yealinkpage