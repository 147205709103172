import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import pic from'../images/footer/bgimg.svg';
import icon1 from '../images/statistics/img1.svg';
import icon2 from '../images/statistics/img3.svg';
import icon3 from '../images/statistics/img2.svg';
import icon4 from '../images/statistics/img4.svg';
import Box from '@mui/material/Box';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import AutoScrollingCarousel from '../components/projectslideshow';
import {  Typography } from '@mui/material';
import '../css/stats.css';
import dataclient from "../components/clientimages";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    height:300,
    padding: theme.spacing(1),
    display:'flex',
    textAlign: {xs:'center',md:'left'},
    justifyContent:{xs:'center',md:'left'},
    justifyItems:{xs:'center',md:'left'},
    alignContent:{xs:'center',md:'left'},
    flexDirection:'column',
    color: theme.palette.secondary.contrastText,
    borderRadius: 25,

  }));
  const Item2 = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    height:600,
    textAlign:'center',
     padding: theme.spacing(1),
    color: theme.palette.secondary.contrastText,
    borderRadius: 25,
  }));
  const Imagebox = styled(Paper)(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, 0)',
   
   textAlign:'center',

    boxShadow:'none',
    marginLeft:0,
    marginTop:20,
    marginBottom:15,
    [theme.breakpoints.up('sm')]: {
      marginLeft:40,
      textAlign:'left',
    }
   

  }));
  const numsx ={
    color:'secondary.dark',
   
    textAlign:{xs:'center',sm:'left'},
    marginLeft: {xs:0,sm:5},
    fontSize:{xs:'36px',sm:'45px'},
    marginTop:{xs:2,sm:5},
    
   
    fontWeight:'bold',
   
   

  }
  const textsx ={
    color:'secondary.contrastText',
    
    marginTop:1,
    fontSize:'16px',
    textAlign:{xs:'center',sm:'left'},
    marginLeft: {xs:0,sm:5},

  }
  const headingbg ={
    color: 'secondary.main',
    backgroundColor: 'primary.main',
    textAlign:'center',
    width:190,
    marginTop:1,
    borderRadius:1,
    fontSize:'16px',
  }
  const headingsx ={
    color: 'secondary.main',
    marginLeft:2,
    fontSize:'16px',
    
    marginTop:1,
  }
  
export default function DirectionStack() {
  const [countup,setCounterup]=useState(false);
  const settings = {
    dots: false,
    arrows: false,
   
    lazyLoad: 'ondemand',
    pauseOnHover:true,
    autoplay:true,
    autoplaySpeed:5000,
    swipe: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 1 }
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1 }
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1 }
      }
    ]
  };

  return (
   
    <div>
      
    <Stack   sx={{ width: '100%',marginTop:'20px',backgroundColor:'#020842' }}  p={2}>
    <div className='app-containerstats'>
    <Stack  direction="row" sx={{textAlign:'center', justifyContent:'center',paddingTop:'50px' }}>
      <Typography variant='h6'sx={headingbg}>Some of Our Valued</Typography>
      <Typography variant='h6'sx={headingsx}>Clients</Typography>
      </Stack>
      <AutoScrollingCarousel  />
      <ScrollTrigger onEnter={()=>setCounterup(true)} onExit={()=>setCounterup(false)}>
      <Stack direction="row" pt={10} >
     
        <Grid container spacing={2} >
      
            <Grid item xs={12} md={8}>
            <Box sx={{ width: '100%' }}>
      <Grid container columnSpacing={1} rowSpacing={2}>
        <Grid item xs={12} sm={6} >
          <Item>
            <Imagebox><img src={`${icon4}`} width={'80px'} height={'80px'} alt="My Image"  /></Imagebox>
            <Typography variant='h3' sx={numsx}><span> {countup && <CountUp start={0} end={15} decimalPlaces={1} duration={5} delay={0}></CountUp>}+</span></Typography>
            <Typography variant='h6'sx={textsx}>Years of experience</Typography>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6}>

          <Item>
          <Imagebox><img src={`${icon3}`} alt="My Image" width={'80px'} height={'80px'} marginLeft={{xs:0,sm:5}}/></Imagebox>
            <Typography variant='h3' sx={numsx}><span> {countup && <CountUp start={200} end={250} decimalPlaces={1} duration={5} delay={0}></CountUp>}+</span></Typography>
            <Typography variant='h6'sx={textsx}>Success Stories</Typography>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Item>
          <Imagebox><img src={`${icon1}`} width={'80px'} height={'80px'} alt="My Image" marginLeft={{xs:0,sm:5}} /></Imagebox>
            <Typography variant='h3' sx={numsx}><span> {countup && <CountUp start={0} end={100} decimalPlaces={1} duration={5} delay={0}></CountUp>}%</span></Typography>
            <Typography variant='h6'sx={textsx}>Guranteed</Typography>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Item>
          <Imagebox><img src={`${icon2}`}width={'80px'} height={'80px'} alt="My Image" marginLeft={{xs:0,sm:5}}/></Imagebox>
            <Typography variant='h3' sx={numsx}><span> {countup && <CountUp start={0} end={500} decimalPlaces={1} duration={5} delay={0}></CountUp>}+</span></Typography>
            <Typography variant='h6'sx={textsx}>Companies Trust Us</Typography>
          </Item>
        </Grid>
      </Grid>
    </Box>
            </Grid>
  
  
            <Grid item xs={12} md={4}>
            <Grid item xs={12}>
          <Box height={600} sx={{backgroundColor:'secondary.main', borderRadius:10 , img:{borderRadius:10}}} >
          <Slider {...settings}>
        {dataclient.map((item) => (
          
            <img
            height={600}
            width={'90%'}
              src={item.img}
              alt={item.name}
              borderRadius={15}
            />
        
        ))}
      </Slider>
          </Box>
        </Grid>
            </Grid>
        </Grid>
       
        </Stack>
       
        </ScrollTrigger>
        </div>
    </Stack>
    </div>
  );
}
