import y1 from "../images/yealink/CP925.png";
import y2 from "../images/yealink/CP935W.png";
import y3 from "../images/yealink/CP965.png";
import y4 from "../images/yealink/t30.png";
import y5 from "../images/yealink/t31.png";
import y6 from "../images/yealink/t31w.png";
import y8 from "../images/yealink/t34w.png";
import y9 from "../images/yealink/t42u.png";
import y10 from "../images/yealink/t43u.png";
import y11 from "../images/yealink/t44u.png";
import y12 from "../images/yealink/t44w.png";
import y13 from "../images/yealink/t46u.png";
import y14 from "../images/yealink/t48u.png";
import y15 from "../images/yealink/t53.png";
import y16 from "../images/yealink/t53w.png";
import y17 from "../images/yealink/t54w.png";
import y18 from "../images/yealink/t57w.png";
import y19 from "../images/yealink/t58w.png";
import y20 from "../images/yealink/t58wp.png";
import y21 from "../images/yealink/t30p.png";
import y22 from "../images/yealink/t31g.png";
import y23 from "../images/yealink/t31w.png";
import y24 from "../images/yealink/t33p.png";
import y25 from "../images/yealink/t33g.png";
import y26 from "../images/yealink/vp59.png";
import y28 from "../images/yealink/cam50.png";
import y27 from "../images/yealink/btn.png";
import y29 from "../images/yealink/cpn10.png";
import y30 from "../images/yealink/ax83h.png";
import y31 from "../images/yealink/exp50.png";
import y32 from "../images/yealink/exp43.png";
import y33 from "../images/yealink/mba40.webp";

import y34 from "../images/yealink/android.webp";
import y35 from "../images/yealink/mvcs40.webp";
import y36 from "../images/yealink/mvcs60.webp";
import y37 from "../images/yealink/mvcs90.webp";
import y38 from "../images/yealink/mvc940.webp";
import y39 from "../images/yealink/mvc860.webp";
import y40 from "../images/yealink/mvc640.webp";
import y41 from "../images/yealink/mvc340.webp";
import y42 from "../images/yealink/mba20.webp";
import y43 from "../images/yealink/mba30.webp";
import y44 from "../images/yealink/mba10.webp";
import y45 from "../images/yealink/dva24.webp"; 
import y46 from "../images/yealink/mba40r.webp";
import y47 from "../images/yealink/zvc860.webp";
import y48 from "../images/yealink/zvcs90.webp";
import y49 from "../images/yealink/mbz.webp";
import y50 from "../images/yealink/c5zr.webp";
import y51 from "../images/yealink/me500.webp";
import y52 from "../images/yealink/dva24z.webp";
import y53 from "../images/yealink/mba10z.webp";
import y54 from "../images/yealink/mba20z.webp";
import y55 from "../images/yealink/mba30z.webp";
import y56 from "../images/yealink/rcz.webp";
import y57 from "../images/yealink/zvc860b.webp";
import y58 from "../images/yealink/uvc85.webp";
import y59 from "../images/yealink/uvc40.webp";
import y60 from "../images/yealink/sv40.webp";
import y61 from "../images/yealink/sv60.webp";
import y62 from "../images/yealink/uvc84.webp";
import y63 from "../images/yealink/uvc86.webp";
import y64 from "../images/yealink/uvc34.webp";
import y65 from "../images/yealink/uvc30r.webp";
import y66 from "../images/yealink/uvc84by.webp";
import y67 from "../images/yealink/uvc30cp.webp";
import y68 from "../images/yealink/roompanelzoom.webp";
import y69 from "../images/yealink/roompanelmicro.webp";
import y70 from "../images/yealink/roomsensorpro.webp";
import y71 from "../images/yealink/roompanelplus.webp";
import y72 from "../images/yealink/roomsensor.webp";
import y73 from "../images/yealink/roompanel.webp";
import y74 from "../images/yealink/rcast.webp";
import y75 from "../images/yealink/cm50.webp";
import y76 from "../images/yealink/ap08.webp";
import y77 from "../images/yealink/cm20.webp";
import y78 from "../images/yealink/cs10.webp";
import y79 from "../images/yealink/cmkit121.webp";
import y80 from "../images/yealink/cp50.webp";
import y81 from "../images/yealink/cpe40.webp";
import y82 from "../images/yealink/vcm36.webp";
import y83 from "../images/yealink/vcm35.webp";
import y84 from "../images/yealink/vcm34.webp";
const yealinkproductdata = [
        {
                id:26,
                title:"IP Phones",
                image:y26,
                name:"VP59",
                subtitle:'T5 Series', 
                colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
                details:'Flagship smart video phone for C-level Executives and workgroups with video collaboration demands',
                types:[ `Lorem `,`Lorem `,`Lorem `],
                backDetails:[ `8 inch (1280 x 800) capacitive
adjustable touch screen`,
`1080p@30 full HD video`,
`Runs Android 7.1`,
`Built-in dual band 2.4G/5G Wi-Fi
(802.11a/b/g/n)`,
`Standard HDMI output for
monitor connection`,
`Up to 16 VoIP accounts`,
`Up to 3-party video confe rencing`,
`Up to 10-party video/audio mixed
conferencing (up to 2 video calls)`,
                    
                    
                    ],
                    fsize:'10px',
                    isize:'100%',
                    bsize:'0px',
                    namesizef:'30px',
                    namesizeb:'20px',
                    weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t5`,
            }, {
                id:20,
                title:"IP Phones",
                image:y20,
                name:"T58W Pro with Camera",
                subtitle:'T5 Series', 
                colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
                details:'Elite desktop phone for executives and professionals',
                types:[ `Lorem `,`Lorem `,`Lorem `],
                backDetails:[ `Runs Android 9.0`,
        `7-inch (1024 x 600) capacitive
        adjustable touch screen`,
        `720p30 HD video`,
        `Supports Yealink Bluetooth
        Handset BTH58`,
        `DoorPhone Features`,
        `Built-in Bluetooth 4.2 and Dual
        Band 2.4G/5G Wi-Fi`,
        `Up to 10-party video/audio mixed
        conferencing`   
                    
                    
                    ],
                    fsize:'11px',
                    isize:'100%',
                    bsize:'0px',
                    namesizef:'18px',
                    namesizeb:'15px',
                    weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t5`,
            },
 
    {
        id:19,
        title:"IP Phones",
        image:y19,
        name:"T58W Pro",
        subtitle:'T5 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Elite desktop voip phone for executives and professionals',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Runs Android 9.0`,
`7-inch (1024 x 600) capacitive
adjustable touch screen`,
`Supports Yealink Bluetooth
Handset BTH58`,
`Optimal HD audio, Yealink Noise
Proof Technology`,
`DoorPhone Features`,
`Built-in Dual
Band 2.4G/5G WiFi`,
`Up to 10-party audio
conferencing`   
            
            
            ],
            fsize:'11px',
            isize:'100%',
            bsize:'0px',
            namesizef:'30px',
            namesizeb:'20px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t5`,
    },
     {
        id:18,
        title:"IP Phones",
        image:y18,
        name:"T57W",
        subtitle:'T5 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Prime business Large Color Screen Wi Fi voip phones',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `7” 800 x 480 capacitive
adjustable touch screen`,
`Built-in dual band 2.4G/5G Wi-Fi (802.11a/b/g/n)`,
`Up to 16 VoIP accounts`,
`Hearing Aid Compatible Handset`,
`Paperless label design`   
            
            
            ],
            fsize:'12px',
            namesizef:'30px',
            namesizeb:'20px',
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t5`,
    }, {
        id:17,
        title:"IP Phones",
        image:y17,
        name:"T54W",
        subtitle:'T5 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Prime business Color Screen Wi Fi voip phones',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `4.3" 480 x 272-pixel color display with backlight`,
`Adjustable LCD screen`,
`Dual firmware images`,
`Built-in dual band 2.4G/5G Wi-Fi (802.11a/b/g/n)`,
`PoE support`,
`HAC Handset`,
`Paperless label design`   
            
            
            ],
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            namesizef:'30px',
            namesizeb:'20px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t5`,
    },
    {
        id:16,
        title:"IP Phones",
        image:y16,
        name:"T53W",
        subtitle:'T5 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Prime business Wi Fi voip phones',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `3.7" 360x160-pixel graphical
LCD with backlight`,
`Built-in Bluetooth 4.2`,
`Built-in dual band 2.4G/5G Wi-Fi
(802.11a/b/g/n)`,
`Up to 12 VoIP accounts`,
`Hearing Aid Compatible Handset`,
`Paperless label design`
            
            
            ],
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            namesizef:'30px',
            namesizeb:'20px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t5`,
    },
    {
        id:15,
        title:"IP Phones",
        image:y15,
        name:"T53",
        subtitle:'T5 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Prime business voip phones',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ 
`3.7" 360x160-pixel graphical
LCD with backlight`,
`Bluetooth via BT40`,
`Wi-Fi via WF40/WF50`,
`Up to 12 VoIP accounts`,
`PoE support`,
`HAC Handset`,
`Paperless label design`   
            
            
            ],
            fsize:'12px',
            namesizef:'30px',
            namesizeb:'20px',
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t5`,
    },
    {
        id:12,
        title:"IP Phones",
        image:y12,
        name:"SIP-T44W",
        subtitle:'T4 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Feature-rich Color Screen Wi-Fi SIP Phone',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `2.8” 320x240-pixel color LCD
with backlight`,
`Built-in Bluetooth 4.2`,
`Built-in dual band 2.4G/5G Wi-Fi
(802.11a/b/g/ n/ac)`,
`Up to 12 SIP accounts`,
 
`Supports color-screen expansion
modules`   
            
            
            ],
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            namesizef:'30px',
            namesizeb:'20px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t4`,
    },
    {
        id:11,
        title:"IP Phones",
        image:y11,
        name:"SIP-T44U",
        subtitle:'T4 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Well-rounded Color Screen SIP office phone',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `2.8” 320x240-pixel color LCD
with backlight`,
`Up to 12 SIP accounts`,
`Wi-Fi via WF40/WF50`,
`Bluetooth via BT40/BT41/BT52`, 
`Supports color-screen expansion
modules`     
            
            
            ],
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            namesizef:'30px',
            namesizeb:'20px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t4`,
    },
    {
        id:14,
        title:"IP Phones",
        image:y14,
        name:"SIP-T48U",
        subtitle:'T4 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Advanced Color Screen SIP Office Phone',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `7" 800 x 480-pixel color touch
screen with backlight`,
`Paperless label design`,
            `Up to 16 SIP accounts`,
`Wi-Fi via WF40/WF50`,

`USB recording`,  
`Supports color-screen expansion
modules` 
            
            
            ],
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            namesizef:'30px',
            namesizeb:'20px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t4`,
    },
    {
        id:13,
        title:"IP Phones",
        image:y13,
        name:"SIP-T46U",
        subtitle:'T4 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Productivity-enhancing Color Screen SIP Office / Operator phone',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ ` 4.3" 480 x 272-pixel color display
with backlight`,
`Up to 16 SIP accounts`,
`Wi-Fi via WF40/WF50`,
`Bluetooth via BT40/BT41/BT52`,
`USB recording`,  
`Supports color-screen expansion
modules` 
            
            
            ],
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            namesizef:'30px',
            namesizeb:'20px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t4`,
    },
    {
        id:10,
        title:"IP Phones",
        image:y10,
        name:"SIP-T43U",
        subtitle:'T4 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Feature-rich SIP Office / Operator Phone',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `3.7" 360x160-pixel graphical LCD
with backlight`,
`Up to 12 SIP accounts`,
`USB headset and EHS support`,
`Wi-Fi via WF40/WF50`,
`Bluetooth via BT40/BT41/BT52`,  
`Supports color-screen expansion
modules`
            
            
            ],
            namesizef:'30px',
            namesizeb:'20px',
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t4`,
    },
    {
        id:9,
        title:"IP Phones",
        image:y9,
        name:"SIP-T42U",
        subtitle:'T4 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Well-rounded SIP desk phones ',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `2.7" 192x64-pixel graphical LCD
with backlight`,
`Up to 12 SIP accounts`,
`USB headset and EHS support`,
`Wi-Fi via WF40/WF50`,
`Bluetooth via BT40/BT41/BT52`,  
            
            
            ],
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            namesizef:'30px',
            namesizeb:'20px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t3`,
    },
    {
        id:8,
        title:"IP Phones",
        image:y8,
        name:"SIP-T34W",
        subtitle:'T3 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Cost-effective Wi Fi IP phone ',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Yealink HD Voice`,
`2.4” 320 x 240-pixel color
display with backlight`,`Built-in dual band 2.4G/5G Wi-Fi
(802.11a/b/ g/n/ac)`,

`Up to 4 SIP accounts`,
`PoE support`,
`Local 5-way conferencing`,   
            
            
            ],
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            namesizef:'30px',
            namesizeb:'20px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t3`,
    },
    {
        id:25,
        title:"IP Phones",
        image:y25,
        name:"T33G",
        subtitle:'T3 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Cost-effective color screen Gigabit IP phone',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Yealink HD Voice`,
`2.4” 320 x 240-pixel color display
with backlight`,
`Up to 4 SIP accounts`,
`PoE support`,
`Local 5-way conferencing`,   
            
            
            ],
            namesizef:'30px',
            namesizeb:'20px',
            fsize:'12px',
            
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t3`,
    },
    {
        id:24,
        title:"IP Phones",
        image:y24,
        name:"T33P",
        subtitle:'T3 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Cost-effective color screen IP phone',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Yealink HD Voice`,
`2.4” 320 x 240-pixel color display
with backlight`,
`Up to 4 SIP accounts`,
`PoE support`,
`Local 5-way conferencing`,   
            
            
            ],
            fsize:'12px',
            isize:'100%',
            namesizef:'30px',
            namesizeb:'20px',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t3`,
    },
    {
        id:6,
        title:"IP Phones",
        image:y6,
        name:"SIP-T31W",
        subtitle:'T3 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Entry level Wi Fi IP phone ',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Yealink HD Voice`,
`2.3” 132x64-pixel graphical LCD
with backlight`,
`Built-in dual band 2.4G/5G Wi-Fi
(802.11a/ b/g/n/ac)`,

`PoE support`,
`Up to 2 SIP accounts`,
`Local 5-way conferencing`,   
            
            
            ],
            namesizef:'30px',
            namesizeb:'20px',
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t3`,
    },
    {
        id:23,
        title:"IP Phones",
        image:y23,
        name:"SIP-T31P",
        subtitle:'T3 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Dual-line entry level IP phone ',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Yealink HD Voice`,
`2.3” 132x64-pixel graphical LCD
with backlight`,
`Up to 2 SIP accounts`,
`PoE support`,
`Local 5-way conferencing`  
            
            
            ],
            namesizef:'30px',
            namesizeb:'20px',
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t3`,
    },
    {
        id:22,
        title:"IP Phones",
        image:y22,
        name:"SIP-T31G",
        subtitle:'T3 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Dual-line entry level Gigabit IP phone ',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Yealink HD Voice`,
`2.3” 132x64-pixel graphical LCD
with backlight`,
`Up to 2 SIP accounts`,
`PoE support`,
`Local 5-way conferencing`  
            
            
            ],
            fsize:'12px',
            namesizef:'30px',
            namesizeb:'20px',
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t3`,
    },
    {
        id:5,
        title:"IP Phones",
        image:y5,
        name:"SIP-T31",
        subtitle:'T3 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Dual-line entry level IP phone ',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Yealink HD Voice`,
`2.3” 132x64-pixel graphical LCD
with backlight`,
`Up to 2 SIP accounts`,
`PoE support`,
`Local 5-way conferencing`  
            
            
            ],
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t3`,
    },
    {
        id:21,
        title:"IP Phones",
        image:y21,
        name:"SIP-T30P",
        subtitle:'T3 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Single line entry level IP phone ',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Yealink HD Voice`,
`2.3” 132x64-pixel graphical LCD`,
`Two-port 10/100M Ethernet
Switch`,

`PoE support`,
`One SIP account`,
`Local 5-way conferencing`,
 
            
            
            ],
            namesizef:'30px',
            namesizeb:'20px',
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t3`,
    },
    {
        id:4,
        title:"IP Phones",
        image:y4,
        name:"SIP-T30",
        subtitle:'T3 Series', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Single line entry level IP phone ',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Yealink HD Voice`,
`2.3” 132x64-pixel graphical LCD`,
`One SIP account`,
`PoE support`,
`Local 5-way conferencing`,
 
            
            
            ],
            namesizef:'30px',
            namesizeb:'20px',
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=t3`,
    },
    {
        id:3,
        title:"IP Phones",
        image:y3,
        name:"CP965",
        subtitle:'Conference Phone', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Flagship conference phone for medium to large rooms and for best-in-class audio conferencing.',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `Android 9.0`,
`5-inch multi-touch screen with
720x1280 resolution`,
`20-foot and 360-degree voice
pickup`,
`Built-in 12-microphone array for
voice pickup`,
`Built-in 1-microphone array for
noise cancellation`,
`10-way conference call`,
`Hybrid UC meeting`,
`Built-in Bluetooth and Wi-Fi`,  
            
            
            ],
            namesizef:'30px',
            namesizeb:'20px',
            fsize:'10px',
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=conference-phone`,
    },
  
  
    {
        id:2,
        title:"IP Phones",
        image:y2,
        name:"CP935W",
        subtitle:'Conference Phone', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Wireless Wi-Fi Touch-Sensitive Conference Phone',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `4-inch multi-touch screen with
480x800 resolution`,
`Built-in rechargeable lithium
battery`,
`Up to 15 hours talk time`,
`Up to 75 hours standby time in
Wi-Fi mode`,
`20-foot and 360° voice pickup`,
`Built-in 6-microphone array for
voice pickup`,
`5-way conference call`,
`Built-in Bluetooth and Wi-Fi`,   
            
            
            ],
            namesizef:'30px',
            namesizeb:'20px',
            fsize:'10px',
            isize:'90%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=conference-phone`,
    },
    {
        id:1,
        title:"IP Phones",
        image:y1,
        name:"CP925",
        subtitle:'Conference Phone', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'CP925 HD IP conference phone for small to medium conference rooms',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ `4-inch multi-touch screen with
480x800 resolution`,
`20-foot and 360-degree voice
pickup`,
`Built-in 6-microphone array for
voice pickup`,
`Built-in 1-microphone array for
noise cancellation`,
`5-way conference call`,
`Built-in Wi-Fi and Bluetooth`,
`Local 3-way PSTN conference
via Yealink CPN10`,  
            
            
            ],
            namesizef:'30px',
            namesizeb:'20px',
            fsize:'10px',
            isize:'90%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-list/ip-phone?filter=conference-phone`,
    },
   
    {
        id:27,
        title:"IP Phones",
        image:y27,
        name:"BT41",
        subtitle:'Phone Accessories', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Bluetooth USB dongle',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[`Bluetooth specification: V4.1`,
                `Support 8K NBS (apply to SIP-
                T27G/T29G/T46G/T48G/T41S/T42S/
                T46S/T48S)`,
                `Support 16K WBS (apply to
                T42U/T43U/T44U/T46U/T48U/T53)`,
                `Operating distance: 10 meters`,
                `Answer incoming calls through
                Bluetooth headset`,
                `Adjusts the volume of Bluetooth
                headset through Bluetooth headset
                or your phone`  
            
            
            ],
            namesizef:'30px',
            namesizeb:'20px',
            fsize:'10px',
            isize:'90%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-detail/accessories-bt41`,
    },


    {
        id:28,
        title:"IP Phones",
        image:y28,
        name:"CAM50 ",
        subtitle:'Phone Accessories', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'HD camera for SIP-T58W ',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[`Compatible with Yealink SIP-
T58W/SIP-T58A IP Phones`,
`5 mega-pixel HD Camera`,
`720p@30fps`,
`Ease-of-use`,
`Plug and Play`,
`Adjustable with privacy shutter` 

            
            ],
            namesizef:'30px',
            namesizeb:'20px',
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-detail/accessories-cam50`,
    },
    {
        id:29,
        title:"IP Phones",
        image:y29,
        name:"CPN10",
        subtitle:'Phone Accessories', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'PSTN box',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ 
`Support Yealink IP Conference
Phone CP860/CP920/CP925/CP965/ T67LTE/ VP59/ T58A/ T58W/ T57W/ T54W/ T53W/ T53`,
`Plug and play`,
`USB call recording`,
`Support up to 2 cascaded
CPN10 to setup PSTN
conference`

            
            ],
            namesizef:'30px',
            namesizeb:'20px',
            fsize:'12px',
            isize:'90%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-detail/accessories-cpn10`,
    },
    {
        id:31,
        title:"IP Phones",
        image:y31,
        name:"EXP50",
        subtitle:'Phone Accessories', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Color-screen expansion module',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ 
                `4.3" 272 x 480-pixel color screen`,
                `Color icons for rich visual
                experience`,
                `20 physical keys on each page
                with dual-color LEDs`,
                `Three independent control keys
                with illuminated LED for fast
                switching pages`,
                `Supports up to 3 modules daisy-
                chain`,
                `Stand with 2 adjustable angles`,

            
            ],
            namesizef:'30px',
            namesizeb:'20px',
            fsize:'10px',
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-detail/accessories-exp50`,
    },
    {
        id:32,
        title:"IP Phones",
        image:y32,
        name:"EXP43",
        subtitle:'Phone Accessories', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Color Expansion Module ',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ 
                `User-friendly Structure Design`,
                `Intuitive Visualization`,
                `High Expansibility`,

            
            ],
            namesizef:'30px',
            namesizeb:'20px',
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-detail/accessories-exp43`,
    },
   

    {
        id:30,
        title:"IP Phones",
        image:y30,
        name:"AX83H",
        subtitle:'Wi-Fi Handset', 
        colors:'linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)',
        details:'Business Wi-Fi Cordless IP Phone',
        types:[ `Lorem `,`Lorem `,`Lorem `],
        backDetails:[ 
`Dual-Band Wi-Fi 6 with seamless
802.11k/v/r roaming`,
`HD Voice and Smart Noise
Filtering`,
`Wireless headsets connection via
Bluetooth 5.0`,
`2.4-inch 240 x 320 TFT color
screen`,
`4 SIP accounts,5-way
conference`

            
            ],
            namesizef:'30px',
            namesizeb:'20px',
            fsize:'12px',
            isize:'100%',
            bsize:'0px',
            weblink:`https://www.yealink.com/en/product-detail/yealink-ax83h-business-wi-fi-handset`,
    },
    
        {
          id: 33,
          title: "Microsoft & Zoom Devices",
          subtitle: "Team Room System",
          name: "Meeting Bar A40 Microsoft Teams Rooms on Android",
          details: "All-in-one video meeting bar for premium medium rooms",
          image: y33,
          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
          backDetails: [
            "Ultra HD picture quality*",
            "High-quality audio pickup experience*",
            "Compatible with Microsoft's MDEP",
            "Multi-Platform Flexibility",
            "Simple deployment",
            "Remote device management"
          ],
          namesizef: "17px",
          namesizeb: "11px",
          fsize: "11px",
          isize: "80%",
          bsize: "10px",
          weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
        },
        {
          id: 34,
          title: "Microsoft & Zoom Devices",
          subtitle: "Team Room System",
          name: "Meeting Board Microsoft Teams Rooms on Android",
          details: "All-In-One Video Conferencing Device with Interactive Whiteboard",
          image: y34,
          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
          backDetails: [
            "ALL-IN-ONE design",
            "Android 10",
            "4K built-in camera",
            "Detachable optional PTZ camera for larger space",
            "Dual-screen supported",
            "WIFI6 wireless presentation",
            "Windows OPS Module",
            "Microsoft Teams & Zoom certified"
          ],
          namesizef: "17px",
          namesizeb: "11px",
          fsize: "11px",
          isize: "100%",
          bsize: "0px",
          weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
        },
        {
          id: 35,
          title: "Microsoft & Zoom Devices",
          subtitle: "Team Room System",
          name: "MVC S40 Microsoft Teams Rooms on Windows",
          details: "Video Conferencing System for Medium Rooms",
          image: y35,
          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
          backDetails: [
            "Smart Vision 40, All-in-One video bar",
            "M Core Pro Mini PC",
            "MTouch E2 Touch Panel",
            "2x 48 MP Camera",
            "Support Intelli Focus",
            "Support Multi-Stream Intelli Frame (Microsoft Teams)",
            "Support Video Fence",
            "Support 2x expansion microphone"
          ],
          namesizef: "17px",
          namesizeb: "11px",
          fsize: "11px",
          isize: "100%",
          bsize: "0px",
          weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
        },
        
                {
                  id: 36,
                  title: "Microsoft & Zoom Devices",
                  subtitle: "Team Room System",
                  name: "MVC S60 Microsoft Teams Rooms on Windows",
                  details: "Video Conferencing System with 360-degree all-in-one Camera for Medium Rooms",
                  image: y36,
                  colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                  backDetails: [
                    "HFOV: 360° Camera",
                    "M Core Pro, Gen-3 Mini-PC",
                    "MTouch Plus, Gen-3 touch panel",
                    "Smart Vision 60, All-in-One video bar",
                    "11.6-inch narrow bezel IPS display",
                    "1920 x 1080 video resolution",
                    "2 x Microphone Mute/Unmute button",
                    "Memory: 8 GB (2 x 4GB)"
                  ],
                  namesizef: "17px",
                  namesizeb: "11px",
                  fsize: "11px",
                  isize: "100%",
                  bsize: "0px",
                  weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                },
                {
                  id: 37,
                  title: "Microsoft & Zoom Devices",
                  subtitle: "Team Room System",
                  name: "MVC S90 Microsoft Teams Rooms on Windows",
                  details: "Video Conferencing System for Pro-AV Rooms",
                  image: y37,
                  colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                  backDetails: [
                    "2 x UVC86 USB PTZ camera",
                    "M Core Pro, Gen-3 Mini-PC",
                    "MTouch Plus, Gen-3 touch panel",
                    "AV Hub, audio and video processor",
                    "Room Sensor, meeting room sensor",
                    "Memory: 8 GB (2 x 4GB)",
                    "11.6-inch narrow bezel IPS display",
                    "20.4 × HD zoom (12x optical, 1.7x digital)"
                  ],
                  namesizef: "17px",
                  namesizeb: "11px",
                  fsize: "11px",
                  isize: "100%",
                  bsize: "0px",
                  weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                },
                {
                  id: 38,
                  title: "Microsoft & Zoom Devices",
                  subtitle: "Team Room System",
                  name: "MVC940 Microsoft Teams Rooms on Windows",
                  details: "Video Conferencing System for Extra-Large Rooms",
                  image: y38,
                  colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                  backDetails: [
                    "2 x UVC84 USB PTZ Camera",
                    "M Core Pro, Gen-3 Mini-PC",
                    "M Touch Plus, Gen-3 touch panel",
                    "AV Hub, audio and video processor",
                    "2 x WPP30 wireless presentation pod (Optional)",
                    "Room Sensor, meeting room sensor",
                    "11.6-inch narrow bezel IPS display",
                    "12x optical zoom PTZ camera"
                  ],
                  namesizef: "17px",
                  namesizeb: "11px",
                  fsize: "11px",
                  isize: "100%",
                  bsize: "0px",
                  weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                },
              

                        {
                          id: 39,
                          title: "Microsoft & Zoom Devices",
                          subtitle: "Team Room System",
                          name: "MVC860 Microsoft Teams Rooms on Windows",
                          details: "Video Conferencing System for Large Rooms",
                          image: y39,
                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                          backDetails: [
                            "UVC86 USB PTZ camera",
                            "Room Sensor",
                            "M Speaker II (optional)",
                            "VCM35 Array microphone (optional)",
                            "VCM36-W Wi-Fi wireless microphone (optional)",
                            "VCM38 Ceiling array microphone (optional)",
                            "WPP30 wireless presentation pod (optional)",
                            "20.4 × HD zoom (12x optical, 1.7x digital) PTZ Camera"
                          ],
                          namesizef: "17px",
                          namesizeb: "11px",
                          fsize: "11px",
                          isize: "100%",
                          bsize: "0px",
                          weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                        },
                        {
                          id: 40,
                          title: "Microsoft & Zoom Devices",
                          subtitle: "Team Room System",
                          name: "MVC640 Microsoft Teams Rooms on Windows",
                          details: "Video Conferencing System for Medium Rooms",
                          image: y40,
                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                          backDetails: [
                            "UVC84 USB PTZ camera",
                            "M Speaker II (optional)",
                            "CPW65 DECT wireless microphone (optional)",
                            "VCM36-W Wi-Fi wireless microphone (optional)",
                            "WPP30 wireless presentation pod (optional)",
                            "Memory: 8 GB (2 x 4GB)",
                            "8-inch IPS screen",
                            "12x optical zoom USB PTZ camera"
                          ],
                          namesizef: "17px",
                          namesizeb: "11px",
                          fsize: "11px",
                          isize: "90%",
                          bsize: "0px",
                          weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                        },
                        {
                          id: 41,
                          title: "Microsoft & Zoom Devices",
                          subtitle: "Team Room System",
                          name: " MVC340 Microsoft Teams Rooms on Windows",
                          details: "Video Conferencing System for Focus and Small Rooms",
                          image: y41,
                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                          backDetails: [
                            "UVC34 All-in-One Video Bar",
                            "11th Gen Intel® Core i5",
                            "Memory: 8 GB (2 x 4GB)",
                            "8-inch IPS screen",
                            "1280 x 800 resolution",
                            "8 MP Camera",
                            "5x e-PTZ Camera"
                          ],
                          namesizef: "17px",
                          namesizeb: "11px",
                          fsize: "11px",
                          isize: "100%",
                          bsize: "0px",
                          weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                        },
                        {
                          id: 42,
                          title: "Microsoft & Zoom Devices",
                          subtitle: "Team Room System",
                          name: "Meeting Bar A20 Microsoft Teams Rooms on Android",
                          details: "All-in-One Video Bar for Premium Small Rooms",
                          image: y42,
                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                          backDetails: [
                            "All-in-One Design",
                            "20MP Camera",
                            "120° Field of View",
                            "Dual Screen",
                            "Auto Framing and Speaker Tracking",
                            "Built-in Dual-band Wi-Fi",
                            "Content Sharing",
                            "Certified by Microsoft Teams, Zoom, Blue Jeans, and RingCentral"
                          ],
                          namesizef: "17px",
                          namesizeb: "11px",
                          fsize: "11px",
                          isize: "80%",
                          bsize: "10px",
                          weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                        },
                        
                                {
                                  id: 43,
                                  title: "Microsoft & Zoom Devices",
                                  subtitle: "Team Room System",
                                  name: "Meeting Bar A30 Microsoft Teams Rooms on Android",
                                  details: "All-in-One Video Conference Bar for Medium Rooms",
                                  image: y43,
                                  colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                  backDetails: [
                                    "All-in-One Design",
                                    "Two Cameras Design",
                                    "120° Field of View",
                                    "Dual Screen",
                                    "Auto Framing and Speaker Tracking",
                                    "Built-in Dual-band Wi-Fi",
                                    "Content Sharing",
                                    "Certified by Microsoft Teams, Zoom, Blue Jeans, and RingCentral"
                                  ],
                                  namesizef: "17px",
                                  namesizeb: "11px",
                                  fsize: "11px",
                                  isize: "80%",
                                  bsize: "10px",
                                  weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                                },
                                {
                                  id: 44,
                                  title: "Microsoft & Zoom Devices",
                                  subtitle: "Team Room System",
                                  name: "Meeting Bar A10 Microsoft Teams Rooms on Android",
                                  details: "For Small Spaces and Home Offices",
                                  image: y44,
                                  colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                  backDetails: [
                                    "All-in-One Design",
                                    "4K Camera",
                                    "Electric Privacy Shutter",
                                    "Auto Framing and Speaker Tracking",
                                    "Built-in Wi-Fi & Bluetooth",
                                    "Content Sharing",
                                    "Certified by Microsoft Teams, Zoom, and RingCentral"
                                  ],
                                  namesizef: "17px",
                                  namesizeb: "11px",
                                  fsize: "11px",
                                  isize: "80%",
                                  bsize: "10px",
                                  weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                                },
                                {
                                  id: 45,
                                  title: "Microsoft & Zoom Devices",
                                  subtitle: "Team Room System",
                                  name: " Desk Vision A24 Microsoft Teams Room on Android",
                                  details: "For Desk / Phone Rooms",
                                  image: y45,
                                  colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                  backDetails: [
                                    "Teams/Zoom Display Certified",
                                    "UC Workstation Mode",
                                    "Full-HD Video Call",
                                    "High-Quality Microphone and Speakers",
                                    "USB Docking Station"
                                  ],
                                  namesizef: "17px",
                                  namesizeb: "11px",
                                  fsize: "11px",
                                  isize: "100%",
                                  bsize: "0px",
                                  weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                                },
                                
                                        {
                                          id: 46,
                                          title: "Microsoft & Zoom Devices",
                                          subtitle: "Zoom Room System",
                                          name: "Meeting Bar A40 Zoom Rooms Appliances",
                                          details: "All-in-one video bar for premium small to medium rooms",
                                          image: y46,
                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                          backDetails: [
                                            "Maximum pixels: 2 x 48MP pixels",
                                            "Number of preset positions: 9",
                                            "Electronic gimbal",
                                            "120° DFOV, 54° VFOV, 110° HFOV",
                                            "Depth of field: 1-5 meters",
                                            "6x digital zoom",
                                            "Maximum resolution: 4K",
                                            "Maximum frame rate: 60FPS",
                                            "Intelligence Features: Intelli Focus, Auto Framing, Speaker Tracking",
                                            "Built-in 2 x 10W speakers",
                                            "Built-in 8 MEMS microphone array"
                                          ],
                                          namesizef: "17px",
                                          namesizeb: "11px",
                                          fsize: "9px",
                                          isize: "100%",
                                          bsize: "0px",
                                          weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                                        },
                                        {
                                          id: 47,
                                          title: "Microsoft & Zoom Devices",
                                          subtitle: "Zoom Room System",
                                          name: "ZVC S90 Zoom Rooms Systems",
                                          details: "Video Conferencing System for Pro-AV Rooms",
                                          image: y47,
                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                          backDetails: [
                                            "4K Sensor USB PTZ Camera",
                                            "20.4 × HD zoom (12x optical, 1.7x digital) USB PTZ Camera",
                                            "M Core Pro, Gen-3 Mini-PC",
                                            "MTouch Plus, Gen-3 touch panel",
                                            "AV Hub, audio and video processor",
                                            "11th Gen Intel® Core i5",
                                            "Memory: 8 GB (2 x 4GB)",
                                            "Storage: 128 GB SSD",
                                            "11.6-inch narrow bezel IPS display",
                                            "1920 x 1080 video resolution",
                                            "Auto-framing",
                                            "Speaker tracking"
                                          ],
                                          namesizef: "17px",
                                          namesizeb: "11px",
                                          fsize: "9px",
                                          isize: "100%",
                                          bsize: "0px",
                                          weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                                        },
                                        {
                                          id: 48,
                                          title: "Microsoft & Zoom Devices",
                                          subtitle: "Zoom Room System",
                                          name: "ZVC860 Zoom Rooms Systems",
                                          details: "Video Conferencing System for Large Rooms",
                                          image: y48,
                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                          backDetails: [
                                            "UVC86 USB PTZ camera",
                                            "M Core Pro, Gen-3 Mini-PC",
                                            "MTouch Plus, Gen-3 touch panel",
                                            "Windows 11 IoT Enterprise X64",
                                            "Windows 11 IoT Enterprise License",
                                            "11th Gen Intel® Core i5",
                                            "Memory: 8 GB (2 x 4GB)",
                                            "1.6-inch narrow bezel IPS display",
                                            "20.4 × HD zoom (12x optical, 1.7x digital) PTZ Camera"
                                          ],
                                          namesizef: "17px",
                                          namesizeb: "12px",
                                          fsize: "11px",
                                          isize: "100%",
                                          bsize: "0px",
                                          weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                                        },
                                      
                                        
                                                {
                                                  id: 49,
                                                  title: "Microsoft & Zoom Devices",
                                                  subtitle: "Zoom Room System",
                                                  name: "Meeting Board Zoom Rooms Appliance",
                                                  details: "All-In-One Intelligent Whiteboard for Meeting Room",
                                                  image: y49,
                                                  colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                  backDetails: [
                                                    "ALL-IN-ONE design",
                                                    "Android 10",
                                                    "4K built-in camera",
                                                    "Detachable optional PTZ camera for larger space",
                                                    "Extended wireless microphones",
                                                    "Windows OPS Module",
                                                    "Upgradeable Android OPS Module",
                                                    "Teams / Zoom Whiteboard",
                                                    "Microsoft Teams & Zoom certified"
                                                  ],
                                                  namesizef: "17px",
                                                  namesizeb: "11px",
                                                  fsize: "11px",
                                                  isize: "100%",
                                                  bsize: "0px",
                                                  weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                                                },
                                                {
                                                  id: 50,
                                                  title: "Microsoft & Zoom Devices",
                                                  subtitle: "Zoom Room System",
                                                  name: "MCoreKit-C5-ZR (Base-kit)",
                                                  details: "Video Conferencing System for Diverse Rooms",
                                                  image: y50,
                                                  colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                  backDetails: [
                                                    "M Core Pro, Gen-3 Mini-PC",
                                                    "11th Gen Intel® Core i5",
                                                    "Memory: 8 GB (2 x 4GB)",
                                                    "Storage: 128 GB SSD",
                                                    "3x HDMI video output with CEC supported",
                                                    "MTouch Plus, Gen-3 touch panel",
                                                    "11.6-inch narrow bezel IPS display",
                                                    "1920 x 1080 video resolution",
                                                    "Support multi-screen control*",
                                                    "Support third-party screen sharing*",
                                                    "Room Sensor",
                                                    "Detection angle: 120°",
                                                    "Adjustable detection angle: ±20°",
                                                    "Operating temperature: 0-40℃",
                                                    "Operating humidity: 10-95%"
                                                  ],
                                                  namesizef: "20px",
                                                  namesizeb: "11px",
                                                  fsize: "10px",
                                                  isize: "100%",
                                                  bsize: "0px",
                                                  weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                                                },
                                                {
                                                  id: 51,
                                                  title: "Microsoft & Zoom Devices",
                                                  subtitle: "Zoom Room System",
                                                  name: " Meeting Eye 500",
                                                  details: "A Mini-PC for Multi-Camera Solution in Zoom Rooms System on Android",
                                                  image: y51,
                                                  colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                  backDetails: [
                                                    "Smart Experience",
                                                    "3 x HDMI out port",
                                                    "1 x VCH/LAN",
                                                    "1 x HDMI in Port",
                                                    "3 x USB-A 3.0"
                                                  ],
                                                  namesizef: "25px",
                                                  namesizeb: "17px",
                                                  fsize: "12px",
                                                  isize: "100%",
                                                  bsize: "0px",
                                                  weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                                                },
                                                {
                                                  id: 52,
                                                  title: "Microsoft & Zoom Devices",
                                                  subtitle: "Zoom Room System",
                                                  name: "Desk Vision A24 Zoom Rooms Appliance",
                                                  details: "Desktop Collaboration Display",
                                                  image: y52,
                                                  colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                  backDetails: [
                                                    "Teams/Zoom Display Certified",
                                                    "UC Workstation Mode",
                                                    "Full-HD Video Call",
                                                    "High quality microphone and speakers",
                                                    "USB Docking Station",
                                                    "USB Power Delivery for PC",
                                                    "QI Wireless Charging",
                                                    "Teams Room Android Certified"
                                                  ],
                                                  namesizef: "17px",
                                                  namesizeb: "11px",
                                                  fsize: "11px",
                                                  isize: "100%",
                                                  bsize: "0px",
                                                  weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                                                },



                                                
                                                        {
                                                          id: 53,
                                                          title: "Microsoft & Zoom Devices",
                                                          subtitle: "Zoom Room System",
                                                          name: "Meeting Bar A10 Zoom Rooms Appliances",
                                                          details: "For small space and home office",
                                                          image: y53,
                                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                          backDetails: [
                                                            "All-in-One Design",
                                                            "4K Camera",
                                                            "Auto Framing and Speaker Tracking",
                                                            "Built-in Wi-Fi & Bluetooth",
                                                            "Content Sharing",
                                                            "Certified by Microsoft Teams & Zoom"
                                                          ],
                                                          namesizef: "17px",
                                                          namesizeb: "11px",
                                                          fsize: "11px",
                                                          isize: "80%",
                                                          bsize: "10px",
                                                          weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                                                        },
                                                        {
                                                          id: 54,
                                                          title: "Microsoft & Zoom Devices",
                                                          subtitle: "Zoom Room System",
                                                          name: "Meeting Bar A20 Zoom Rooms Appliances",
                                                          details: "All-in-one video bar for premium small rooms",
                                                          image: y54,
                                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                          backDetails: [
                                                            "All-in-One Design",
                                                            "20MP Camera",
                                                            "120° Field of View",
                                                            "Dual Screen",
                                                            "Auto Framing and Speaker Tracking",
                                                            "Built-in Dual-band Wi-Fi",
                                                            "Content Sharing",
                                                            "Certified by Microsoft Teams & Zoom & Blue Jeans & RingCentral"
                                                          ],
                                                          namesizef: "17px",
                                                          namesizeb: "11px",
                                                          fsize: "11px",
                                                          isize: "100%",
                                                          bsize: "0px",
                                                          weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                                                        },
                                                        {
                                                          id: 55,
                                                          title: "Microsoft & Zoom Devices",
                                                          subtitle: "Zoom Room System",
                                                          name: "Meeting Bar A30 Zoom Rooms Appliances",
                                                          details: "All-in-one video bar for medium rooms",
                                                          image: y55,
                                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                          backDetails: [
                                                            "All-in-One Design",
                                                            "Two Cameras Design",
                                                            "120° Field of View",
                                                            "Dual Screen",
                                                            "Auto Framing and Speaker Tracking",
                                                            "Built-in Dual-band Wi-Fi",
                                                            "Content Sharing",
                                                            "Certified by Microsoft Teams & Zoom & Blue Jeans & RingCentral"
                                                          ],
                                                          namesizef: "17px",
                                                          namesizeb: "11px",
                                                          fsize: "11px",
                                                          isize: "100%",
                                                          bsize: "0px",
                                                          weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                                                        },
                                                        {
                                                          id: 56,
                                                          title: "Microsoft & Zoom Devices",
                                                          subtitle: "Zoom Room System",
                                                          name: "Room Cast for Zoom Rooms",
                                                          details: "Digital Signage & Wireless Presentation System",
                                                          image: y56,
                                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                          backDetails: [
                                                            "Zoom Digital Signage",
                                                            "Yealink Wireless Presentation Pod",
                                                            "Air Play",
                                                            "Zoom Direct Sharing",
                                                            "Zoom Sharing Key",
                                                            "4-Screen Casting",
                                                            "Remote device management",
                                                            "AES 128 data encryption"
                                                          ],
                                                          namesizef: "20px",
                                                          namesizeb: "12px",
                                                          fsize: "11px",
                                                          isize: "90%",
                                                          bsize: "0px",
                                                          weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                                                        },
                {
                                                                id: 57,
                                                                title: "Microsoft & Zoom Devices",
                                                                subtitle: "Zoom Room System",
                                                                name: "Yealink ZVC860 (Base-kit) Zoom Rooms Systems",
                                                                details: "Video Conferencing System for Large Rooms",
                                                                image: y57,
                                                                colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                backDetails: [
                                                                  "M Core Pro, Gen-3 Mini-PC",
                                                                  "M Touch Plus, Gen-3 touch panel",
                                                                  "UVC86 USB PTZ camera",
                                                                  "Room Sensor"
                                                                ],
                                                                namesizef: "17px",
                                                                namesizeb: "11px",
                                                                fsize: "11px",
                                                                isize: "100%",
                                                                bsize: "0px",
                                                                weblink: "https://www.yealink.com/en/product-detail/accessories-exp50"
                },


                                                              
                                                                {
                                                                  id: 58,
                                                                  title: "Microsoft & Zoom Devices",
                                                                  subtitle: "USB Cameras & BYOD Kits",
                                                                  name: "UVC85-BYOD",
                                                                  details: "Premium Audio and Video Conferencing Solution for Medium to Large Meeting Rooms",
                                                                  image: y58,
                                                                  colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                  backDetails: [
                                                                    "36x HD Zoom, 90° Wide-Angle Lens",
                                                                    "Audio and Video Integration",
                                                                    "Auto-Framing, Efficient Meetings",
                                                                    "Flexible Installation, Easy Deployment",
                                                                    "Device Management"
                                                                  ],
                                                                  namesizef: "27px",
                                                                  namesizeb: "13px",
                                                                  fsize: "11px",
                                                                  isize: "100%",
                                                                  bsize: "0px",
                                                                  weblink: "https://www.yealink.com/en/product-detail/uvc85-byod"
                                                                },
                                                                {
                                                                  id: 59,
                                                                  title: "Microsoft & Zoom Devices",
                                                                  subtitle: "USB Cameras & BYOD Kits",
                                                                  name: "UVC40 E2",
                                                                  details: "All-in-One Intelligent USB Video Bar for Small and Huddle Rooms",
                                                                  image: y59,
                                                                  colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                  backDetails: [
                                                                    "Ultra-HD 4K Camera | Super-Wide 120° Field of View | Smart Area Exposure",
                                                                    "Smart Video Conferencing with AI Technology",
                                                                    "Precise Sound Localization, Full-Duplex Experience",
                                                                    "Secure Conference with Electric Lens Cap",
                                                                    "Integrated Design | Standard UAC/UVC Devices | Device Management"
                                                                  ],
                                                                  namesizef: "28px",
                                                                  namesizeb: "13px",
                                                                  fsize: "11px",
                                                                  isize: "80%",
                                                                  bsize: "0px",
                                                                  weblink: "https://www.yealink.com/en/product-detail/uvc40"
                                                                },
                                                                {
                                                                  id: 60,
                                                                  title: "Microsoft & Zoom Devices",
                                                                  subtitle: "USB Cameras & BYOD Kits",
                                                                  name: "Smart Vision 40",
                                                                  details: "Intelligent All-in-One Camera for Small-to-Medium Microsoft Teams Rooms",
                                                                  image: y60,
                                                                  colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                  backDetails: [
                                                                    "2× 48MP",
                                                                    "Built-in 8 MEMS Microphone Array",
                                                                    "6-Meter Voice Pickup Range",
                                                                    "4-Meter Quality Voice Pickup Range",
                                                                    "Support 2x Expansion Microphone",
                                                                    "Intelli Focus",
                                                                    "Auto Framing",
                                                                    "Speaker Tracking"
                                                                  ],
                                                                  namesizef: "25px",
                                                                  namesizeb: "13px",
                                                                  fsize: "11px",
                                                                  isize: "80%",
                                                                  bsize: "0px",
                                                                  weblink: "https://www.yealink.com/en/product-detail/smart-vision-40"
                                                                },
                                                                {
                                                                  id: 61,
                                                                  title: "Microsoft & Zoom Devices",
                                                                  subtitle: "USB Cameras & BYOD Kits",
                                                                  name: "Yealink Smart Vision 60",
                                                                  details: "Intelligent 360° All-in-One Camera for Small-to-Medium Microsoft Teams Rooms",
                                                                  image: y61,
                                                                  colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                  backDetails: [
                                                                    "360° HFOV, 60° VFOV Camera",
                                                                    "10K Panoramic Camera",
                                                                    "6 x MEMS Microphone Array",
                                                                    "People Feed",
                                                                    "Cortana Voice Assistant",
                                                                    "Phonetic Transcription",
                                                                    "Automatic Translation",
                                                                    "Participant Identification"
                                                                  ],
                                                                  namesizef: "22px",
                                                                  namesizeb: "13px",
                                                                  fsize: "11px",
                                                                  isize: "80%",
                                                                  bsize: "5px",
                                                                  weblink: "https://www.yealink.com/en/product-detail/smart-vision-60"
                                                                },
                                                                
                                                                {
                                                                          id: 62,
                                                                          title: "Microsoft & Zoom Devices",
                                                                          subtitle: "USB Cameras & BYOD Kits",
                                                                          name: "UVC86",
                                                                          details: "4K Dual-Eye Intelligent Tracking Camera",
                                                                          image: y62,
                                                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                          backDetails: [
                                                                            "PTZ Camera: 8MP, Sony Sensor, 90° DFOV, 81° HFOV, 50° VFOV, 20.4x HD Zoom, Pan: ±100°, Tilt: +40°, -90°, 9 Camera Presets",
                                                                            "Panoramic Camera: 8MP, Sony Sensor, 120° DFOV, 110° HFOV, 78° VFOV, Manual Tilt: ±9°",
                                                                            "Auto Framing",
                                                                            "Speaker Tracking",
                                                                            "Presenter Tracking"
                                                                          ],
                                                                          namesizef: "30px",
                                                                          namesizeb: "20px",
                                                                          fsize: "12px",
                                                                          isize: "100%",
                                                                          bsize: "0px",
                                                                          weblink: "https://www.yealink.com/en/product-detail/uvc86"
                                                                },
                                                                {
                                                                          id: 63,
                                                                          title: "Microsoft & Zoom Devices",
                                                                          subtitle: "USB Cameras & BYOD Kits",
                                                                          name: "UVC84",
                                                                          details: "4K USB PTZ Camera",
                                                                          image: y63,
                                                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                          backDetails: [
                                                                            "4K Sensor",
                                                                            "80° Field of View",
                                                                            "36x HD Zoom (12x Optical + 3x Digital)",
                                                                            "Auto Framing",
                                                                            "60FPS",
                                                                            "Video Output: 4K, 1080p, 720p"
                                                                          ],
                                                                          namesizef: "30px",
                                                                          namesizeb: "20px",
                                                                          fsize: "12px",
                                                                          isize: "80%",
                                                                          bsize: "10px",
                                                                          weblink: "https://www.yealink.com/en/product-detail/uvc84"
                                                                },
                                                                        {
                                                                          id: 64,
                                                                          title: "Microsoft & Zoom Devices",
                                                                          subtitle: "USB Cameras & BYOD Kits",
                                                                          name: "UVC34",
                                                                          details: "All-in-One USB Video Bar",
                                                                          image: y64,
                                                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                          backDetails: [
                                                                            "Ultra HD 4K Camera with 1/2.8-Inch CMOS",
                                                                            "120° Super-Wide-Angle Lens",
                                                                            "Electric Lens Cap",
                                                                            "5m Voice Pickup Range",
                                                                            "Auto Framing and AI Face Enhancement",
                                                                            "Built-in Wi-Fi, Remote Device Management",
                                                                            "Applicable to Most Popular Video Conferencing Platforms"
                                                                          ],
                                                                          namesizef: "30px",
                                                                          namesizeb: "20px",
                                                                          fsize: "12px",
                                                                          isize: "100%",
                                                                          bsize: "0px",
                                                                          weblink: "https://www.yealink.com/en/product-detail/uvc34"
                                                                        },
                                                                        {
                                                                          id: 65,
                                                                          title: "Microsoft & Zoom Devices",
                                                                          subtitle: "USB Cameras & BYOD Kits",
                                                                          name: "UVC30 Room",
                                                                          details: "4K USB Camera",
                                                                          image: y65,
                                                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                          backDetails: [
                                                                            "USB Camera",
                                                                            "4K Resolution",
                                                                            "120° Field of View",
                                                                            "Auto Framing",
                                                                            "30FPS",
                                                                            "Wide Dynamic Range"
                                                                          ],
                                                                          namesizef: "30px",
                                                                          namesizeb: "20px",
                                                                          fsize: "12px",
                                                                          isize: "80%",
                                                                          bsize: "10px",
                                                                          weblink: "https://www.yealink.com/en/product-detail/uvc30"
                                                                        },
                                                                        
                                                                                {
                                                                                  id: 66,
                                                                                  title: "Microsoft & Zoom Devices",
                                                                                  subtitle: "USB Cameras & BYOD Kits",
                                                                                  name: "UVC84-BYOD",
                                                                                  details: "For Medium and Large Rooms",
                                                                                  image: y66,
                                                                                  colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                  backDetails: [
                                                                                    "4K Video at 30 FPS",
                                                                                    "12x Optical Zoom, 3x Digital Zoom for Crystal-Clear Video",
                                                                                    "Mechanical Pan, Tilt, and Zoom",
                                                                                    "Auto Framing Feature",
                                                                                    "Smooth PTZ Control via Remote or Yealink Camera Control Plug-In",
                                                                                    "Long Endurance with BYOD Box",
                                                                                    "Easy to Deploy"
                                                                                  ],
                                                                                  namesizef: "25px",
                                                                                  namesizeb: "15px",
                                                                                  fsize: "11px",
                                                                                  isize: "100%",
                                                                                  bsize: "0px",
                                                                                  weblink: "https://www.yealink.com/en/product-detail/uvc84"
                                                                                },
                                                                                {
                                                                                  id: 67,
                                                                                  title: "Microsoft & Zoom Devices",
                                                                                  subtitle: "USB Cameras & BYOD Kits",
                                                                                  name: "UVC30-CP900-BYOD",
                                                                                  details: "For Small and Huddle Conference Rooms",
                                                                                  image: y67,
                                                                                  colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                  backDetails: [
                                                                                    "All-in-One Design, Plug and Play",
                                                                                    "Long Endurance",
                                                                                    "Cost-Effective",
                                                                                    "Simple Deployment"
                                                                                  ],
                                                                                  namesizef: "20px",
                                                                                  namesizeb: "12px",
                                                                                  fsize: "12px",
                                                                                  isize: "100%",
                                                                                  bsize: "0px",
                                                                                  weblink: "https://www.yealink.com/en/product-detail/uvc30-cp900"
                                                                                },
                                                                                        {
                                                                                          id: 68,
                                                                                          title: "Microsoft & Zoom Devices",
                                                                                          subtitle: "AIOT Room Device",
                                                                                          name: "Room Panel Zoom Rooms Scheduling Display",
                                                                                          details: "Enhance Your Room Booking Experience with Zoom Rooms",
                                                                                          image: y68,
                                                                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                          backDetails: [
                                                                                            "Operating System: Android 10",
                                                                                            "Display: 8\" LCD Panel, Resolution: 1280x800",
                                                                                            "Audio: 2x Array Microphones, 2x Watt Speakers",
                                                                                            "12V Power Adapter",
                                                                                            "Mounting Options: Surface mount for 2-gang US electrical box, 20° tilt mount kit for wall or glass, Flush mount kit for wall or glass",
                                                                                            "Bluetooth 5.0, 802.11ac Wi-Fi"
                                                                                          ],
                                                                                          namesizef: "17px",
                                                                                          namesizeb: "11px",
                                                                                          fsize: "11px",
                                                                                          isize: "90%",
                                                                                          bsize: "0px",
                                                                                          weblink: "https://www.yealink.com/en/product-detail/room-panel-zoom"
                                                                                        },
                                                                                        {
                                                                                          id: 69,
                                                                                          title: "Microsoft & Zoom Devices",
                                                                                          subtitle: "AIOT Room Device",
                                                                                          name: "Room Panel Microsoft Teams Panels",
                                                                                          details: "Intelligent Room Scheduling Solution for Your Workspace",
                                                                                          image: y69,
                                                                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                          backDetails: [
                                                                                            "Display: 8\" LCD Panel, Resolution: 1280x800",
                                                                                            "Audio: 2x Array Microphones, 2x Watt Speakers",
                                                                                            "12V Power Adapter",
                                                                                            "Mounting Options: Surface mount for 2-gang US electrical box, 20° tilt mount kit for wall or glass, Flush mount kit for wall or glass",
                                                                                            "Bluetooth 5.0, 802.11ac Wi-Fi"
                                                                                          ],
                                                                                          namesizef: "17px",
                                                                                          namesizeb: "11px",
                                                                                          fsize: "11px",
                                                                                          isize: "100%",
                                                                                          bsize: "0px",
                                                                                          weblink: "https://www.yealink.com/en/product-detail/room-panel-teams"
                                                                                        },
                                                                                        {
                                                                                          id: 70,
                                                                                          title: "Microsoft & Zoom Devices",
                                                                                          subtitle: "AIOT Room Device",
                                                                                          name: "Room Sensor Pro",
                                                                                          details: "Enterprise Multifunctional Room Sensor",
                                                                                          image: y70,
                                                                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                          backDetails: [
                                                                                            "People Counting: Detection Range: Radius 4m Circular Area (Height: 3m), Detection Distance: ≤5m",
                                                                                            "Temperature: Range: 0°C~40°C, Resolution: 0.1°C, Accuracy: -1~+3°C",
                                                                                            "Humidity: Range: 10~95% RH, Resolution: 0.5% RH, Accuracy: ±5%",
                                                                                            "Illuminance: Multiple Ranges (up to 20000lux), Accuracy: ±10%"
                                                                                          ],
                                                                                          namesizef: "22px",
                                                                                          namesizeb: "13px",
                                                                                          fsize: "11px",
                                                                                          isize: "100%",
                                                                                          bsize: "0px",
                                                                                          weblink: "https://www.yealink.com/en/product-detail/room-sensor-pro"
                                                                                        },
                                                                                        {
                                                                                          id: 71,
                                                                                          title: "Microsoft & Zoom Devices",
                                                                                          subtitle: "AIOT Room Device",
                                                                                          name: "Room Panel Plus",
                                                                                          details: "10.1-inch Intelligent Room Scheduling Solution",
                                                                                          image: y71,
                                                                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                          backDetails: [
                                                                                            "Display: 10.1\" LCD Panel, 10-point Capacitive Multitouch",
                                                                                            "2 Million Pixels",
                                                                                            "1x Fast Ethernet Port (10/100Mbps)",
                                                                                            "Microphone: 2x Microphones, Speaker: 2x Speakers",
                                                                                            "Ambient Light Sensor, TOF Proximity Sensor"
                                                                                          ],
                                                                                          namesizef: "19px",
                                                                                          namesizeb: "11px",
                                                                                          fsize: "11px",
                                                                                          isize: "100%",
                                                                                          bsize: "0px",
                                                                                          weblink: "https://www.yealink.com/en/product-detail/room-panel-plus"
                                                                                        },
                                                                                        {
                                                                                          id: 72,
                                                                                          title: "Microsoft & Zoom Devices",
                                                                                          subtitle: "AIOT Room Device",
                                                                                          name: "Room Sensor",
                                                                                          details: "Enterprise Multifunctional Room Sensor",
                                                                                          image: y72,
                                                                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                          backDetails: [
                                                                                            "Detection: Occupancy, Temperature, Humidity, Light",
                                                                                            "Housing: PC, White",
                                                                                            "Occupancy Type: Passive Infrared Tech",
                                                                                            "PIR Detection Angle: 120°",
                                                                                            "Sensor Adjustable Angle: ±20°"
                                                                                          ],
                                                                                          namesizef: "25px",
                                                                                          namesizeb: "15px",
                                                                                          fsize: "11px",
                                                                                          isize: "90%",
                                                                                          bsize: "0px",
                                                                                          weblink: "https://www.yealink.com/en/product-detail/room-sensor"
                                                                                        },
                                                                                        {
                                                                                                id: 73,
                                                                                                title: "Microsoft & Zoom Devices",
                                                                                                subtitle: "AIOT Room Device",
                                                                                                name: "Room Panel ",
                                                                                                details: "Smart Room Scheduling Device",
                                                                                                image: y73,
                                                                                                colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                                backDetails: [
                                                                                                  "Display: 8\" LCD Panel, Resolution: 1280x800",
                                                                                                  "Audio: 2x Array Microphones, 2x Watt Speakers",
                                                                                                  "12V Power Adapter",
                                                                                                  "Mounting Options: Surface mount for 2-gang US electrical box, 20° tilt mount kit for wall or glass, Flush mount kit for wall or glass",
                                                                                                  "Bluetooth 5.0, 802.11ac Wi-Fi"
                                                                                                ],
                                                                                                namesizef: "25px",
                                                                                                namesizeb: "15px",
                                                                                                fsize: "11px",
                                                                                                isize: "90%",
                                                                                                bsize: "0px",
                                                                                                weblink: "https://www.yealink.com/en/product-detail/smart-room-panel"
                                                                                },
                                                                                {
                                                                                        id: 74,
                                                                                        title: "Microsoft & Zoom Devices",
                                                                                        subtitle: "AIOT Room Device",
                                                                                        name: "Room Cast ",
                                                                                        details: "Seamless and Intuitive Wireless Presentation System",
                                                                                        image: y74,
                                                                                        colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                        backDetails: [
                                                                                          "4K/60FPS",
                                                                                          "Yealink Wireless Presentation Pod",
                                                                                          "Supports Miracast, AirPlay, Google Cast",
                                                                                          "4-Screen Casting",
                                                                                          "Virtual Whiteboard",
                                                                                          "Intuitive Remote Control"
                                                                                        ],
                                                                                        namesizef: "25px",
                                                                                        namesizeb: "15px",
                                                                                        fsize: "12px",
                                                                                        isize: "100%",
                                                                                        bsize: "0px",
                                                                                        weblink: "https://www.yealink.com/en/product-detail/room-cast"
                                                                                      },
                                                                                      
                                                                                        {
                                                                                          id: 75,
                                                                                          title: "Microsoft & Zoom Devices",
                                                                                          subtitle: "Room Audio Devices",
                                                                                          name: "CM50",
                                                                                          details: "Premium Dante Beamforming Ceiling Microphone",
                                                                                          image: y75,
                                                                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                          backDetails: [
                                                                                            "Dante Microphone",
                                                                                            "127 Microphone Units",
                                                                                            "5-meter Radius High-Quality Pickup",
                                                                                            "AI-Based Sound Optimization",
                                                                                            "8 Custom Pickup Areas",
                                                                                            "Acoustic Adaptation",
                                                                                            "AI Feedback Suppression",
                                                                                            "Sound Source Localization"
                                                                                          ],
                                                                                          namesizef: "30px",
                                                                                          namesizeb: "20px",
                                                                                          fsize: "11px",
                                                                                          isize: "100%",
                                                                                          bsize: "0px",
                                                                                          weblink: "https://www.yealink.com/en/product-detail/cm50"
                                                                                        },
                                                                                        {
                                                                                          id: 76,
                                                                                          title: "Microsoft & Zoom Devices",
                                                                                          subtitle: "Room Audio Devices",
                                                                                          name: "AP08",
                                                                                          details: " Digital Signal Processor",
                                                                                          image: y76,
                                                                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                          backDetails: [
                                                                                            "16*16 Dante Channel",
                                                                                            "8 AEC Channels",
                                                                                            "Yealink Room Designer",
                                                                                            "AI-Based Sound Optimization",
                                                                                            "One-Button Deployment",
                                                                                            "Intelligent Design",
                                                                                            "Acoustic Adaptation"
                                                                                          ],
                                                                                          namesizef: "30px",
                                                                                          namesizeb: "20px",
                                                                                          fsize: "12px",
                                                                                          isize: "90%",
                                                                                          bsize: "0px",
                                                                                          weblink: "https://www.yealink.com/en/product-detail/ap08"
                                                                                        },
                                                                                        {
                                                                                          id: 77,
                                                                                          title: "Microsoft & Zoom Devices",
                                                                                          subtitle: "Room Audio Devices",
                                                                                          name: " CM20 ",
                                                                                          details: "AI-Powered Beamforming Ceiling Microphone",
                                                                                          image: y77,
                                                                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                          backDetails: [
                                                                                            "Frequency Response: 120Hz-16kHz",
                                                                                            "Maximum SPL: 122dBSPL",
                                                                                            "Signal Noise Ratio: 86dB @<1% THD",
                                                                                            "Sensitivity: 0 dBV/Pa 94dBSPL@1kHz",
                                                                                            "16 Omni-Direction Microphones",
                                                                                            "1 Internal AEC Channel"
                                                                                          ],
                                                                                          namesizef: "30px",
                                                                                          namesizeb: "20px",
                                                                                          fsize: "11px",
                                                                                          isize: "100%",
                                                                                          bsize: "0px",
                                                                                          weblink: "https://www.yealink.com/en/product-detail/cm20"
                                                                                        },
                                                                                        {
                                                                                          id: 78,
                                                                                          title: "Microsoft & Zoom Devices",
                                                                                          subtitle: "Room Audio Devices",
                                                                                          name: "CS10/CS10-D",
                                                                                          details: "Network Ceiling Loudspeaker for Audio Clarity",
                                                                                          image: y78,
                                                                                          colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                          backDetails: [
                                                                                            "Frequency Response (-6dB): 100-20kHz",
                                                                                            "Maximum SPL: Continuous: 99dB @20W 1m",
                                                                                            "LF: 5.7-inch woofer, HF: 1.1-inch tweeter",
                                                                                            "Nominal Impedance: 8Ω",
                                                                                            "Nominal Coverage Angle: 120",
                                                                                            "Size: ф294 × H125.8mm (with cover mesh)"
                                                                                          ],
                                                                                          namesizef: "17px",
                                                                                          namesizeb: "11px",
                                                                                          fsize: "12px",
                                                                                          isize: "100%",
                                                                                          bsize: "0px",
                                                                                          weblink: "https://www.yealink.com/en/product-detail/cs10"
                                                                                        },
                                                                                        {
                                                                                                id: 79,
                                                                                                title: "Microsoft & Zoom Devices",
                                                                                                subtitle: "Room Audio Devices",
                                                                                                name: "CMKit-121 ",
                                                                                                details: "Yealink Audio Ceiling Solution",
                                                                                                image: y79,
                                                                                                colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                                backDetails: [
                                                                                                  "16 Omnidirectional Microphone Units",
                                                                                                  "8 Independent Pickup Zones",
                                                                                                  "AI Dynamic Noise Reduction",
                                                                                                  "AI Reverberation Suppression",
                                                                                                  "CM20 Ceiling Microphone",
                                                                                                  "CS10 Ceiling Speakers"
                                                                                                ],
                                                                                                namesizef: "30px",
                                                                                                namesizeb: "20px",
                                                                                                fsize: "10px",
                                                                                                isize: "100%",
                                                                                                bsize: "0px",
                                                                                                weblink: "https://www.yealink.com/en/product-detail/cmkit-121"
                                                                                              },
                                                                                              {
                                                                                                id: 80,
                                                                                                title: "Microsoft & Zoom Devices",
                                                                                                subtitle: "Room Audio Devices",
                                                                                                name: "CP50 ",
                                                                                                details: "Video Conference Omnidirectional Microphone",
                                                                                                image: y80,
                                                                                                colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                                backDetails: [
                                                                                                  "8-Element Omnidirectional Microphone Array",
                                                                                                  "Crystal-Clear Natural Voice Reproduction",
                                                                                                  "Intelligent Noise and Echo Cancellation",
                                                                                                  "Full-Duplex Communication",
                                                                                                  "Easy Deployment with 8-Device Daisy-Chaining",
                                                                                                  "Plug-and-Play"
                                                                                                ],
                                                                                                namesizef: "30px",
                                                                                                namesizeb: "20px",
                                                                                                fsize: "10px",
                                                                                                isize: "100%",
                                                                                                bsize: "0px",
                                                                                                weblink: "https://www.yealink.com/en/product-detail/cp50"
                                                                                              },
                                                                                              {
                                                                                                id: 81,
                                                                                                title: "Microsoft & Zoom Devices",
                                                                                                subtitle: "Room Audio Devices",
                                                                                                name: "CPE40 ",
                                                                                                details: "Conference Omnidirectional Microphone",
                                                                                                image: y81,
                                                                                                colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                                backDetails: [
                                                                                                  "Single POE Network Cable",
                                                                                                  "8-Element Omnidirectional Microphone Array",
                                                                                                  "Support 360° Audio Pickup",
                                                                                                  "Supports Full-Duplex Communication",
                                                                                                  "Single POE network cable",
                                                                                                  "Yealink's Advanced Audio and AI Algorithms"
                                                                                                ],
                                                                                                namesizef: "30px",
                                                                                                namesizeb: "20px",
                                                                                                fsize: "10px",
                                                                                                isize: "100%",
                                                                                                bsize: "0px",
                                                                                                weblink: "https://www.yealink.com/en/product-detail/cpe40"
                                                                                              },
                                                                                              
                                                                                              {
                                                                                                id: 82,
                                                                                                title: "Microsoft & Zoom Devices",
                                                                                                subtitle: "Room Audio Devices",
                                                                                                name: "VCM36-W",
                                                                                                details: " Wireless Microphone",
                                                                                                image: y82,
                                                                                                colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                                backDetails: [
                                                                                                  "Optimal HD Audio",
                                                                                                  "Yealink Full Duplex Technology",
                                                                                                  "Yealink Acoustic Echo Canceling",
                                                                                                  "6m Voice Pickup Range",
                                                                                                  "Muting the microphone with touchpad",
                                                                                                  "Touchpad Muting Functionality",
                                                                                                  "Built-in 2450 mAh Battery",
                                                                                                  "Up to 12 Hours of Use Time",
                                                                                                  "Up to 7 Days Standby Time"
                                                                                                ],
                                                                                                namesizef: "30px",
                                                                                                namesizeb: "20px",
                                                                                                fsize: "10px",
                                                                                                isize: "90%",
                                                                                                bsize: "0px",
                                                                                                weblink: "https://www.yealink.com/en/product-detail/vcm36-w"
                                                                                              },{
                                                                                                id: 83,
                                                                                                title: "Microsoft & Zoom Devices",
                                                                                                subtitle: "Room Audio Devices",
                                                                                                name: "VCM35",
                                                                                                details: "Advanced Video Conferencing Microphone Array with Full-Duplex Technology",
                                                                                                image: y83,
                                                                                                colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                                backDetails: [
                                                                                                  "Optima HD Audio",
                                                                                                  "Yealink Full Duplex Technology",
                                                                                                  "Yealink Acoustic Echo Canceling",
                                                                                                  "Yealink Noise Proof Technology",
                                                                                                  "Voice Activity Detection (VAD)",
                                                                                                  "360° 5m Omnidirectional Pickup",
                                                                                                  "Muting the Microphone with Touchpad",
                                                                                                  "Frequency Response: 100Hz to 8kHz"
                                                                                                ],
                                                                                                namesizef: "30px",
                                                                                                namesizeb: "20px",
                                                                                                fsize: "10px",
                                                                                                isize: "100%",
                                                                                                bsize: "0px",
                                                                                                weblink: "https://www.yealink.com/en/product-detail/vcm35"
                                                                                              },
                                                                                              {
                                                                                                id: 84,
                                                                                                title: "Microsoft & Zoom Devices",
                                                                                                subtitle: "Room Audio Devices",
                                                                                                name: "VCM34 ",
                                                                                                details: "Efficient Microphone Array for Professional Video Conferencing",
                                                                                                image: y84,
                                                                                                colors: "linear-gradient(90deg, #0085FF 0%, #3A73A8 91.49%, #666666 100%)",
                                                                                                backDetails: [
                                                                                                  "Optimal HD Audio",
                                                                                                  "Yealink Acoustic Echo Canceling",
                                                                                                  "6m Voice Pickup Range",
                                                                                                  "Built-In 2450 mAh Battery",
                                                                                                  "Up to 12 Hours of Use Time",
                                                                                                  "Up to 7 Days Standby Time",
                                                                                                  "Frequency Response: 100Hz to 8kHz",
                                                                                                  "Dimension (WDH): Microphone: 100 x 16.5 mm, Charge Cradle: 100 x 10.5 mm"
                                                                                                ],
                                                                                                namesizef: "30px",
                                                                                                namesizeb: "20px",
                                                                                                fsize: "10px",
                                                                                                isize: "90%",
                                                                                                bsize: "0px",
                                                                                                weblink: "https://www.yealink.com/en/product-detail/vcm34"
                                                                                              }
                                                                                              
                                                                                              
                                                                                      
                                                                                      

                                                                                      
                                                                                      
                                                                              
                                                                              
                                                                      
                                                              
                                                              
                                                                                            
                                                      
                                              
                                                                  
                              
                              
      
      
   
   
   
  
   
  
   
    
   
   
   
   
   
   
   
   
   





  
];
export default yealinkproductdata;