import React, { useEffect, useRef } from 'react';
import DirectionStack from '../components/statistics';
import data from '../components/servicesdata';
import GridComponent from '../components/Services';
import dataproject from'../components/projectdata';
import dataproduct from '../components/producthomedata'
import datateam from '../components/teamcard'
import dataintro from '../components/introdata'
import SliderComponent from '../components/teamcarousel';
import SliderprojectComponent from '../components/projects';
import Footer from '../components/footer';
import Intro from '../components/intro';
import {Navbar} from '../components/navbar';
import { useLocation } from 'react-router-dom';
const Landingpage = () => {
  const sliderRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#slider') {
      sliderRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);
  return (
    <div>
       <Navbar variant='absolute' sectionId="section1" label="Go to Section 1"/> 
     <Intro  data={dataintro}/>
    <DirectionStack />
    <GridComponent data={data} />
    
    <div id="slider">
        <SliderprojectComponent data={dataproduct} />
      </div>
   
    
    
    
    
    <Footer sectionId="section1" label="Go to Section 1" /> 
    </div>
  )
}

export default Landingpage
