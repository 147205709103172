//page header for yealink and yeastar 

import React from 'react'
import {Typography,Stack} from '@mui/material';


const headingbg ={
    color: 'secondary.main',
    backgroundColor: 'primary.main',
    textAlign:'center',
    width:100,
    marginTop:1,
    borderRadius:1,
    fontSize:'16px',
    padding:0,
    fontWeight:'700',
  }
  const headingsx ={
    color: 'secondary.main',
    width:50,
    fontWeight:'700',
    fontSize:'16px',
    marginRight:1,
    marginTop:1,
  }
    const headingwg ={
      
      
    }
function Pageheader(props)  {

  return(
      <div>
    
     <Stack sx={{ width: '100%',
    height: {xs:'50vh',sm:'60vh',md:'48vh'},
    marginTop:{xs:'80px',sm:'90px',md:'100px'},
    backgroundImage:`url(${props.bgimg})`,
     objectFit:'cover',
     display:'flex',
     flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
     backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
   
   

    }}>
         <Stack  direction="row" sx={{textAlign:'center', justifyContent:'center' }}>
     
      <Typography variant='subtitle1'sx={headingsx}>OUR</Typography>
      <Typography variant='subtitle1'sx={headingbg}>PRODUCTS</Typography>
    </Stack> 
    <Typography variant='h5'sx={{fontWeight:'bold',
      fontSize:{xs:'50px',sm:'70px',md:'90px'},
      color:props.color,
      textAlign:'center',}} mb={8}><span>{props.heading}</span></Typography>
     
     </Stack>
      </div>
  )
}



export default Pageheader
